//***Copyright Notice***
//____________________________________________________
//Copyright © 2024 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { CSSProperties, FC, useContext, useState } from "react"
import { TableBodyProps } from "./ListView"
import { MachshevetClient, ColumnData, LiteRecordData } from "./Declarations"
import { SmartCount, SmartRow } from "./misc"
import { ColumnHeader } from "./Column"
import { Control } from "./Control"
import { numberColor, redirect, parseDate, UtcDate, getControlProps2, adjustColor } from "./globals"
import { AppContext, MainContext } from "./styles"

export const DataGrid: FC<TableBodyProps> = props => {
    const app = useContext(AppContext)!
    const ctx = useContext(MainContext)!
    const [selectedRecordIndex, setSelectedRecordIndex] = useState(0)
    const [editCols, setEditCols] = useState<string[]>([])
    const topstyle: CSSProperties = { position: "sticky", top: 0, backgroundColor: "white", whiteSpace: "nowrap", padding: 4 }
    const partstyle: CSSProperties = { borderWidth: 1, borderStyle: "solid", borderColor: numberColor(ctx.data.SecondaryColor), borderRadius: 6 }//the padding ruins the sticky footers on the bottom
    const sortcols = props.gridProps.Fields.sortBy(x => x.SelectedPosition)
    //const sortcols = props.records?.Columns.sortBy(x => x.SelectedPosition)
    const sCell: CSSProperties = { padding: ".2vmax .7vmax" }
    const state = props.gridProps

    //useEffect(() => {
    //    setState(props.gridProps)
    //}, [props.gridProps])

    const footcellstyle: CSSProperties = { position: "sticky", bottom: 0, backgroundColor: adjustColor(ctx.data.PrimaryColor!,.7), padding: ".2vmax", borderSpacing: 0 }

    function getMultiKeys(toIndex: number) {
        const arr = props.records!.Records.filter((r, idx) => {
            if ((idx >= selectedRecordIndex && idx <= toIndex) || (idx <= selectedRecordIndex && idx >= toIndex)) return true
            return false
        })
        return arr
    }

    return <table id="TestGridTable" tabIndex={1} style={{ ...partstyle, display: "block", whiteSpace: "nowrap", flexGrow: 1, flexBasis: 0, overflowY: "auto", borderCollapse: 'collapse' }} onContextMenu={e => props.setMenuField!(e)}>
        <thead>
            <tr id="TestGridHeader" style={{ backgroundColor: "white" }} >
                <th style={topstyle}></th>
                <th style={topstyle}>
                    <input type="checkbox" id="TestSelectAll" onClick={async e => {
                        const ischecked = e.currentTarget.checked
                        if (ischecked) {
                            const data = await MachshevetClient.GetAllIDs(props.controller!, state)
                            const recs = data.map(x => {
                                //const y = defaultRecordData(props.listType!, x)
                                const y = new LiteRecordData()
                                y.RecordType = props.listType
                                y.RecordID = x
                                y.RecordKey = x.toString()
                                return y
                            })
                            props.setSelected!(recs, true, true)
                        } else props.setSelected!([], false, true);
                    }} />
                    <SmartCount testname="SelectCount" Number={state.RecordKeys.size} />
                </th>
                {sortcols && sortcols.map(x => <ColumnHeader controller={props.controller!} key={x.ColumnKey} column={x} recordType={x.RecordType} gridProps={state!} fullPage={props.fullPage!} showTools={props.showTools} refresher={props.refreshData!} commands={props.commands?.filter(x => !x.NeedsRecord && x.NeedsField)}
                    stateSetter={y => {
                        let fld = state.Fields.find(z => z.SelectName === x.SelectName)
                        if (!fld) {
                            fld = new ColumnData()
                            fld.Name = x.Name
                            fld.SelectName = x.SelectName
                            state.Fields.push(fld)
                        }
                        Object.assign(fld, y)
                        props.onPropsChanged!({ ...state, Fields: state.Fields })
                        //props.setColumns!(state.Fields)
                    }}
                    editModeToggler={() => {
                        if (editCols.includes(x.Name!)) {
                            const newlst = editCols.filter(z => z !== x.Name)
                            setEditCols(newlst)
                        } else {
                            const newlst = editCols.concat(x.Name!)
                            setEditCols(newlst)
                        }
                    }}


                />)}
            </tr>
        </thead>
        <tbody>
            {
                props.records && props.records.Records.map((rowData, i) => {
                    const recid = rowData.RecordID!
                    const rectyp = rowData.RecordType!
                    let backclr = rowData.BackColor
                    let isnew = false
                    const reckey = rowData.RecordKey || (rectyp + '.' + recid + '.' + i)
                    const isSelected = state.RecordKeys.has(reckey)
                    if (isSelected) backclr = ctx.data.SecondaryColor
                    if (rowData.AddedOn) {
                        const addon = parseDate(rowData.AddedOn.toString())!
                        const utcnow = UtcDate(new Date())
                        const datediff = (utcnow.getTime() - addon.getTime()) / 1000
                        isnew = datediff < 9
                        if (isnew) backclr = 65535
                    }
                    const visflds = getControlProps2(rowData.Fields, props.records?.Columns!).sortBy(x => x.memberData!.SelectedPosition)

                    return <SmartRow id={"TestRow" + i} draggable key={reckey} style={{ backgroundColor: numberColor(backclr), color: numberColor(rowData.ForeColor), borderBottom: "solid 1px", borderBottomColor: adjustColor(ctx.data.SecondaryColor!, .7) }} onDragOver={e => e.preventDefault()}
                        onDoubleClick={e => {
                            props.onRowDoubleClick ? props.onRowDoubleClick(rowData) : redirect(undefined, rectyp, recid, !e.ctrlKey);
                        }}
                        onClick={e => {
                            if (rowData.RecordKey) {
                                const shiftKey = e.shiftKey
                                props.onRowClicked && props.onRowClicked(recid, rowData.RecordName!)
                                if (shiftKey) {
                                    const selctdKeys = getMultiKeys(i);
                                    props.setSelected!(selctdKeys, true, true)
                                }
                                else {
                                    props.setSelected!([rowData], true, true)
                                    setSelectedRecordIndex(i);
                                }
                            }

                        }}
                        onContextMenu={e => {
                            if (!isSelected) {
                                props.setSelected!([rowData], true, false)
                                setSelectedRecordIndex(i)
                            }
                            props.setMenuField!(e)
                        }}
                        onDrop={async () => {
                            const ordn = rowData.Fields.find(x => x.Name === "Ordinal")?.Value
                            await MachshevetClient.Global.SetValue(rowData.RecordType!, state.RecordKeys, "Ordinal", ordn)
                            await MachshevetClient.Global.SetValue(rowData.RecordType!, new Set(rowData.RecordKey!), "Ordinal", ordn + 1)
                            await props.refreshData!()

                        }}   >
                        <td><span style={{ color: numberColor(ctx.data.SecondaryColor) }}>{i + 1}</span></td>
                        <td>
                            <input id="TestSelectRow" type="checkbox" style={{ width: "100%" }} checked={isSelected} onClick={e => e.stopPropagation()} onChange={e => {
                                const shiftKey = (e.nativeEvent as unknown as PointerEvent).shiftKey
                                let recs: LiteRecordData[]
                                //if (shiftKey && checked && selectedRecordIndex !== undefined && !state.RecordKeys.has(rowData.RecordKey!)) recs = getMultiKeys(i);
                                if (shiftKey && selectedRecordIndex !== undefined && !isSelected) recs = getMultiKeys(i)
                                else recs = [rowData]
                                //props.setSelected!(recs, checked, false)
                                props.setSelected!(recs, !isSelected, false)
                                //if (checked && !(shiftKey && !state.RecordKeys !== undefined)) {
                                if (!isSelected && !(shiftKey && !state.RecordKeys !== undefined)) {
                                    props.setSelected!([rowData], true, false)
                                    setSelectedRecordIndex(i)
                                }
                                return false;
                            }}
                            />
                        </td>
                        {visflds.map(x => {
                            const stl = { ...sCell }
                            x.recordID = rowData.RecordID
                            x.recordKey = rowData.RecordKey
                            x.reloader = () => props.refreshData && props.refreshData()
                            if (x.memberData!.EditMode || editCols.includes(x.Name!)) x.memberData!.Editable = true
                            if (x.memberData!.BackColor) stl.backgroundColor = numberColor(x.memberData!.BackColor)
                            if (x.Name === app.menuField?.Name) {
                                stl.borderWidth = 1
                                stl.borderColor = numberColor(ctx.data.PrimaryColor)
                                stl.borderStyle = "dotted"
                            }
                            if (x.memberData.Editable) {
                                if (!x.style) x.style = {};
                                x.style.fontWeight = 'bold';
                            }
                            //x.FontSize = props.FontSize
                            return <td key={x.ColumnKey} id={"Test_Control_" + x.Name} style={stl} onContextMenu={e => {
                                if (!isSelected) {
                                    props.setSelected!([rowData], true, false)
                                    setSelectedRecordIndex(i)
                                }
                                props.setMenuField!(e, x)
                            }
                            } ><Control term={state.Term} field={{
                                    ...x, onChange: async () => { }, onBlur: async e => {
                                        const fldnm = x.memberData.IDFieldName || x.Name!
                                    const obj: { [k: string]: any } = {}
                                    obj[fldnm] = e
                                    await MachshevetClient.ApiSaveEdit(rowData.RecordType!, rowData.RecordID, obj, window.location.href)
                                    props.refreshData!()
                                }
                            }} /></td>
                        })}
                    </SmartRow>
                })}
        </tbody>
        {
            props.totals && props.totals.length>0 && <tfoot>
                <tr>
                    <td style={footcellstyle} />
                    <td style={footcellstyle} />
                    {sortcols && sortcols.map(x => {
                        const ttl = props.totals!.find(y => y.Key === x.Name)
                        const sym = props.records?.Records?.flatMap(y => y.Fields).find(y => y.Name === x.Name)?.CoinSymbol
                        return <td id={"TestAggregate_" + x.Name} key={x.ColumnKey} style={footcellstyle}
                        >{ttl && <SmartCount Number={ttl?.Value} fieldType={x.FieldType} coinSymbol={sym}   />}</td>
                    })}
                </tr>
            </tfoot>


        }
    </table>

}