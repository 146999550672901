//***Copyright Notice***
//____________________________________________________
//Copyright © 2024 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { FC, useContext, useState } from 'react'
import { DialogPlus, SmartButton, SmartDiv, useEffect2, VecIcon } from './misc'
import { ColumnChoice, ColumnData, MachshevetClient, SettingGroup } from "./Declarations"
import { AppContext, MainContext, openFieldSetting } from './styles'

export const ColumnChooser: FC<{ RecordType?: string, Group: SettingGroup, SettingKey?: string, ReportID?: number, Width?: number, OnChanged?: () => void, visible?: boolean, OnCancel?: () => void }> = props => {
    const [visible, setVisible] = useState(props.visible || false)
    const [state, setState] = useState<ColumnChoice>({ RecordType: props.RecordType, Group: props.Group, ReportID: props.ReportID, Columns: [], ForAll: false, ReportMode: false });

    useEffect2(async () => {
        if (props.visible !== undefined) {
            await fillData(true)
            setVisible(props.visible)
        }
    }, [props.visible])

    async function fillData(forAll: boolean) {
        const cols = await MachshevetClient.Global.GetColumnData(props.Group, props.SettingKey, props.RecordType, forAll, props.ReportID)
        setState(prev => ({ ...prev, Columns: cols }))
    }

    return <>
        <VecIcon name='ChooseColumns' width={props.Width}
            onClick={async () => {
                await fillData(true)
                setVisible(true)
            }} />
        {visible && state && <ChooserPopup columnChooser={state} onCancel={() => {
            props.OnCancel && props.OnCancel()
            setVisible(false)
        }} onChanged={props.OnChanged} />}
    </>
}

export const ChooserPopup: FC<{ columnChooser: ColumnChoice, onCancel: () => void, onChanged?: () => void }> = props => {
    const ctx = useContext(MainContext)
    const app = useContext(AppContext)
    const [search, setSearch] = useState<string>();
    const [selection, setSelection] = useState<Set<string>>(new Set<string>());
    const [state, setState] = useState(props.columnChooser);
    const [dragging, setDragging] = useState<string | null>(null);

    const srch = search?.toLowerCase()

    const searchPredicate = (column: ColumnData) => !srch || column.LocalName?.toLowerCase().includes(srch) || column.Name?.toLowerCase().includes(srch);

    const availableColumns = state.Columns.filter(x => x.SelectedPosition < 0 && searchPredicate(x)).sortBy(x => x.LocalName);
    const chosencols = state.Columns.filter(x => x.SelectedPosition > -1).sortBy(x => x.SelectedPosition);

    async function fillData(forAll: boolean) {
        const cols = await MachshevetClient.Global.GetColumnData(props.columnChooser.Group, props.columnChooser.Key, props.columnChooser.RecordType, forAll, props.columnChooser.ReportID)
        setState(prev => ({ ...prev, Columns: cols }))
    }

    function toggleselect(name: string) {
        if (selection.has(name)) {
            const newSelection = new Set(selection);
            newSelection.delete(name);
            setSelection(newSelection);
        } else {
            setSelection(new Set([...selection, name]));
        }
    }

    function itemstyle(item: ColumnData) {
        let backclr = item.IsNew ? 'fuchsia' : ''
        if (selection.has(item.Name!)) backclr = 'lightsalmon'
        const ret: React.CSSProperties = { display: 'flex', alignItems: 'center', padding: '.2em', backgroundColor: backclr, fontWeight: item.Editable ? "bold" : "" }
        return ret
    }

    return <DialogPlus {...props} onClose={props.onCancel} title={ctx.localized("ChooseColumns")} testName="ChooseColumns" footer={
        <div>
            <SmartButton disabled={selection.size !== 1} onClick={async () => {
                const nam = [...selection][0]
                await openFieldSetting(props.columnChooser.RecordType!, nam, app!)
            }} >{ctx.localized("Settings")}</SmartButton>
            <SmartButton testName="Okay" onClick={async () => {
                await MachshevetClient.Global.SetColumns(state)
                props.onChanged && props.onChanged()
                props.onCancel()
            }}>{ctx.localized('Okay')}</SmartButton>
            {!state.ReportMode && <label>
                <input type="checkbox" checked={state.ForAll} onChange={async e => {
                    const forall = e.target.checked;
                    setState(prev => ({ ...prev, ForAll: forall }))
                    await fillData(forall);
                }} />
                <span>{ctx.localized('ForAllUsers')}</span>
            </label>}
            <label>
                <input type="checkbox" checked={state.ReportMode} onChange={async e => {
                    setState(prev => ({ ...prev, ReportMode: e.currentTarget.checked }))
                }} />
                <span>{ctx.localized('ReportMode')}</span>
            </label>

        </div>
    }>

        <div id="TestColumnChooser" style={{ display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
            <input type='search' id="TestColumnSearch" value={search || ''} autoFocus onChange={e => setSearch(e.currentTarget.value)} placeholder={ctx.localized('SearchColumns') + '...'} />
            <div style={{ display: 'flex', overflowY: 'auto', gap: 5 }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span style={{ fontWeight: "bold" }}>{ctx.localized('AvailableColumns')}</span>
                    <dl id="TestAvailables" style={{ overflowY: 'auto' }} >
                        {availableColumns.map(x => {
                            return <SmartDiv key={x.Name} style={itemstyle(x)} title={x.Name} onClick={() => toggleselect(x.Name!)} onDoubleClick={() => {
                                const newcols = state.Columns.map(y => y.Name === x.Name ? { ...y, SelectedPosition: chosencols.length + 1 } : y)
                                setState(prev => ({ ...prev, Columns: newcols }))
                            }}
                            >
                                <VecIcon name={x.Icon!} width={20} />
                                <span id={"Test" + x.Name}>{x.LocalName}</span>
                                {x.Licensed ? undefined : <span>🔒</span>}
                            </SmartDiv>
                        })}
                    </dl>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <SmartButton onClick={() => {
                        const newcols = state.Columns.map(y => selection.has(y.Name!) ? { ...y, SelectedPosition: chosencols.length + 1 } : y)
                        setState(prev => ({ ...prev, Columns: newcols }))
                        setSelection(new Set<string>())
                    }}>{ctx.data.IsRtl ? ">" : "<"}</SmartButton>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span style={{ fontWeight: "bold" }}>{ctx.localized('SelectedColumns')}</span>
                    <dl id="TestSelecteds" style={{ overflowY: 'auto' }} >
                        {chosencols.filter(x => searchPredicate(x)).map(x => {
                            return <SmartDiv style={itemstyle(x)} title={x.Name} key={x.Name} draggable onDragStart={() => { setDragging(x.Name!) }}
                                onDragOver={e => e.preventDefault()}
                                onDrop={() => {
                                    if (dragging) {
                                        const landingPosition = x.SelectedPosition;
                                        const newcols = state.Columns?.map(x => x.Name === dragging ? { ...x, SelectedPosition: landingPosition } : x.SelectedPosition < landingPosition ? x : { ...x, SelectedPosition: x.SelectedPosition + 1 })
                                        setState(prev => ({ ...prev, Columns: newcols }))
                                    }
                                }}
                                onDoubleClick={() => {
                                    //const newcols = state.Columns?.map(y => y.Name === x.Name ? { ...x, SelectedPosition: -1 } : y)
                                    const newcols = state.Columns?.map(y => y.ColumnKey === x.ColumnKey ? { ...x, SelectedPosition: -1 } : y)
                                    setState(prev => ({ ...prev, Columns: newcols }))
                                }}
                                onClick={() => toggleselect(x.Name!)}
                            >
                                <div>
                                    <VecIcon name={x.Icon!} width={18} />
                                    <span id={"Test" + x.Name}>{x.SelectedPosition.toString() + ' ' + x.LocalName}</span>
                                </div>
                                {x.IsFilter && <VecIcon name='Filter' width={16} color='red' />}
                                {x.Licensed ? undefined : <span>🔒</span>}
                            </SmartDiv>
                        })}
                    </dl>
                </div>
            </div>
        </div>
    </DialogPlus>
}