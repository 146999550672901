//***Copyright Notice***
//____________________________________________________
//Copyright © 2024 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { useState, FC, useEffect, useRef, useContext, HTMLProps, ReactElement, ReactNode, CSSProperties } from 'react'
import { ColumnChooser } from './chooseColumns'
import { RecordForm } from './RecordAdapter'
import { Control } from './Control'
import { Link } from 'react-router-dom'
import { HtmlPick, DisplayBox, FilePick } from './pickers'
import { AppContext, ColumnData2, ContextMenuProps, doCommand, MainContext, MenuProps, prioritizeCommands } from './styles'
import { ColumnData, CommandMessage, ControlProps, DashboardData, DashboardTile, FieldTypes, GridProps, LiteRecordData, LoginResult, MachshevetClient, RecordsData, SettingGroup } from "./Declarations"
import {
    ControlProps2, controlRecord, defaultControlProps, defaultGridProps, KeyValuePair,
    numberColor, ReadText, getUrl, redirect, TabType, getActionUrl, GetFileBytes, showConfirm, getControlProps2, findScrollableParent, testAttribs, adjustColor
} from "./globals"
import { TableBodyProps } from './ListView'

export function useInterval(callback: () => void, delay: number) {
    const savedCallback = useRef(() => { });

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    useEffect(() => {
        if (delay === null) return undefined
        const tick = () => savedCallback.current()
        const id = setInterval(tick, delay)
        return () => clearInterval(id)
    }, [delay])
}

export function useEffect2(effect: () => void, deps?: React.DependencyList) {
    useEffect(() => {
        effect()
    }, deps)
}

export const NavLink: FC<React.HTMLProps<HTMLAnchorElement> & { reportID?: number, controller?: string, recordID?: number, action?: string, activeTab?: number, foreignController?: string, foreignRecordID?: number }> = props => {
    const url = getUrl(props.reportID, props.controller, props.recordID, props.action, props.activeTab, props.foreignController, props.foreignRecordID);
    const stl: CSSProperties = { ...props.style, textDecoration: "none" }
    return <Link id={"TestLink" + props.controller + "_" + props.action} title={props.title} to={url} onClick={props.onClick} style={stl} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>{props.children}</Link >
}

export const Attention: FC<{ record: LiteRecordData, data: RecordsData }> = props => {
    const ctx = useContext(MainContext)!
    const [state, setState] = useState(true)
    const flds = getControlProps2(props.record.Fields, props.data.Columns)
    if (!state) return <span />
    return <div style={{ borderColor: 'gray', borderStyle: 'solid', borderWidth: 1, backgroundColor: 'white', borderRadius: 5 }}>
        <div style={{ display: 'flex', backgroundColor: numberColor(ctx.data.PrimaryColor), color: 'white', padding: 5, gap: "1vh" }}>
            <VecIcon name={props.data.RecordType!} width={14} />
            <NavLink controller={props.data.RecordType} recordID={props.record.RecordID} style={{ flexGrow: 1, textAlign: 'center' }}>
                {props.record.RecordName}
            </NavLink>
            <ColumnChooser Group={SettingGroup.Popup} RecordType={props.data.RecordType} Width={16} ReportID={props.data.ReportID} />
            <VecIcon name="Hide" width={16} onClick={() => setState(false)} />
        </div>
        <div style={{ padding: 3 }}>
            {flds.filter(x => x.Value || x.memberData!.SubReportID).map(y => <div key={y.Name} style={{ display: 'flex', gap: 3 }}>
                <Icon name={y.memberData!.Icon!} title={y.memberData!.LocalName} />
                <Control field={{ ...y, showTools: false, recordID: props.record.RecordID }} />
            </div>)}
        </div>
    </div>
}

export const Preview: FC<{ recordType: string, id: number, miniMode: boolean }> = props => {
    const [state, setState] = useState<RecordsData>()

    useEffect2(async () => {
        const pr = await MachshevetClient.Global.Preview(props.recordType, props.id)
        setState(pr)
    }, [props.id])

    const rec = state?.Records[0]
    let flds = rec && getControlProps2(rec.Fields, state.Columns)
    if (props.miniMode) flds = flds?.filter(x => x.Value && !x.memberData!.ListType)

    return <div id="TestPreviewContainer" style={{ display: "flex", flexDirection: "column" }}>
        {!props.miniMode && <div style={{ backgroundColor: "#f5f5f5", borderColor: "#DDD", padding: 15, borderWidth: 1, display: "flex" }}>
            <span style={{ fontWeight: "bold", fontSize: 14, flexGrow: 1 }}>{rec?.RecordName} </span>
            <ColumnChooser Group={SettingGroup.PreviewFields} RecordType={props.recordType} Width={16} />
        </div>
        }
        <div id="TestPreview" style={{ padding: 15, overflowY: 'auto' }}> {flds && flds.map(x => {
            const cp2 = x as ControlProps2
            cp2.recordID = props.id
            cp2.showChanges = false
            cp2.style = { padding: "1px 5px", fontWeight: 600, display: 'table-cell' }
            cp2.showTools = false
            cp2.memberData.WordWrap = true
            return <div key={x.Name} style={{ display: x.memberData!.SubReportID || (x.memberData!.MaxLength && x.memberData!.MaxLength > 300) ? '' : 'table-row' }}>
                <span style={{ padding: "1px 5px", display: 'table-cell' }}>{x.memberData!.LocalName}</span>
                <Control field={{ ...cp2 }} />
            </div>
        })}</div>
        {state && !props.miniMode && <QuickAdds {...state} />}
    </div>
}

export const QuickAdds: FC<RecordsData> = props => {
    const app = useContext(AppContext)!;
    const ctx = useContext(MainContext)!;
    return <div style={{ display: "flex", gap: 4, padding: 5 }}>
        {props.QuickAdds.map(x => <button key={x.Key} type="button" style={{ flexGrow: 1 }} name={x.Key} title={ctx.localized('Add') + ' ' + ctx.localized(x.Key) + ' (' + ctx.localized(x.Value) + ')'} onClick={async () => {
            //const cp = defaultControlProps()
            const cp = new ColumnData2()
            cp.RecordType = props.RecordType
            cp.Name = 'Add'
            cp.commandParams = [x.Key + '.' + x.Value]
            await doCommand(cp, 0, defaultGridProps(props.Records[0].RecordID), app)
        }} >
            <VecIcon name={x.Key} />
            <span style={{ fontSize: 22, fontWeight: "bold" }}>+</span>
        </button>
        )}
    </div>
}

export const SvgPick: FC<ControlProps2> = props => {
    return <div>{props.memberData!.Editable && <><input type="file" onChange={e => {
        const trg = e.currentTarget;
        if (trg.files && trg.files[0]) {
            const file = trg.files[0];
            ReadText(file).then(x => props.onChange!(x))
        }
    }} style={{ display: 'block' }} accept="image/svg+xml" />
        <input type="text" value={props.Value as string} onChange={async e => {
            const trg = e.currentTarget
            await props.onChange!(trg.value)
        }} /></>}
        <span dangerouslySetInnerHTML={{ __html: props.Value! as string }} />
    </div>
}

export const EditButton: FC<{ RecordType: string, RecordID: number }> = props => {
    const ctx = useContext(MainContext)!;
    return ctx && ctx.data.UserID ?
        <NavLink {...props}>
            <VecIcon name="Edit" width={18} />
        </NavLink> : <></>
}

export const EmailDropDown: FC<ControlProps2 & { Selected: { Key: string, Value: string }[] }> = props => {
    const [options, setOptions] = useState<KeyValuePair<string, string>[]>([]);
    const [selected, setSelected] = useState<{ Key: string, Value: string }[]>([]);
    const [inputValue, setInputValue] = useState("");
    const [opened, setOpened] = useState(false);


    useEffect(() => {
        if (props.Selected) {
            props.Selected.map(x => {
                setSelected(prev => [...prev, x]);
            })
        }
    }, [])

    useEffect(() => {
        props.onChange && props.onChange(selected);
    }, [selected])

    function addEmail(text: string) {
        const emailPerson = text.split(',');
        const newarr = selected.concat({ Key: emailPerson[0], Value: emailPerson.length > 1 ? emailPerson[1] : "" });
        setSelected(newarr);
    }
    function removeEmail(address: string) {
        setSelected(prev => [...prev.filter(x => x.Key != address)]);
    }

    return <div style={{ display: "flex", gap: 2, flexWrap: 'wrap' }}>
        {selected.map((email, ind) =>
            <div style={{
                display: "flex", background: "#e8e6e6",
                border: "2px solid gray", borderRadius: "5px",
                padding: "0 3px", gap: "10px"
            }} key={ind}>
                <i style={{ cursor: "pointer" }} onClick={x => removeEmail(email.Key)}>x</i>
                <div style={{ display: "grid", lineHeight: "100%", whiteSpace: "nowrap", fontSize: "0.8em" }}>
                    <div style={{ fontWeight: "bold" }}>{email.Value}</div>
                    <div>{email.Key}</div>
                </div>
            </div>)}
        <div onMouseLeave={() => setOpened(false)}>
            <input value={inputValue} id="TestRecipients" onChange={async e => {
                const trm = e.currentTarget.value
                setOpened(true)
                setInputValue(trm);
                const res = await MachshevetClient.Global.FindEmailAddresses(trm);
                setOptions(res!);
            }} onFocus={() => setOpened(true)} onKeyDown={e => {

                if (e.key == "Enter" && inputValue != "" && options.length == 0) {
                    addEmail(inputValue);
                    setInputValue("");
                }

            }} />
            {opened && <div>
                {options.map(x => <div key={x.Key} style={{ display: "flex" }} onClick={e => {
                    setInputValue("")
                    addEmail(x.Key)
                }}><div style={{ fontWeight: "bold" }}>{x.Value}</div> ({x.Key})</div>)}
            </div>}
        </div>
    </div>
}

export const EditTable: FC<ControlProps2> = props => {
    const [selectedID, setSelectedID] = useState<number>()
    //const [commands, setCommands] = useState<GridCommands>()
    const [commands, setCommands] = useState<ColumnData[]>()
    const app = useContext(AppContext)!
    const ctx = useContext(MainContext)
    const rows: RecordsData = props.Value

    useEffect2(async () => {
        if (!commands) {
            const cmnds = await MachshevetClient.Global.GetCommands(props.memberData!.ListType!)
            setCommands(cmnds)
        }
    }, [])

    const gp = defaultGridProps(selectedID)
    gp.SettingGroup = SettingGroup.EditColumns
    const cmds = commands && prioritizeCommands(ctx, commands)
    const cmds2 = commands && prioritizeCommands(ctx, commands.filter(x => !x.NeedsRecord && x.NeedsField))

    return <table {...testAttribs(props)} onContextMenu={e => app.showContextMenu!(e, undefined, cmds, undefined, undefined, SettingGroup.EditColumns, undefined, undefined, props.reloader)}>
        <thead style={{ fontSize: 12 }} >
            {rows.Columns.filter(x => x.Visible !== false).map(x => {
                const cd = x as ColumnData2
                //cd.recordID
                return <th key={x.Name} title={x.Tooltip} id={"TestColumn_" + x.Name} >
                    <SmartDiv style={{ display: 'inline' }} onContextMenu={e => app.showContextMenu!(e, undefined, cmds2, undefined, undefined, undefined, undefined, undefined, undefined, cd)}> {x.LocalName}</SmartDiv>
                </th>
            }
            )
            }
            <th />
        </thead>
        <tbody>
            {rows.Records.map((x, i) => {
                const flds = getControlProps2(x.Fields, rows.Columns)
                return <tr id={"Test" + i} key={x.RecordID} style={{ position: 'relative' }} onContextMenu={e => {
                    app.showContextMenu!(e, undefined, cmds, undefined, undefined, SettingGroup.EditColumns, undefined, undefined, props.reloader, undefined, x.RecordID)
                    setSelectedID(x.RecordID)
                }}>{flds.filter(x => x.memberData!.Visible !== false).map(y => {
                    const cp = y as ControlProps2
                    cp.recordID = x.RecordID
                    cp.serverRefresh = props.serverRefresh
                    cp.reloader = props.reloader
                    cp.mainRecordType = props.memberData!.RecordType
                    cp.modelGetter = () => {
                        const ret = controlRecord(flds, x.RecordID!)
                        return ret
                    }
                    cp.onChange = async v => await props.onChange!(v, y, undefined, i)
                    return <td key={y.Name} id={"Test_Control_" + y.Name} data-Editable={cp.memberData!.Editable} onContextMenu={e => {
                        app.showContextMenu!(e, undefined, cmds, cp, undefined, SettingGroup.EditColumns, undefined, undefined, props.reloader)
                        setSelectedID(x.RecordID)
                    }}>{<Control field={cp} />}
                    </td>
                })}
                    <td>
                        {flds.filter(y => y.ErrorText).map(y => <span key={y.Name} style={{ color: "red" }}>{y.ErrorRecordID ? <NavLink controller={x.RecordType} recordID={y.ErrorRecordID} >{y.ErrorText}</NavLink> : <span>{y.memberData!.LocalName + " " + y.ErrorText}</span>}</span>)}
                        <VecIcon name="Delete" width={20} onClick={() => {
                            const currows = rows.Records
                            const v = currows.map(y => {
                                if (y.RecordID === x.RecordID) y.IsDeleted = true
                                return y
                            })
                            //const rd = 0
                            const newstt = rows
                            rows.Records = v
                            //await props.onChange!(v, props)
                            props.onChange!(newstt, props)
                        }} /></td>
                </tr>
            }
            )}
        </tbody>
        <tfoot>
            <td colSpan={4} id="TestPlusRow">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <VecIcon name='Add' width={20} onClick={async () => {
                        const newrow = new LiteRecordData()
                        newrow.Fields = rows.SampleRecord
                        const newnums = rows ? rows.Records.filter(x => (x.RecordID || 0) < 0).map(x => x.RecordID!) : []
                        const minnum = newnums.length ? Math.min(...newnums) : 0
                        newrow.RecordID = minnum - 1
                        await props.onChange!(undefined, props, newrow)
                    }} />
                    {rows && <span id="TestTotalRows" style={{ color: 'lightgray' }}>{rows.Records.length}</span>}
                </div>
            </td>
        </tfoot>
    </table>
}

export const DashboardButton: FC<DashboardTile> = props => {
    const app = useContext(AppContext)!
    const ctx = useContext(MainContext)!
    const [hover, setHover] = useState(false)
    //const forecolor = props.ForeColor ? numberColor(props.ForeColor) : "var(--primary)";
    const forecolor = numberColor(props.ForeColor || ctx.data.PrimaryColor)
    const backcolor = props.BackColor ? numberColor(props.BackColor) : undefined;
    const iconName = props.RecordType!;
    const direction = document.getElementsByTagName("body")[0].dir === "rtl" ? { right: 0 } : { left: 0 }

    const hascount = props.Count !== undefined && props.Count > 0
    return <NavLink reportID={props.ReportID} controller={props.RecordType} style={{ color: forecolor, backgroundColor: backcolor, boxShadow: "0 0 5px rgba(0, 0, 0, .4)", textAlign: "center", padding: "2vmax", position: "relative" }} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        <span style={{ display: hover ? "inline-block" : "none", color: backcolor || 'white', backgroundColor: forecolor, fontWeight: 'bold', padding: 4, position: 'absolute', top: 0, borderRadius: 7, ...direction }}
            onClickCapture={e => {
                e.stopPropagation()
                e.preventDefault()
                const rd = new RecordsData()
                rd.RecordType = props.RecordType
                app.openRecord!(rd)
            }}>＋</span>
        {hascount && <span style={{ color: "white", backgroundColor: "red", padding: 4, borderRadius: 30, position: 'absolute', display: 'block' }}><SmartCount Number={props.Count!} fieldType={props.AggregationFieldType} /></span>}
        <VecIcon name={iconName} width="3vmax" />
        <span style={{ display: "block", textAlign: "center", marginTop: 10 }}>{props.DisplayName}</span>
        {props.IsLicensed ? undefined : <span >🔒</span>}
    </NavLink>
}

export const Dashboard: FC = () => {
    const app = useContext(AppContext)!
    const ctx = useContext(MainContext)!
    const [state, setState] = useState<DashboardData>()
    const [columnChooserVisible, setColumnChooserVisible] = useState(false)

    async function getData() {
        const dt = await MachshevetClient.Global.GetDashboardData()
        setState(dt)
    }

    useEffect2(async () => {
        app.setPageTitle!('')
        await getData()//this line works, but very slow
        const refreshTimer = setInterval(async () => { if (ctx.docActive()) await getData() }, 10000)
        return () => clearInterval(refreshTimer)
    }, []);

    const imgurl = app.data ? getActionUrl('Global', 'DashboardBackground') : "";
    return <div style={{ padding: 30, backgroundImage: `url(${imgurl})`, backgroundRepeat: "no-repeat", backgroundPosition: 'center', backgroundSize: '60%', overflowY: 'auto' }}>
        <div style={{ display: 'grid', gridTemplateColumns: "repeat(auto-fit, minmax(6em, 15em))", gap: "3vmax" }}  >
            {state && state.Entities.map(x => <DashboardButton {...x} key={x.Key} />)}
            <div id="TestEditTiles" style={{ boxShadow: "0 0 5px rgba(0, 0, 0, .4)", textAlign: "center", padding: 30, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', background: '#e5fafd' }} onClick={() => setColumnChooserVisible(true)}>
                <ColumnChooser Group={SettingGroup.Dashboard} OnChanged={getData} visible={columnChooserVisible} OnCancel={() => setColumnChooserVisible(false)} />
                <span style={{ marginTop: 10 }}> {ctx.localized("EditTiles")}</span>
            </div>
        </div>
        <span style={{ margin: '80px 0 20px', display: 'block', fontWeight: 600, fontSize: '1.3rem' }}>{ctx.localized('Recent')}</span>
        <div style={{ display: 'flex', rowGap: 20, columnGap: 30, flexWrap: 'wrap' }}>
            {state && state.Recents.map(x => {
                //const forecolor = x.ForeColor ? numberColor(x.ForeColor) : "var(--primary)";
                // const forecolor = numberColor(x.ForeColor || ctx.data.PrimaryColor)
                const backcolor = x.BackColor ? numberColor(x.BackColor) : undefined
                const iconName = x.RecordType!
                return <NavLink key={x.RecordType + '.' + x.RecordID} controller={x.RecordType} recordID={x.RecordID} style={{ backgroundColor: backcolor, textAlign: "center", padding: '16px 10px', background: '#e7e7e7', display: 'flex', alignItems: 'center', borderInlineStartColor: numberColor(ctx.data.SecondaryColor), borderInlineStartWidth: 3, borderInlineStartStyle: 'solid', flexGrow: 1 }}>
                    <Icon name={iconName} />
                    <span>{x.DisplayName}</span>
                    <span style={{ color: numberColor(ctx.data.SecondaryColor), display: 'flex', flexGrow: '1', justifyContent: 'end', marginInlineEnd: '-23px ' }}>
                        <span style={{
                            border: '2px solid ' + numberColor(ctx.data.SecondaryColor), borderRadius: '50%', background: '#fff'
                        }}> {ctx.data.IsRtl ? ">>" : "<<"}</span>
                    </span>
                </NavLink>
            })}
        </div>
    </div>
}

export const Login: FC = () => {
    const ctx = useContext(MainContext)
    const [uName, setUName] = useState<string>("")
    const [pass, setPass] = useState<string>("")
    const [result, setResult] = useState<LoginResult>()
    const [newPass, setNewPass] = useState("")

    return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'gainsboro', flexGrow: 1 }}>
        <div style={{ gap: "5vh", backgroundColor: 'white', display: 'flex', borderRadius: 8, alignItems: "center", padding: "3vh" }}>
            <div style={{ textAlign: 'center' }}><img src={getActionUrl('Global', 'Logo')} /></div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: "1vh" }}>
                <div style={{ flexDirection: 'column', display: 'flex', borderRadius: 5, backgroundColor: 'white' }}>
                    <label>{ctx.localized("Email") + ":"}</label>
                    <input id="TestEmail" style={{ padding: 10, borderRadius: 5 }} placeholder={ctx.localized("Email")} type="text" onChange={e => setUName(e.currentTarget.value)} />
                </div>
                <div style={{ flexDirection: 'column', display: 'flex', borderRadius: 5, backgroundColor: 'white' }}>
                    <label>{ctx.localized("Password") + ":"}</label>
                    <input id="TestPassword" style={{ padding: 10, borderRadius: 5 }} placeholder={ctx.localized("Password")} type="password" onChange={e => setPass(e.currentTarget.value)} />
                </div>
                <SmartButton testName="Login" onClick={async () => {
                    const res = await MachshevetClient.Login.Login(uName, pass)
                    setResult(res)
                    if (!res.MustReset) {
                        if (res.PersonID) {
                            ctx.setUser(res.PersonID)
                            if (!res.Validation) redirect()
                        }
                    }
                }
                } autoFocus={true} >{ctx.localized("Login")}</SmartButton>
                {ctx.data.HasMailBox && <SmartButton testName="GetNewPassword" onClick={async () => {
                    const cnfm = showConfirm(ctx.localized("GenerateANewPassword") + "?")
                    if (cnfm) await MachshevetClient.Login.GenerateTempPassword(uName)
                }}>{ctx.localized("GetNewPassword")}</SmartButton>}
                {result?.Error && <div style={{ padding: 5 }}><b style={{ fontSize: "150%", color: "red" }}>{result.Error}</b></div>}

                {result?.Validation || result?.MustReset && <div>
                    <div style={{ fontSize: "150%", color: "orange", fontWeight: 'bold' }}>{result.Validation}</div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {ctx.localized("NewPassword")}
                        <input value={newPass} type="password" onChange={e => setNewPass(e.currentTarget.value)} />
                        <SmartButton onClick={async () => {
                            await MachshevetClient.Login.ChangePassword(newPass)
                            redirect()
                        }}>{ctx.localized("Change")}</SmartButton>
                    </div>
                </div>
                }
                <SmartButton onClick={async () => {
                    const url = await MachshevetClient.Login.MicrosoftLogin()
                    window.location.href = url
                }}><Icon name="Microsoft" /> {ctx.localized("MicrosoftLogin")}</SmartButton>
            </div>
        </div>
    </div>
}

export const VecIcon: FC<HTMLProps<HTMLSpanElement> & { name: string, width?: number | string, color?: string, position?: 'absolute', backColor?: string, testName?: string }> = props => {
    const ctx = useContext(MainContext)
    const [svg, setSvg] = useState<string>()

    useEffect2(async () => {
        const res = await MachshevetClient.Global.VectorIcon(props.name)
        const text = await res.text()
        if (text !== '') setSvg(text)
    }, [props.name])

    const svg2 = svg || '<svg viewBox="0 0 512 512" ><path d="M256,0C114.509,0,0,114.497,0,256c0,141.491,114.497,256,256,256c141.491,0,256-114.497,256-256 C512,114.509,397.503,0,256,0z  M256,477.867c-122.337,0-221.867-99.529-221.867-221.867S133.663,34.133,256,34.133 S477.867,133.663,477.867,256S378.337,477.867,256,477.867z" /></svg>'
    //const wdt = props.width || 32
    const wdt = props.width || "2vmax"
    const colr = props.color || "currentColor"
    const bcolr = props.backColor || undefined
    const { testName, ...spanProps } = props
    return <span {...spanProps} id={"TestIcon" + (props.testName || props.name)} title={ctx.localized(props.name)} dangerouslySetInnerHTML={{ __html: svg2 }} style={{ ...props.style, width: wdt, display: "inline-block", fill: colr, stroke: colr, position: props.position, backgroundColor: bcolr, minWidth: wdt }} />
}

export const Icon: FC<{ name: string, onClick?: React.MouseEventHandler, onMouseEnter?: React.MouseEventHandler, onMouseLeave?: React.MouseEventHandler, title?: string, fontSize?: string, color?: string }> = props => {
    const [text, setText] = useState<string>()

    useEffect2(async () => {
        const res = await MachshevetClient.Global.VectorIcon(props.name)
        const tx = await res.text()
        setText(tx)
    });

    let svg2 = text || '<svg viewBox="0 0 512 512" ><path d="M256,0C114.509,0,0,114.497,0,256c0,141.491,114.497,256,256,256c141.491,0,256-114.497,256-256 C512,114.509,397.503,0,256,0z  M256,477.867c-122.337,0-221.867-99.529-221.867-221.867S133.663,34.133,256,34.133 S477.867,133.663,477.867,256S378.337,477.867,256,477.867z" /></svg>'
    if (props.title) svg2 = svg2 + `<title>${props.title}</title>`
    //const stl: CSSProperties = { fill: 'currentcolor', height: "1em", width: "1em", display: 'inline-block' }
    const stl: CSSProperties = { height: "1em", width: "1em", display: 'inline-block' }
    if (props.color) stl.fill = props.color
    if (props.fontSize) stl.fontSize = props.fontSize
    return <span id={"TestIcon" + props.name} title={props.title} dangerouslySetInnerHTML={{ __html: svg2 }} style={stl} onClick={props.onClick} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave} aria-label={props.title} />
}

export const DialogPlus: FC<{ title?: string, onClose: () => void, footer: ReactNode, children?: ReactNode, minWidth?: number, testName?: string }> = props => {
    const ctx = useContext(MainContext)
    const dialogRef = useRef<HTMLDialogElement>(null)
    const [opened, setOpened] = useState(true)
    const timeref = useRef(new Date().getTime())

    useEffect(() => {
        if (opened) {
            //(dialogRef.current as any).showModal()
            (dialogRef.current as any).show() //showmodal hides contextmenus
        } else {
            (dialogRef.current as any).close()
            props.onClose()
        }
    }, [opened])
    const sDialog: CSSProperties = { display: 'flex', flexDirection: 'column', maxHeight: "80vh", maxWidth: "80vw", color: numberColor(ctx.data.PrimaryColor), backgroundColor:"white" }
    sDialog.zIndex = 2//needed only when not using showmodal
    if (props.minWidth) sDialog.minWidth = props.minWidth + "vw"
    //position absoulte doesnt work in columnchooser in attention2
    return <div style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: "rgba(0,0,0,.45)", zIndex: 3, overflowY: 'auto' }}>
        <dialog id={"TestPopup" + (props.testName || "")} className={props.title} data-time={timeref.current} ref={dialogRef} style={sDialog}>
            <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold', fontSize: 16, borderBottom: "1px solid #f0f0f0" }}>
                <div style={{ padding: 10 }} >{props.title}</div>
                <span id="TestCloseDialog" style={{ padding: 10, cursor: 'pointer' }} onClick={() => setOpened(false)}>X</span>
            </div>
            <div style={{ padding: 20, overflowY: 'auto', display: 'flex' }} >{props.children}</div>
            <div style={{ padding: 10, borderTop: "1px solid #f0f0f0", display: 'flex', justifyContent: 'end', gap: 4 }} >
                {props.footer}
            </div>
        </dialog>
    </div>
}

//export const SmartButton: FC<{ onClick: React.MouseEventHandler<HTMLButtonElement>, testName?: string, disabled?: boolean, autoFocus?: boolean }> = props => {
export const SmartButton: FC<HTMLProps<HTMLButtonElement> & { testName?: string }> = props => {
    const ctx = useContext(MainContext)
    const [isWorking, setIsWorking] = useState(false)
    const [hover, setHover] = useState(false)
    const isLocked = (isWorking || props.disabled)

    const stl: React.CSSProperties = { padding: "1vh 2vh", borderWidth: 1, borderRadius: 5, borderColor: numberColor(ctx.data.PrimaryColor) }
    if (isLocked) {
        stl.cursor = isWorking ? 'wait' : 'not-allowed'
        stl.color = "lightgray"
        stl.borderColor = "lightgray"
    }
    else {
        if (hover) stl.backgroundColor = adjustColor(ctx.data.SecondaryColor!, .6)
    }

    return <button id={"Test" + props.testName} style={stl} disabled={isLocked} autoFocus={props.autoFocus} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={async e => {
        setIsWorking(true)
        if (props.onClick) {
            try {
                await props.onClick(e)
            } catch (e) {
                setIsWorking(false)
                throw e
            }
        }
        setIsWorking(false)
    }}>{props.children}</button>
}

export const EditDialog: FC<{ onSaved?: (id?: number) => void, record?: RecordsData, presetValues?: any, onClose: () => void }> = props => {
    const record = props.record
    const [IsDirty, setIsDirty] = useState(false)
    const [saveHandler, setSaveHandler] = useState<() => number>()
    const app = useContext(AppContext)!
    const ctx = useContext(MainContext)!
    const ttl = record?.Records[0]?.RecordName
    const recid = props.record?.Records[0]?.RecordID

    return <DialogPlus title={ttl} minWidth={80} onClose={() => {
        if (IsDirty) {
            const sure = showConfirm(ctx.localized('CloseWithoutSaving') + '?');
            if (sure) props.onClose()
        }
        else props.onClose()
    }}
        footer={[
            <SmartButton key="Open" testName="Open" onClick={() => {
                redirect(undefined, props.record?.RecordType, recid || 0, ctx.data.CommandsInSameTab)
                props.onClose()
            }}>{ctx.localized('Open')}</SmartButton>,
            <SmartButton key="SaveAndCopy" testName="SaveAndCopy" onClick={async e => {
                const id = await saveHandler!()
                if (!id) { return; }
                const cmd = new ColumnData2()
                cmd.Name = "Copy"
                cmd.RecordType = props.record?.RecordType
                const gp = new GridProps()
                gp.RecordKeys = new Set([id.toString()])
                props.onClose()
                await doCommand(cmd, 0, gp, app)
            }}>{ctx.localized('SaveAndCopy')}</SmartButton>,
            <SmartButton key="SaveAndOpen" testName="SaveAndOpen" onClick={async e => {
                const id = await saveHandler!();
                if (!id) { return; }
                redirect(undefined, props.record?.RecordType, id, ctx.data.CommandsInSameTab)
                props.onClose()
            }}>{ctx.localized('SaveAndOpen')}</SmartButton>,
            <SmartButton key="Save" testName="Save" onClick={async () => {
                await saveHandler!();
                props.onClose()
            }}>{ctx.localized('Save')}</SmartButton>,
        ]}    >
        <RecordForm Record={record} setSaveHandler={setSaveHandler} onSaved={props.onSaved} onDirty={setIsDirty} presetValues={props.presetValues} />
    </DialogPlus>
}
export const SmartDiv: FC<HTMLProps<HTMLDivElement>> = props => {
    const ctx = useContext(MainContext)
    const [hover, setHover] = useState(false)
    const stl = { ...props.style } || {}
    if (hover) stl.backgroundColor = numberColor(ctx.data.SecondaryColor)
    stl.cursor = 'pointer'
    stl.userSelect = 'none'
    return <div {...props} style={stl} onMouseEnter={e => {
        setHover(true)
        if (props.onMouseEnter) props.onMouseEnter(e)
    }} onMouseLeave={e => {
        setHover(false)
        if (props.onMouseLeave) props.onMouseLeave(e)
    }} />
}
export const SmartRow: FC<HTMLProps<HTMLTableRowElement>> = props => {
    const ctx = useContext(MainContext)
    const [hover, setHover] = useState(false)
    const stl = { ...props.style } || {}
    if (hover) stl.backgroundColor = numberColor(ctx.data.SecondaryColor)
    //stl.cursor = 'pointer'
    stl.userSelect = 'none'
    return <tr {...props} style={stl} onMouseEnter={e => {
        setHover(true)
        if (props.onMouseEnter) props.onMouseEnter(e)
    }} onMouseLeave={e => {
        setHover(false)
        if (props.onMouseLeave) props.onMouseLeave(e)
    }} />
}

export const SmartCount: FC<{ Number?: number, testname?: string, fieldType?: FieldTypes, coinSymbol?: string }> = props => {
    const ctx = useContext(MainContext)!
    const [counter, setCounter] = useState(props.Number)
    const [prevVal, setPrevVal] = useState(props.Number)
    const [changing, setChanging] = useState(false);
    const hasval = props.Number !== null && props.Number !== undefined

    useEffect(() => {
        const newval = props.Number

        if (hasval) {
            let tckr = prevVal!;
            const fctr = newval! < prevVal! ? -1 : 1
            const diff = Math.abs(newval! - prevVal!)
            const ticks = 10
            const adder = Math.round(diff / ticks) || 1
            const timer = setInterval(() => {
                if (tckr === newval) {
                    clearInterval(timer)
                    setPrevVal(props.Number)
                    setChanging(false)
                } else {
                    setChanging(true)
                    tckr += (adder * fctr)
                    if ((fctr === 1 && tckr > newval!) || (fctr === -1 && tckr < newval!)) tckr = newval!
                    setCounter(tckr)
                }
            }, 140)
        }
    }, [props.Number]);

    let dspl = hasval ? counter!.toString() : ""
    if (counter) {
        switch (props.fieldType) {
            case FieldTypes.Span:
                dspl = new Date(counter * 1000).toISOString().substr(11, 8)
                break
            case FieldTypes.Percent:
                dspl = (counter * 100).toString() + '%'
                break
            case FieldTypes.Money:
                {
                    const smb = props.coinSymbol || ctx.data.CoinSymbol
                    const curdecimals = counter.toString().split('.')[1]?.length || 0
                    dspl = smb + ' ' + counter.toFixed(Math.max(ctx.data.MinDecimals, curdecimals))
                }
                break
        }
    }
    return <span id={"Test" + props.testname} data-value={props.Number} style={{ fontWeight: changing ? "bold" : "inherit" }}>{dspl}</span>
}

export const SmartText: FC<{ Text?: string, testname?: string }> = props => {
    const [display, setDisplay] = useState(props.Text || "")
    const [prevVal, setPrevVal] = useState(props.Text || "")
    const [changing, setChanging] = useState(false);
    const hasval = props.Text !== null && props.Text !== undefined

    useEffect(() => {
        const newval = props.Text || ""

        if (hasval) {
            let tckr = prevVal!
            let pos = 0;
            const maxlen = Math.max(newval.length, prevVal.length)
            const ticks = 10
            const tempo = Math.round(maxlen / ticks)
            const timer = setInterval(() => {
                if (tckr === newval) {
                    clearInterval(timer)
                    setPrevVal(props.Text || "")
                    setChanging(false)
                } else {
                    setChanging(true)
                    pos += tempo
                    if (pos > maxlen) pos = maxlen
                    tckr = newval.substring(0, pos) + prevVal.substring(pos, prevVal.length)
                    setDisplay(tckr)
                }
            }, 160)
        }
    }, [props.Text]);
    return <span id={"Test" + props.testname} style={{ fontWeight: changing ? "bold" : "inherit" }}>{display}</span>
}

export const MessageEditor: FC<CommandMessage & { onClose: () => void }> = props => {
    const ctx = useContext(MainContext);
    const app = useContext(AppContext)!;
    const [state, setState] = useState(props);
    const [mailboxes, setMailboxes] = useState<KeyValuePair<number, string>[]>();
    const fileInput = React.useRef<HTMLInputElement>(null);

    useEffect2(async () => {
        const res = await MachshevetClient.Global.Mailboxes();
        setMailboxes(res!.Value);
    }, []);

    const htmcol = new ColumnData()


    return <DialogPlus {...props} title={ctx.localized('NewMessage')} footer={[<SmartButton testName="AddDocument" key="ShowFiles" onClick={() => {
        const gp = new GridProps()
        gp.RecordType = "Document"
        app.openTable!(gp, e => setState(prev => ({ ...prev, Attachments: prev.Attachments.concat({ Title: e.RecordName!, Inline: false, Bytes: "", RecordID: e.RecordID }) })))
    }} >{ctx.localized('AddDocument')}</SmartButton>, <input id="TestUpload" key="Upload" type="file" ref={fileInput} onChange={async e => {
        const fil = (e.target as HTMLInputElement).files![0];
        const bts = await GetFileBytes(fil)
        const filnam = fil.name.replace(/\.[^/.]+$/, "")
        const ext = fil.name.substr(fil.name.lastIndexOf('.') + 1)
        setState(prev => ({ ...prev, Attachments: prev.Attachments.concat({ Title: filnam, Inline: false, Bytes: bts, Extension: ext }) }));
    }} />, <SmartButton testName="Send" key="Send" onClick={async () => {
        await MachshevetClient.Global.SendMail(state)
        props.onClose()
    }}>{ctx.localized('Send')}</SmartButton>]}>
        <div>
            <table style={{ width: "100%" }}>
                <tbody>
                    {mailboxes && mailboxes.length > 0 && <tr><td>{ctx.localized('From')}</td><td>
                        <select onChange={e => setState(prev => ({ ...prev, MailboxID: +e.currentTarget?.value }))}>
                            {/*<option />*/}
                            {mailboxes.options()}
                        </select>
                    </td></tr>}
                    <tr><td>{ctx.localized('ScreenName')}</td><td>
                        <input value={state.ScreenName} onChange={e => {
                            const vl = e.currentTarget.value;
                            setState(prev => ({ ...prev, ScreenName: vl }));
                        }} />
                    </td></tr>
                    <tr><td>{ctx.localized('ReplyAddress')}</td><td>
                        <input value={state.ReplyAddress} onChange={e => {
                            const vl = e.currentTarget.value;
                            setState(prev => ({ ...prev, ReplyAddress: vl }));
                        }} />
                    </td></tr>
                    <tr><td>{ctx.localized('Email')}</td><td>
                        <EmailDropDown {...defaultControlProps()} onChange={async e => setState(prev => ({ ...prev, Recipients: e }))} Selected={state.Recipients} />
                        <input type="checkbox" checked={state.SendSplit} onChange={e => {
                            const chk = e.currentTarget.checked
                            setState(prev => ({ ...prev, SendSplit: chk }))
                        }} /><span>{ctx.localized("SendSplit")}</span>
                    </td></tr>
                    <tr><td>{ctx.localized('Fax')}</td><td> <input type="text" value={state.FaxNumber} style={{ width: "100%" }} onChange={x => {
                        const vl = +x.currentTarget.value;
                        setState(prev => ({ ...prev, FaxNumber: vl }));
                    }} /></td></tr>
                    <tr><td>{ctx.localized('Subject')}</td><td> <input type="text" value={state.Subject} style={{ width: "100%" }} onChange={x => {
                        const vl = x.currentTarget.value;
                        setState(prev => ({ ...prev, Subject: vl }));
                    }} /></td></tr>
                </tbody>
            </table>
            <HtmlPick {...defaultControlProps()} memberData={htmcol} Value={state.Body} onChange={async e => {
                setState(prev => ({ ...prev, Body: e as string }));
            }} />
            {state.Attachments.length > 0 && <div>
                <div style={{ paddingRight: 5 }}>{ctx.localized('Attachments')}:</div>
                <div id="TestAttachments" style={{ padding: 5 }}>{state.Attachments.map(x =>
                    <div key={x.Title} style={{ display: "flex", gap: 7, justifyContent: "space-between", boxShadow: "1px 1px 6px 0px #a1a1a1", borderRadius: 3, margin: 1, padding: "2px 4px" }}>
                        <div style={{ display: "flex", gap: 3 }}>
                            <div>🗎</div>
                            <div>{x.Title + '.' + x.Extension}</div>
                        </div>
                        <div style={{ cursor: "pointer" }} onClick={() => setState(prev => ({ ...prev, Attachments: prev.Attachments.filter(a => a.Title !== x.Title) }))}>&#10006;</div>
                    </div>)}
                </div>
            </div>}
        </div>
    </DialogPlus>
}

export const ContextMenu: FC<ContextMenuProps> = props => {
    const ctx = useContext(MainContext)!;
    const stl: React.CSSProperties = {} 
    stl.borderRadius = 6
    stl.backgroundColor = "white"
    stl.borderColor = numberColor(ctx.data.SecondaryColor)
    stl.borderStyle = "solid"
    stl.borderWidth = 1
    stl.backgroundColor = "white"
    stl.fontSize = "90%"
    stl.overflowY = "auto"

    const qads = props.quickAdds?.map(x => {
        const cp = new ColumnData2()
        cp.RecordType = props.items[0].RecordType
        cp.Name = 'Add'
        cp.LocalName = '+' + ctx.localized(x.Key)
        cp.Key = x.Key
        cp.commandParams = [x.Key + '.' + x.Value]
        return cp
    })

    return <Popout id="TestContextMenu" style={stl} position={props.position}>
        <div style={{ display: 'flex', gap: 4, padding: 1, justifyContent: "space-evenly", backgroundColor: adjustColor(ctx.data.SecondaryColor!,.7) }}>{qads && qads.map(x => <SmartDiv key={x.commandParams![0]} title={x.LocalName} style={{ padding: 3 }}> <Icon name={x.Key!} onClick={() => props.onCommand(x)} /></SmartDiv>)}</div>
        {props.items.map(x => <MenuItemView key={x.Name} menu_item={x} onCommand={props.onCommand} style={{ whiteSpace: 'nowrap' }} />)}
    </Popout>
}

export const VerticalMenu: FC<MenuProps & HTMLProps<HTMLElement>> = props => {
    return <div style={{ ...props.style, position: "absolute", backgroundColor: "white" }}>
        {props.items.map(x => <MenuItemView key={x.Name} menu_item={x} onCommand={props.onCommand} />)}
    </div>
}

export const MenuItemView: FC<{ menu_item: ColumnData2, onCommand: (command: ColumnData2) => void, field?: ControlProps } & HTMLProps<HTMLElement>> = props => {
    const [selected, setSelected] = useState(false);
    if (props.menu_item.items) {
        //this is for "More"
        const stl = { ...props.style }
        stl.display = selected ? 'block' : 'none'
        stl.margin = "0px 100%"
        return <Avatar  {...props.menu_item} onClick={() => { }} onMouseEnter={() => setSelected(true)} onMouseLeave={() => setSelected(false)} style={{ display: 'flex' }}>
            <VerticalMenu items={props.menu_item.items} hoverable onCommand={props.onCommand} style={stl} />
        </Avatar>
    }
    return <Avatar  {...props.menu_item} style={props.style} onClick={() => !props.menu_item.PickList && props.onCommand && props.onCommand(props.menu_item)} onMouseEnter={() => setSelected(true)} onMouseLeave={() => setSelected(false)}>
        {props.menu_item.PickList ?
            <>
                <div style={{ backgroundColor: "white", display: selected ? 'block' : 'none', position: 'absolute', margin: "0 100%" }}>
                    {props.menu_item.PickList.map(pick => {
                        //const cp = defaultControlProps()
                        const cp = new ColumnData2()
                        cp.LocalName = pick.Value
                        cp.Name = props.menu_item.Name
                        cp.commandParams = [pick.Key]
                        cp.Icon = pick.Icon || props.menu_item.Icon
                        return <Avatar {...cp} style={props.style} key={pick.Key} onClick={() => props.onCommand && props.onCommand(cp)}
                        />
                    })}
                </div>
            </> :
            undefined}
    </Avatar>
}

export const CommandButton: FC<ColumnData2> = props => {
    const app = useContext(AppContext)!
    return <button type="button" style={{ padding: 2, textAlign: "inherit", width: "100%" }}    >
        <MenuItemView menu_item={props} onCommand={async () => {
            const gp = defaultGridProps(props.recordID)
            await doCommand(props, 0, gp, app)
        }} />
    </button>
}

export const Avatar: FC<ColumnData2 & { onClick: React.MouseEventHandler, style?: React.CSSProperties, icon?: string, iconWidth?: number, children?: ReactNode, onMouseEnter?: React.MouseEventHandler, onMouseLeave?: React.MouseEventHandler }> = props => {
    const ctx = useContext(MainContext)
    const stl = { ...props.style } || {}
    stl.gap = 3
    //stl.alignItems = 'center'
    stl.display = 'flex'
    if (props.IsNew) stl.backgroundColor = "fuchsia"
    if (props.SpecificCommand) stl.fontWeight = "649"
    let testid = "Test_Field_" + props.Name// + (props.commandParams?.length ? props.commandParams[0] : props.Name)
    if (props.commandParams?.length) testid = testid + '_' + props.commandParams[0]
    return <SmartDiv style={stl} id={testid} onClick={props.onClick} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
        <Icon name={props.icon || props.Name!} />
        <span style={{ whiteSpace: 'nowrap' }}>{props.LocalName}</span>
        {((props.PickList && props.PickList.length) || props.Name === "More") ? ctx.data.IsRtl ? '◁' : '▷' : ''}
        {props.children}
    </SmartDiv>
}

//export const Avatar2: FC<ControlProps2 & { gridProps: GridProps, icon?: string, tooltip?: string, menuField?: ControlProps, params?: string[], totalRows?: number, children?: ReactNode }> = props => {
export const Avatar2: FC<ColumnData2 & { gridProps: GridProps, icon?: string, tooltip?: string, menuField?: ControlProps, params?: string[], totalRows?: number, children?: ReactNode }> = props => {
    const [subMenu, setSubMenu] = useState(false)
    const [inProgress, setInProgress] = useState(false)
    const app = useContext(AppContext)!;
    const stl: React.CSSProperties = { ...props.style, borderRadius: 20, borderStyle: 'solid', padding: ".4vmax .8vmax", cursor: 'pointer', fontWeight: 600 }
    if (!stl.borderWidth) stl.borderWidth = 1
    stl.whiteSpace = "nowrap"
    stl.justifyContent = 'space-between'
    if (props.IsNew) stl.backgroundColor = "fuchsia"

    const hasrecs = props.gridProps.RecordKeys.size || !props.NeedsRecord

    const isenabled = !inProgress && hasrecs

    if (!isenabled) {
        stl.pointerEvents = "none"
        stl.opacity = 0.3
    }
    async function docmd(key?: string) {
        try {
            await doCommand({ ...props, commandParams: key ? [key] : props.commandParams }, props.totalRows || 0, props.gridProps, app)
        } catch (e) {
            setInProgress(false)
            throw e
        }
        setInProgress(false)
    }

    return <SmartDiv style={{ ...stl, display: 'flex', justifyContent: 'space-evenly' }}
        onClick={async () => {
            if (props.HasPickList) {
                setSubMenu(!subMenu)
            } else {
                setInProgress(true)
                await docmd()
            }
        }}>
        <div style={{ display: 'flex', gap: 6, alignItems: 'center' }}>
            <Icon name={props.icon || props.Name!} />
            <div id={"Test_Field_" + props.Name} title={props.tooltip}>{props.LocalName}</div>
        </div>
        {subMenu && <div style={{ ...stl, position: 'absolute', backgroundColor: 'white', zIndex: 2 }} onMouseLeave={() => {
            setSubMenu(false)
        }}>{props.PickList.map(x => <SmartDiv key={x.Key} id={"Test" + x.Key} style={{ padding: 2 }} onClick={async () => {
            setInProgress(true)
            await docmd(x.Key)
            setSubMenu(false)
        }}>{x.Value}</SmartDiv>)}</div>}
        {
            props.HasPickList ? <span>▼</span> : <></>
        }
        {props.children}
    </SmartDiv >;
}

//export const HamburgerItem: FC<{ command: ControlProps2, onClick: (back: boolean) => void, onCommand: (command: ControlProps2, field?: ControlProps) => void }> = props => {
export const HamburgerItem: FC<{ command: ColumnData2, onClick: (back: boolean) => void, onCommand: (command: ColumnData2, field?: ControlProps) => void }> = props => {
    const ctx = useContext(MainContext)
    if (props.command.items)
        //for "More"
        if (props.command.expanded && props.command.items) return <>
            <div>{ctx.localized(props.command.Name!)}</div>
            {props.command.items.map(subItem => <HamburgerItem key={subItem.Name} command={subItem} onClick={() => props.onClick(true)} onCommand={props.onCommand} />)}
        </>
        else return <Avatar {...props.command} onClick={() => props.onClick(false)}  >
            {ctx.data.IsRtl ? '◁' : '▷'}
        </Avatar>
    else {
        if (props.command.PickList)
            return <Avatar {...props.command} onClick={() => props.onClick(false)} />
        else return <Avatar {...props.command} onClick={() => {
            props.onCommand(props.command);
        }} />
    }
}

export const HamburgerMenu: FC<MenuProps> = props => {
    const ctx = useContext(MainContext)
    const [state, setState] = useState(prioritizeCommands(ctx, props.items))
    const [moreActive, setMoreActive] = useState(false)
    const [item, setItem] = useState<ColumnData2>()

    return <div id="TestHamburger" style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflowY: 'auto' }} >
        <input type="text" placeholder="⌕" style={{ borderWidth: 1, borderRadius: 5, borderColor: "lightgray", fontSize: 10, borderStyle: "solid" }} onChange={e => {
            const trm = e.currentTarget.value.toLowerCase()
            setState(props.items.filter(x => x.Name?.toLowerCase().includes(trm) || x.LocalName?.toLowerCase().includes(trm)).sortBy(x => x.LocalName))
        }} />
        {(state.length > 1 && moreActive) ?
            <div onClick={() => {
                setMoreActive(!moreActive)
                setState(prioritizeCommands(ctx, props.items))
            }} >{ctx.data.IsRtl ? '▷' : '◁'}&nbsp;{ctx.localized("Back")}</div>
            : <></>}
        {item ?
            <div onClick={() => {
                setItem(undefined)
                const itms = prioritizeCommands(ctx, props.items)
                if (moreActive) setState(itms[itms.length - 1].items!)
                else setState(itms)
            }} >{ctx.data.IsRtl ? '▷' : '◁'}&nbsp;{ctx.localized("Back")}</div>
            : <></>}
        <div style={{ overflowY: 'auto', display: 'flex', flexDirection: 'column', flexGrow: 1, padding: ".5vh", gap: ".5vh" }}>
            {(() => {
                if (state.length)
                    return state.map(item => <HamburgerItem key={item.Name} command={item} onCommand={props.onCommand} onClick={(back) => {
                        if (!back) {
                            if (item.HasPickList) {
                                setState([])
                                setItem(item)
                            }
                            else {
                                setMoreActive(!moreActive)
                                setState(state[state.length - 1].items!)
                            }
                        }
                    }} />)
                else
                    return item?.PickList.map(pick => {
                        const cp = { ...item }
                        cp.HasPickList = false
                        cp.PickList = []
                        cp.items = undefined
                        cp.commandParams = [pick.Key]
                        cp.LocalName = pick.Value
                        return <Avatar {...cp} key={pick.Key} onClick={() => props.onCommand ? props.onCommand(cp) : undefined} />
                    })

            })()}
        </div>
    </div>
}

export const MyTab: FC<TabType> = props => {
    return <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflowY: 'auto' }}>{props.children}</div>
}

export const Accordion: FC<{ children: ReactElement[], closeTab?: boolean }> = props => {
    const [selectedTab, setSelectedTab] = useState<number>();
    useEffect(() => {
        setSelectedTab(undefined);
    }, [props.closeTab])
    return <div id="TestAccordion">
        {props.children.map((item, i) => {
            const tt = item.props as TabType
            return <><SmartDiv id={"TestAccordion" + item.key} key={i} onClick={() => {
                if (selectedTab === i) {
                    setSelectedTab(undefined);
                } else {
                    setSelectedTab(i)
                }
            }} >{tt.titleText}</SmartDiv>{i == selectedTab ? item : null}</>

        })}
    </div>
}


export const MyTabs: FC<{ children: ReactElement[], selectedTabChanged?: (index: number) => void, activeTab?: number }> = props => {
    const ctx = useContext(MainContext)
    const [selectedTab, setSelectedTab] = useState(props.activeTab || 0)
    useEffect(() => {
        if (props.activeTab && props.activeTab !== selectedTab) {
            setSelectedTab(props.activeTab)
        }
    }, [props.activeTab]);

    function selectTab(index: number) {
        setSelectedTab(index);
        props.selectedTabChanged && props.selectedTabChanged(index);
    }

    return <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflowY: 'auto' }}>
        <div style={{ display: 'flex', gap: 0 }}>
            {props.children.map((item, i) => {
                const tt = item.props as TabType
                const topmrg = selectedTab == i ? 0 : 4
                const bordrwdt = (selectedTab == i ? 5 : 1) + "px 1px " + (selectedTab == i ? 0 : 1) + "px 1px"
                const ttl = tt.titleText ? <span style={tt.style}>{tt.titleText}</span> : tt.titleElement
                return <SmartDiv key={i} id={"TestTab" + tt.reportID} onClick={() => {
                    selectTab(i);
                    tt.onClick && tt.onClick()
                }} style={{ backgroundColor: '#ebebeb', padding: 8, borderColor: numberColor(ctx.data.PrimaryColor), borderRadius: "0px", borderWidth: bordrwdt, borderStyle: "solid", marginTop: topmrg }}>{ttl}</SmartDiv>
            })}
        </div>
        <div style={{ borderColor: numberColor(ctx.data.PrimaryColor), borderStyle: "solid", borderWidth: "1px 1px 1px 1px", overflowY: 'auto', display: 'flex', flexGrow: 1 }}>
            {
                props.children.map((x, i) => {
                    const tt = x.props as TabType
                    return <div key={i} id={"Test" + tt.testName} style={{ display: i == selectedTab ? "flex" : "none", flexDirection: 'column', flexGrow: 1, overflowX: 'auto' }}>{x}</div>
                })
            }
        </div>
    </div>
}


export const CardList: FC<TableBodyProps> = props => {
    return <div style={{ display: 'flex', flexDirection: 'column', gap: 7, flexWrap: 'wrap' }} >{props.records?.Records.map(x => {
        const selkeys = props.gridProps?.RecordKeys
        const isSelected = selkeys && selkeys.has(x.RecordKey!)
        return <RecordCard key={x.RecordKey} record={x} isSelected={isSelected} records={props.records!} onSelected={(e, u) => {
            props.setSelected!([e], true, u)
        }} onContextMenu={e => {
            props.setSelected!([x], true, false)
            props.setMenuField!(e)
        }} />
    })}</div>
}

export const ChatList: FC<TableBodyProps> = props => {
    return <div style={{ overflowY: "auto" }}>{props.records?.Records.map(x => {
        //const body = x.Fields.sortBy(x => -x.MaxLength!)[0]
        const flds = getControlProps2(x.Fields, props.records!.Columns)
        const body = flds.sortBy(x => -x.memberData!.MaxLength!)[0]
        const regfields = flds.filter(x => x.Name !== body.Name && x.Name !== "FileBytes")
        const imgfield = flds.filter(x => x.Name === "FileBytes" && x.Value !== null)[0]
        imgfield.showTools = false
        const stl: React.CSSProperties = { display: 'flex', gap: 10 }
        stl.padding = x.Incoming ? "0px 20px 0px 0px" : "0px 0px 0px 20px"
        return <div key={x.RecordKey} style={{ padding: 15, display: 'flex' }} onContextMenu={e => {
            e.preventDefault()
            props.setSelected!([x], true, true)
            props.setMenuField!(e)
        }} >
            <div style={stl}>
                <img src={getActionUrl('Person', 'GetDoc', x.PersonID, 'Column=Image')} style={{ maxWidth: 60 }} />
                <div style={{ flexGrow: 1 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', gap: 20 }}>{regfields.map(y => <div key={y.Name} onContextMenu={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        props.setSelected!([x], true, true)
                        props.setMenuField!(e, y)
                    }} ><Control field={{ ...y, showTools: false }} /></div>)}</div>
                    <div style={{ backgroundColor: numberColor(x.BackColor), borderRadius: 5, padding: 10 }}>{body.DisplayString}</div>
                    {imgfield && <div id="TestChatImg"><FilePick {...imgfield} /></div>}

                </div>
            </div>
        </div>
    })}</div>
}

export const RecordCard: FC<{ record: LiteRecordData, isSelected?: boolean, onSelected?: (record: LiteRecordData, unselectOthers: boolean) => void, onContextMenu?: (coords: React.MouseEvent) => void, records: RecordsData }> = props => {
    const app = useContext(AppContext)!
    const ctx = useContext(MainContext)!
    const [hover, setHover] = useState(false)
    const rec = props.record
    const recname = rec.RecordName
    const flds = getControlProps2(rec.Fields, props.records.Columns)
    const relvfields = flds.filter(y => y.Name !== 'Name' && y.Value).sortBy(y => y.memberData!.SelectedPosition)
    const cmds = props.records.Columns && props.records.Columns.length ? props.records.Columns.filter(y => y.IsCommand).sortBy(y => y.SelectedPosition) : undefined
    let backclr = numberColor(ctx.data.PrimaryColor)
    const stl: CSSProperties = { borderWidth: 1, borderStyle: 'solid', borderRadius: 8 }
    if (props.isSelected) {
        stl.borderWidth = 2
        backclr = "orange"
    }
    stl.borderColor = backclr
    const gp = defaultGridProps()
    gp.RecordKeys = new Set([rec.RecordKey!])
    return <div draggable key={rec.RecordKey} style={stl} onClick={e => props.onSelected!(rec, !e.ctrlKey)} onDoubleClick={() => redirect(undefined, rec.RecordType, rec.RecordID)} onContextMenu={e => props.onContextMenu && props.onContextMenu(e)} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onDragStart={() => props.onSelected!(rec, true)}>
        <div style={{ fontWeight: 'bold', color: "white", padding: 5, backgroundColor: backclr, borderRadius: "8px 8px 0px 0px" }}>{recname}</div>
        <div id="TestGlanceCard" style={{ padding: 5, position: "relative" }}>
            {relvfields.map(y => <div key={y.Name} style={{ display: 'flex', gap: 3 }}>
                <Icon name={y.memberData!.Icon!} title={y.memberData!.LocalName} />
                <DisplayBox {...y} showTools={true} />
            </div>)}
            <div style={{ display: "flex", opacity: hover ? 1 : 0, padding: ".5vmax", justifyContent: 'space-around', transition: "opacity .8s", position: 'absolute', backgroundColor: "white", borderColor: backclr, borderWidth: 1, borderStyle: 'solid', borderRadius: 5, gap: "1vmax" }}>
                {cmds && cmds.map(x => <SmartDiv key={x.Name} style={{ display: 'flex' }}><Icon name={x.Name!} onClick={() => doCommand(x as ColumnData2, 1, gp, app)} /></SmartDiv>)}
            </div>
        </div>
    </div>
}

export const Popout: FC<HTMLProps<HTMLDivElement> & { position?: [number, number] }> = props => {
    const [popTop, setPopTop] = useState(props.position?.[1])
    const [popLeft, setPopLeft] = useState(props.position?.[0])
    const [popHeight, setPopHeight] = useState<number>()
    const [popWidth, setPopWidth] = useState<number>()
    const myref = useRef<HTMLDivElement>(null)
    const [parent, setParent] = useState<HTMLElement>()

    function findPopupPosition() {
        if (parent) {
            const rect = parent.getBoundingClientRect()
            const top = props.position ? props.position[1] : window.pageYOffset + rect.top + rect.height
            //if (!props.position) {
            setPopTop(top)
            const left = props.position ? props.position[0] : window.pageXOffset + rect.left
            setPopLeft(left)
            //}
            const scrlr = findScrollableParent(parent)
            if (scrlr) {
                const scrollTop = scrlr.scrollTop
                if (myref.current) {
                    const elementRect = myref.current.getBoundingClientRect()
                    const bottomRelativeToContainer = elementRect.bottom - scrollTop
                    const bottomSpace = window.innerHeight - bottomRelativeToContainer
                    if (bottomSpace < 0) setPopHeight(window.innerHeight - top)
                    if (elementRect.left < 0) setPopWidth(elementRect.width + elementRect.left)
                    if (elementRect.right > window.innerWidth) setPopWidth(window.innerWidth - elementRect.left)
                }
            }
        }
    }

    useEffect(() => {
        const prnt = myref.current!.parentElement
        setParent(prnt!)
        const scrlr = findScrollableParent(prnt!)
        if (scrlr) scrlr.addEventListener('scroll', findPopupPosition)
        window.addEventListener('resize', findPopupPosition)
        findPopupPosition()


        const resizeObserver = new ResizeObserver(() => {
            findPopupPosition()
        })
        resizeObserver.observe(myref.current!)




        return () => {
            if (scrlr) scrlr.removeEventListener('scroll', findPopupPosition)
            window.removeEventListener('resize', findPopupPosition)
            if (myref.current) resizeObserver.unobserve(myref.current)
            resizeObserver.disconnect()
        }
    })

    const stl = { ...props.style }
    stl.top = popTop
    stl.maxHeight = popHeight
    stl.maxWidth = popWidth
    stl.left = popLeft

    return <div ref={myref} {...props} style={{ ...stl, position: "fixed", zIndex: 2 }}>
        {props.children}
    </div>
}
