//***Copyright Notice***
//____________________________________________________
//Copyright © 2024 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import { DialogPlus, SmartButton } from "./misc"
import { ControlProps2, controlRecord2, myStringify, defaultControlProps, rightCut, downloadFile, defaultGridProps, controlRecord, getControlProps2, parseDate } from "./globals"
import { useContext, FC, useState } from "react"
import React from "react"
import { MachshevetClient, DataTypes, CommandResult, LiteRecordData, RecordsData } from "./Declarations"
import { Control } from "./Control"
import { AppContext, ColumnData2, CommandInputProps, handleCommandResult, MainContext } from "./styles"

export const CommandPopup: FC<CommandInputProps & { onClose: () => void }> = props => {
    const app = useContext(AppContext)!
    const ctx = useContext(MainContext)
    const [state, setState] = useState(props)
    let flds = getControlProps2(state.Fields.filter(x => x.Name !== 'LinkedIDs'), state.Columns)
    flds = flds.filter(x => !x.memberData.IsCommand)
    async function setFieldValue(mainField: ControlProps2, newValue: unknown, newRow?: LiteRecordData) {
        const isfil = newValue instanceof File
        setState(prev => {
            const ret = flds.map(x => {
                let newval = x.Value
                let newdisp = x.Value
                if (x.Name === mainField.Name) {
                    if (x.memberData.ListType && newRow) {
                        const v2: RecordsData = x.Value || []
                        v2.Records = v2.Records.concat(newRow)
                        newval = v2
                    }
                    else if (!isfil) newval = newValue
                }
                if (newval && x.memberData.DataType === DataTypes.DateTime) newdisp = parseDate(newval)!.toString()
                return { ...x, Value: newval, DisplayString: newdisp } as ControlProps2
            })

            if (isfil) {
                const newfld = defaultControlProps()
                newfld.Name = mainField.Name + '_File'
                newfld.Value = newValue
                ret.push(newfld)
            }
            return {
                ...prev, Fields: ret
            }
        });
    }

    const lid = flds.filter(x => x.Name === 'LinkedIDs')[0]
    if (lid) {
        const lids = lid.Value
        const lid2 = Object.entries(lids)
        const lidcnts = lid2.map(x => {
            const cp = defaultControlProps()
            const ky = x[0]
            cp.Name = ky
            cp.memberData.LocalName = ctx.localized(ky)
            cp.memberData.ForeignTypeName = rightCut(ky, "ID")
            cp.Value = x[1]
            cp.memberData.DataType = DataTypes.Number
            return cp
        })
        flds = flds.concat(lidcnts)
    }

    return <DialogPlus {...props} title={props.command.LocalName!} footer={props.Columns.filter(x => x.IsCommand).map(x => <SmartButton key={x.Name} testName={x.Name!} onClick={async () => {
        const vals2 = controlRecord2(flds)
        const jso = myStringify(vals2)
        const response = await MachshevetClient.Global.InputCommand(x.Name!, window.location.href, jso, vals2)
        const disposition = response.headers.get('Content-Disposition')
        if (disposition && disposition.startsWith('attachment')) {
            const prnt = response.headers.get('X-ForPrint')
            if (prnt) {
                const reader = new FileReader()
                const blb = await response.blob()
                reader.readAsText(blb)
                reader.onload = () => app.printHtml!(reader.result!.toString())
            } else {
                await downloadFile(response)
            }
        } else {
            const res: CommandResult = await response.json()
            if (res.ObjectType === props.ObjectType) {
                const flds2 = res.Fields.map(x => {
                    const y = x as ControlProps2
                    y.mainRecordType = props.mainRecordType
                    return y
                })
                setState(prev => ({ ...prev, Fields: flds2 }))
            }
            else await handleCommandResult(res, x as ColumnData2, defaultGridProps(), app, undefined, props.colSetter)
        }
        if (!x.KeepOpen) props.onClose()
    }} >{x.LocalName}</SmartButton>)}    >
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: 5 }}>
            {flds.filter(x => x.memberData.Visible !== false).map(column => {
                const cp2 = column
                cp2.mainCommand = props.command.Name
                cp2.mainRecordType = props.mainRecordType
                cp2.modelGetter = () => controlRecord(flds)
                cp2.commandInputGetter = () => flds
                return <div id={"Test_Control_" + column.Name} data-editable={column.memberData.Editable} key={column.Name} style={{ flexGrow: 1 }}>
                    <div>{column.memberData.LocalName}</div>
                    <Control field={{ ...cp2, onChange: async (v, f, n, i) => setFieldValue(column, v, n) }} />
                </div>
            })}
        </div>
    </DialogPlus>
}