//***Copyright Notice***
//____________________________________________________
//Copyright © 2024 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { FC, useState, useRef, HTMLProps, useEffect, useContext } from 'react'
import { NavLink, useEffect2, RecordCard, Icon, Popout } from './misc'
import { GridProps, MachshevetClient, RecordsData, SettingGroup } from './Declarations'
import { ControlProps2, defaultGridProps, numberColor, testAttribs } from './globals'
import { DisplayBox, PickListControl } from './pickers'
import { ListView } from './ListView'
import { AppContext } from './styles'

export const EntityPick: FC<ControlProps2> = props => {
    const app = useContext(AppContext)!
    const [options, setOptions] = useState(props.memberData!.PickList)
    const [showResults, setshowResults] = useState(false)
    const [gridProps, setGridProps] = useState<GridProps>({ ...defaultGridProps(), ReportID: props.memberData!.PickReportID, SettingGroup: SettingGroup.PickColumns, Member: props.Name, Command: props.mainCommand })
    const [selection, setSelection] = useState<{ id: number, name: string }>({ id: 0, name: "" })
    const keyDownHandler = useRef<HTMLProps<HTMLInputElement>['onKeyDown']>()
    const pickRef = useRef<HTMLDivElement>(null)
    const [selectedIndex, setSelectedIndex] = useState<number>()
    const [showCard, setShowCard] = useState(false)
    const [miniRec, setMiniRec] = useState<RecordsData>()
    const timeout = useRef<number>()

    useEffect(() => {
        setOptions(props.memberData.PickList)
    }, [props.memberData.PickList])


    useEffect2(async () => {
        if (props.memberData.Editable) {
            const id = parseInt(props.Value)
            if (id && !options) {
                const name = await MachshevetClient.GetRecordName(props.memberData.ForeignTypeName!, id)
                setGridProps(prev => ({ ...prev, Term: name }))
            }
            if (!props.Value && gridProps.Term) setGridProps(prev => ({ ...prev, Term: "" }))
        }
    }, [props.Value])

    async function refresh(force: boolean) {
        if (props.memberData!.RecordType) {//options cant work with recordtype
            const rec = props.modelGetter && props.modelGetter()
            if (force || rec.ID || !options || options.length == 0 || props.memberData!.RecordType.endsWith("Input")) {//it shouldnt work in new recordadapter, options length will be 0 in bulkedit popup
                if (props.memberData!.RecordType.endsWith("Input")) {
                    const res = await MachshevetClient.Global.GetInputOptions(props.mainRecordType!, props.mainCommand!, props.Name!, rec)
                    setOptions(res)
                } else {
                    const res = await MachshevetClient.GetSelectOptions(props.memberData!.RecordType, rec, props.Name!)
                    setOptions(res!)
                }
            }
        }
    }

    function handleSelect(id: number, name: string) {
        setGridProps(prev => ({ ...prev, Term: name }))
        props.onChange && props.onChange(id)
        setshowResults(false)
        props.onBlur && props.onBlur(id)
    }

    function inputKeyDown(event: KeyboardEvent | React.KeyboardEvent<HTMLInputElement>) {
        const key = event.key;
        const strtidx = selectedIndex === undefined ? -1 : selectedIndex
        if (key === 'ArrowDown') {
            if (!showResults) setshowResults(true);
            else setSelectedIndex(strtidx + 1);
        }
        else if (key === 'ArrowUp') setSelectedIndex(strtidx - 1);
        else if (key === 'Enter' && selection.id > 0) handleSelect(selection.id, selection.name)
    };

    function triggerOpen() {
        timeout.current = window.setTimeout(async () => {
            let rid = props.ForeignID
            if (!props.memberData.ForeignTypeName) rid = props.recordID
            rid = rid || props.Value
            if (rid) {
                const pr = await MachshevetClient.Global.Preview(props.memberData.ForeignTypeName!, rid)
                if (pr.Records) setMiniRec(pr)
            }
        }, 1800)
    }

    let listcontrol = props.memberData!.RecordType!
    if (props.mainCommand) listcontrol = props.mainRecordType!
    if (!listcontrol) listcontrol = props.memberData!.ForeignTypeName!
    let dispvalue = gridProps.Term// || props.Value //props.value is for editmode where we have the persons name in props.value
    if (props.Value && !dispvalue) dispvalue = props.Value

    const fornid = props.ForeignID || (props.Value ? +props.Value : undefined)

    return <div style={{ flexGrow: 1 }}>{
        props.memberData!.Editable ?
            <div id='div7' ref={pickRef} >
                <div style={{ display: 'flex', alignItems: 'center', gap: ".2vmax" }}>
                    {
                        options ?
                            <PickListControl {...props} />
                            :
                            <input {...testAttribs(props)} disabled={props.memberData!.Locked} tabIndex={0} autoComplete="off" type='text' value={dispvalue} style={{ ...props.style, minWidth: dispvalue?.length + 'ch', flexGrow:1 }} draggable onDragStart={e => e.preventDefault()} onDoubleClick={e => e.stopPropagation()} onClick={() => setshowResults(!showResults)}
                                onChange={e => {
                                    const value = e.currentTarget.value
                                    if (!value || value === "") props.onChange && props.onChange(null)
                                    setshowResults(true)
                                    setGridProps(prev => ({ ...prev, Term: value }))
                                    e.stopPropagation()
                                    return false
                                }}
                                onFocus={e => e.target.select()}
                                onKeyDown={e => {
                                    !keyDownHandler.current || keyDownHandler.current(e)
                                    if (e.keyCode == 27) setshowResults(false)
                                    inputKeyDown(e)
                                }}
                            />
                    }
                    {
                        props.showTools != false && <>
                            <NavLink controller={props.memberData.ForeignTypeName} style={{ display: 'flex' }} recordID={props.Value}>
                                <Icon name="Record"
                                    onMouseEnter={() => {
                                        setShowCard(true)
                                        triggerOpen()
                                    }}
                                    onMouseLeave={() => setShowCard(false)} />
                            </NavLink>
                            <Icon name='Add'
                                onClick={() => {
                                    const rd = new RecordsData()
                                    rd.RecordType = props.memberData!.ForeignTypeName
                                    app.openRecord!(rd, async id => {
                                        await refresh(true)
                                        await props.onChange!(id)
                                    }, { Name: gridProps.Term })
                                }}
                            />
                        </>
                    }
                </div>
                {
                    showResults && <Popout style={{ display: 'flex' }}>
                        <ListView activeTab={true} showTools={false} controller={listcontrol} saveState={false} handleSelection={(id, name) => setSelection({ id, name })} gridProps={gridProps} term={gridProps.Term} idxSelected={selectedIndex} resetSelected={e => setSelectedIndex(e)} onRowClicked={(id, name) => handleSelect(id, name)} modelGetter={props.modelGetter} commandInputGetter={props.commandInputGetter}
                        />
                    </Popout>
                }
            </div>
            :
            (fornid || props.editPage) && <NavLink controller={props.memberData!.ForeignTypeName} recordID={fornid} style={{ backgroundColor: numberColor(props.memberData!.BackColor), display: 'flex' }} onMouseLeave={() => setShowCard(false)}
                onMouseEnter={() => {
                    setShowCard(true)
                    triggerOpen()
                }}>
                <DisplayBox {...props} />
            </NavLink>
    }
        {showCard && miniRec && <Popout id="TestGlance" onMouseLeave={() => setShowCard(false)}>  <RecordCard record={miniRec.Records[0]} records={miniRec} />   </Popout>}
    </div>
}