//***Copyright Notice***
//____________________________________________________
//Copyright © 2024 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { FC, useContext } from "react"
import { SvgPick, CommandButton, EditTable } from './misc'
import { EntityPick } from './EntityPick'
import { NumberPick, PercentPick, ColorPick, SpanPick, UrlPick, PhonePick, FilePick, PdfPick, DatePick, BitPick, AudioPick, MoneyPick, MeasurePick, TextPick, ExpiryPick, HtmlPick, FormatPick, EmailPick, LongTextPick, ImagePick, PickListControl, PathPick, AddressPick, DataListControl } from "./pickers"
import { DataTypes, FieldTypes, GridProps } from "./Declarations"
import { adjustColor, ControlProps2, defaultGridProps, numberColor } from "./globals"
import { ListView } from "./ListView"
import { ColumnData2, MainContext } from "./styles"

export const Control: FC<{ field: ControlProps2, term?: string }> = props => {
    const ctx = useContext(MainContext)
    const fld = props.field
    const gp: GridProps = { ...defaultGridProps(), Member: fld.memberData!.SubReportID ? "" : fld.Name, ReportID: fld.memberData!.SubReportID, Command: fld.mainCommand }
    fld.style = { ...props.field.style, borderRadius: 7, borderWidth: 1, borderStyle: "solid", borderColor: "#dddddd", fontSize: fld.memberData.FontSize }
    if (!fld.style?.color) {
        // fld.style.color = fld.memberData!.ForeColor ? numberColor(fld.memberData!.ForeColor) : "#5c5c5c"
        if (fld.memberData.ForeColor) fld.style.color = numberColor(fld.memberData.ForeColor)
    }
    if (!fld.style.fontWeight) fld.style.fontWeight = fld!.memberData.FontWeight
    if (!fld.style.padding) fld.style.padding = ".4vmax .6vmax"
    if (fld.editPage) fld.style.backgroundColor = fld.memberData.BackColor ? numberColor(fld.memberData.BackColor) : adjustColor(ctx.data.PrimaryColor!, .95)
    if (props.term && fld.DisplayString?.toLowerCase().includes(props.term))
        fld.memberData.BackColor = 16776960
    if (fld.ErrorText) {
        fld.style.borderColor = "red"
        fld.style.outline = "red"
    }

    if (fld.memberData.Multiline && fld.memberData.Editable)
        return <LongTextPick {...fld} />
    else if (fld.memberData.FieldType === FieldTypes.CardExpiry)
        return <ExpiryPick {...fld} />
    else if (fld.memberData.DataType === DataTypes.DateTime)
        return <DatePick {...fld} />
    else if (fld.memberData.ForeignTypeName && !fld.memberData.ShowAll)
        return <EntityPick {...fld} />
    else if ((fld.memberData.DataType === DataTypes.List || fld.memberData.ShowAll) && !fld.memberData.IsCommand && !fld.memberData.Internal && fld.memberData.Visible !== false) {
        if (fld.memberData.Editable) {
            return <EditTable {...fld} />
        } else {
            let ctlr = fld.memberData!.RecordType
            if (fld.mainCommand) ctlr = fld.mainRecordType
            if (fld.memberData!.SubReportID) ctlr = "Report"
            return <ListView controller={ctlr!} gridProps={gp} parentRecordID={fld.recordID} modelGetter={fld.modelGetter} activeTab={true} commandInputGetter={fld.commandInputGetter} showTools={false} saveState={false} />
        }
    }
    else if (fld.Name === "LinkedIn")
        return <UrlPick {...fld} template="https://www.linkedin.com/in/{Handle}" />
    else if (fld.memberData.FieldType === FieldTypes.Measure)
        return <MeasurePick {...fld} />
    else if (fld.memberData.IsCommand)
        if (fld.memberData.PrefillOptions)
            return <ListView controller={fld.memberData.RecordType!} gridProps={gp} activeTab={true} showTools={true} FontSize={fld.memberData.FontSize} />
        else {
            const cd = fld.memberData as ColumnData2
            cd.recordID = fld.recordID
            cd.reloader = fld.reloader
            return <CommandButton {...cd} />
        }
    else if (fld.memberData.FormatPicker)
        return <FormatPick  {...fld} />
    else if (fld.memberData.FieldType === FieldTypes.Phone)
        return <PhonePick {...fld} />
    else if (fld.memberData.FieldType === FieldTypes.Html)
        return <HtmlPick {...fld} />
    //else if (fld.memberData.FieldType === FieldTypes.Gender)
    //    return <GenderPick {...fld} />
    else if (fld.memberData.HasPickList && fld.memberData.Visible !== false && !fld.memberData.MergePicker) {
        if (fld.memberData.AutoComplete) {
            return <DataListControl {...fld} />
        } else {
            return <PickListControl {...fld} />
        }
    }
    else if (fld.memberData.DataType === DataTypes.Boolean)
        return <BitPick {...fld} />
    else if (fld.Name === "AddressSearch")
        return <AddressPick {...fld} />
    else if (fld.memberData.DataType === DataTypes.Bytes)
        if (fld.memberData.FieldType === FieldTypes.Pdf)
            return <PdfPick {...fld} />
        else if (fld.memberData.FieldType === FieldTypes.Audio)
            return <AudioPick {...fld} />
        else if (fld.memberData.FieldType === FieldTypes.Bitmap)
            return <ImagePick {...fld} />
        else
            return <FilePick {...fld} />
    else if (fld.memberData.FieldType === FieldTypes.DrawPath)
        return <PathPick {...fld} />
    else if (fld.memberData.FieldType === FieldTypes.Color)
        return <ColorPick {...fld} />
    else if (fld.memberData.FieldType === FieldTypes.Svg)
        return <SvgPick  {...fld} />
    else if (fld.memberData.FieldType === FieldTypes.Email)
        return <EmailPick {...fld} />
    else if (fld.memberData.FieldType === FieldTypes.Url)
        return <UrlPick {...fld} />
    else if (fld.memberData.FieldType === FieldTypes.Money) {

        const nVal = +fld.Value
        const backclr = !nVal ? undefined : nVal > 0 ? 9498256 : 16761035
        fld.style!.backgroundColor = numberColor(backclr)
        //fld.memberData!.BackColor = backclr

        return <MoneyPick {...fld} />
    }
    else if (fld.memberData.FieldType === FieldTypes.Percent)
        return <PercentPick {...fld} />
    else if ((fld.memberData.DataType === DataTypes.TimeSpan && fld.memberData.FieldType !== FieldTypes.Clock) || fld.memberData.FieldType === FieldTypes.Span)
        return <SpanPick {...fld} />
    else if (fld.memberData.DataType === DataTypes.Number)
        return <NumberPick {...fld} />
    else {
        return <TextPick {...fld} />
    }
}