//***Copyright Notice***
//____________________________________________________
//Copyright © 2024 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { FC, useState, useEffect, useRef, useContext } from 'react'
import { Control } from './Control'
import { VecIcon, HamburgerMenu, NavLink, Avatar2, useInterval, SmartButton, useEffect2, SmartDiv, Accordion, MyTab } from './misc'
import { ColumnData, ControlProps, DataTypes, FieldTypes, GridProps, MachshevetClient, SettingGroup, RecordsData, LiteRecordData } from './Declarations'
import { ControlProps2, controlRecord2, defaultControlProps, defaultGridProps, getControlProps2, groupBy, numberColor, RecordFormProps, showConfirm } from './globals'
import { ListView } from './ListView'
import { AppContext, ColumnData2, doCommand, MainContext } from './styles'

export const RecordForm: FC<RecordFormProps> = props => {
    const app = useContext(AppContext)!
    const ctx = useContext(MainContext)
    const [state, setState] = useState(props.Record)
    const [commands, setCommands] = useState<ColumnData[]>([])
    const originalVals = useRef<Record<string, unknown>[]>()
    const [menuShown, setMenuShown] = useState(false)
    const [fieldChanged, setFieldChanged] = useState(0)
    const [dirty, setDirty] = useState(false)
    const [changeField, setChangeField] = useState('')
    const [subChangeField, setSubChangeField] = useState<string>()
    const [subChangeIndex, setSubChangeIndex] = useState<number>()
    const aborter = useRef(new AbortController())

    const recrd = state?.Records[0]
    const rcrdid = recrd?.RecordID
    const rectyp = state?.RecordType

    const flds = state?.Columns?.length ? getControlProps2(recrd!.Fields, state.Columns) : undefined

    function setIsDirty(isDir: boolean) {
        setDirty(isDir)
        props.onDirty && props.onDirty(isDir)
    }

    async function loadRecord() {
        let recid = rcrdid
        if (recid === 0) recid = undefined
        //let kvs = props.presetValues && Object.entries(props.presetValues).map(x => { return { Key: x[0], Value: x[1] } })
        let kvs = props.presetValues //&& Object.entries(props.presetValues).map(x => { return { Key: x[0], Value: x[1] } })
        if (!props.presetValues) kvs = getRecord2()//specially for new records, shouldnt lose data when choosing columns/reload
        const rec = await MachshevetClient.PageRecord(rectyp!, recid, kvs)
        if (!originalVals.current || !originalVals.current.length) {
            const ovals = rec.Records[0].Fields.map(x => {
                const r: Record<string, any> = [x.Name, x.Value]
                return r
            })
            originalVals.current = ovals
        }
        setRecord(rec)
    }
    function setRecord(record: RecordsData) {
        setState(record)
        props.onRefreshed && props.onRefreshed(record)
    }

    async function firstLoad() {
        if (!recrd || !recrd.Fields.length) {//cant check recordid, since it ruins data that was set by changesothers
            await loadRecord()
            if (rcrdid) await MachshevetClient.Global.MarkRecordSeen(rectyp!, rcrdid)
        }
        const cmnds = await MachshevetClient.Global.GetCommands(rectyp!)
        setCommands(cmnds)
        refs.current = cmnds.filter(x => x.PrefillOptions).map(x => ({ ...defaultGridProps(), ReportID: x.PickReportID }))
    }

    useEffect2(async () => {
        await firstLoad()
        window.addEventListener('beforeunload', onBeforeUnload);
        return () => { window.removeEventListener('beforeunload', onBeforeUnload) }
    }, [])

    useEffect(() => {
        if (props.setSaveHandler) props.setSaveHandler(() => save)
    }, [state])

    useEffect2(async () => {
        if (recrd?.Fields.length) {
            aborter.current.abort()
            aborter.current = new AbortController()
            const rec = getRecord()
            //const data = await MachshevetClient.RecordData(props.Record!.RecordType!, rec, changeField, subChangeIndex, subChangeField, aborter.current.signal)
            const data = await MachshevetClient.RecordData(rectyp!, rec, changeField, subChangeIndex, subChangeField, aborter.current.signal)
            if (data) {
                setState(prev => {
                    return {
                        ...prev!, Records: [{
                            ...prev!.Records[0], Fields: prev!.Records[0].Fields.map(x => {
                                //const newfld = data.Fields.find(y => y.Name === x.Name)!
                                const newfld = data.Records[0].Fields.find(y => y.Name === x.Name)!
                                if (!newfld) return x
                                return { ...x, Value: newfld.Value === undefined ? null : newfld.Value, DisplayString: newfld.DisplayString, ErrorText: newfld.ErrorText, WarningText: newfld.WarningText, ErrorRecordID: newfld.ErrorRecordID }
                            })
                        }]
                    }
                })


            }
        }
    }, [fieldChanged, subChangeField, subChangeIndex])

    useInterval(async () => {
        if (ctx.docActive() && !dirty && recrd?.RecordID) {
            await loadRecord();
        }
    }, 12000);

    function onBeforeUnload(e: BeforeUnloadEvent) {
        if (dirty) {
            e.preventDefault()
            e.returnValue = ''
            return
        }
        delete e['returnValue']
    }
    function setFieldValue(fieldName: string, newValue: any, subFieldName?: string, subFieldIndex?: number) {
        setIsDirty(true)
        const isfil = newValue instanceof File
        setState(prev => {
            const nval = isfil ? null : newValue
            const ret = { ...prev!, Records: [{ ...prev!.Records[0], Fields: prev!.Records[0]!.Fields.map(x => x.Name == fieldName && !isfil ? { ...x, Value: nval, DisplayString: nval } : x) }] }
            if (isfil) {
                const newfld = defaultControlProps()
                newfld.Name = fieldName + '_File'
                newfld.Value = newValue
                ret.Records[0].Fields.push(newfld)
            }
            return ret
        })
        var a = fieldChanged + 1
        setFieldChanged(a)
        setChangeField(fieldName)
        setSubChangeField(subFieldName)
        setSubChangeIndex(subFieldIndex)
    }

    //async function handleFieldChange(value: any, field: ControlProps, newrow?: RecordData, subPropertyName?: string, idx?: number) {
    async function handleFieldChange(value: any, field: ControlProps, newrow?: LiteRecordData, subPropertyName?: string, idx?: number) {
        if (state) {
            if (subPropertyName && (idx != undefined)) {
                const newrows: RecordsData = recrd!.Fields.find(x => x.Name === subPropertyName)!.Value
                const nrow = newrows.Records[idx!]
                nrow.Fields = nrow.Fields.map(x => x.Name === field.Name ? { ...x, Value: value, DisplayString: value } : x)
                newrows.Records[idx!] = nrow
                setFieldValue(subPropertyName!, newrows, field.Name, idx)
            } else {
                const fieldName = field.Name!
                if (newrow) {
                    //const rows: RecordData[] = state.Fields.find(x => x.Name === subPropertyName)!.Value;
                    //const rows: RecordData[] = recrd!.Fields.find(x => x.Name === subPropertyName)!.Value;
                    const rows: RecordsData = recrd!.Fields.find(x => x.Name === subPropertyName)!.Value
                    //const a = rows.Records.concat([newrow])
                    rows.Records.push(newrow)
                    value = rows//  a
                }
                setFieldValue(fieldName, value)
            }
        }
    }
    function getRecord() {
        const prec = controlRecord2(flds!, rcrdid)
        prec["TempBulkField"] = recrd!.TempBulkField
        prec["TempBulkIDs"] = recrd!.TempBulkIDs
        prec["TempParentRecordType"] = recrd!.TempParentRecordType
        prec["ChildKeyField"] = recrd!.ChildKeyField
        return prec
    }

    function getRecord2() {
        //bytes should always be upoloaded as File. not sure why the bytes even exist in the state. shouldnt thay be disaply with a url?
        const ret = flds?.filter(x => (x.Value instanceof File || x.memberData?.Editable) && x.memberData?.DataType !== DataTypes.Bytes).reduce((result, item) => {
            let newval = item.Value
            if (item.memberData?.ListType) {
                newval = (newval as RecordsData).Records.map(x => {
                    const newrec: { [index: string]: any } = {}
                    newrec["ID"] = x.RecordID
                    x.Fields.forEach(f => newrec[f.Name!] = f.Value)
                    return newrec
                })
            }
            result[item.Name!] = newval
            return result
        }, {} as any)
        if (ret) {
            ret["TempParentRecordType"] = recrd!.TempParentRecordType
            ret["ChildKeyField"] = recrd!.ChildKeyField
        }
        return ret
    }

    async function save() {
        const warns = flds!.filter(x => x.WarningText)
        if (warns.length) {
            const wrnmsg = warns.map(x => x.memberData!.LocalName + ": " + x.WarningText).join("\n")
            const cnf = showConfirm(wrnmsg + "\n\n" + ctx.localized("SaveAnyway") + "?")
            if (!cnf) return
        }
        const rec = getRecord()
        if (recrd?.TempBulkIDs) {
            await MachshevetClient.SaveBulk(rectyp!, rec, window.location.href)
            props.onSaved && props.onSaved(undefined, rcrdid)
            return undefined
        } else {
            //const obj = flds!.filter(x => x.Value instanceof File || x.memberData!.Editable).reduce((result, item) => {
            //    let newval = item.Value
            //    if (item.memberData?.ListType) {
            //        newval = (newval as RecordsData).Records.map(x => {
            //            const newrec: { [index: string]: any } = {}
            //            newrec["ID"] = x.RecordID
            //            x.Fields.forEach(f => newrec[f.Name!] = f.Value)
            //            return newrec
            //        })
            //    }
            //    result[item.Name!] = newval
            //    return result
            //}, {} as any)

            const obj = getRecord2()

            const record = await MachshevetClient.ApiSaveEdit(rectyp!, rcrdid || 0, obj, window.location.href)
            setIsDirty(false)
            const id = record.Records[0].RecordID!
            props.onSaved && props.onSaved(id, rcrdid)
            setRecord(record)
            return id
        }
    }
    //const groupedFields = flds && groupBy(flds.filter(x => x.memberData && x.memberData.Visible !== false), x => x.Group!)
    const groupedFields = flds && groupBy(flds.filter(x => x.memberData && x.memberData.Visible !== false), x => x.memberData!.Group!)
    const flderr = recrd?.Fields.filter(x => x.ErrorText)[0]
    const haserr = !dirty || flderr != undefined
    const fldcommands = commands.filter(x => x.NeedsField && !x.NeedsReport && !x.NeedsList)
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const PublicUrl = urlParams.get('PublicUrl')
    const gp = defaultGridProps()
    gp.SettingGroup = SettingGroup.EditFields
    if (rcrdid) gp.RecordKeys = new Set([rcrdid.toString()])

    let topbuttons: ColumnData2[] = []
    if (state) {
        topbuttons = commands.filter(x => !x.NeedsField).sortBy(x => -x.Uses).slice(0, 6).map(x => x as ColumnData2)
        if (state.QuickAdds) {
            const qads = state.QuickAdds.map(x => {
                const cp = new ColumnData2()
                cp.RecordType = rectyp
                cp.Name = 'Add'
                cp.LocalName = '+' + ctx.localized(x.Key)
                cp.commandParams = [x.Key + '.' + x.Value]
                return cp
            })
            topbuttons = topbuttons.concat(qads)
        }
    }
    const filledcommands = commands.filter(x => x.PrefillOptions)
    const refs = useRef<GridProps[]>()
    const [closeTab, setCloseTab] = useState(true);

    let fldStl: React.CSSProperties
    if (ctx.data.OldGrid) fldStl = { display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(30em, 1fr))", gridGap: "1vmax", padding: '10px 20px', position: 'relative' }
    else fldStl = { display: "flex", flexWrap: "wrap", columnGap: "4vh", rowGap: "3vh" }

    return <div id={"TestRecord_" + rectyp} style={{ display: "flex", overflowY: 'auto', flexGrow: 1 }}>
        <div className="div2" style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', gap: 10, overflowX: 'auto' }}>
            {state && commands.length > 0 && <div id="TestCommands" className="div3" style={{ display: 'flex', gap: 7 }}>{topbuttons.map(x => <Avatar2 {...x} key={x.Name} gridProps={gp} reloader={async () => {
                if (x.Name === "Delete" && !props.isPopup)
                    window.history.back()
                else
                    await loadRecord()
            }} RecordType={rectyp} params={x.commandParams} style={{ borderWidth: x.commandParams?.length ? 3 : "" }} />)}</div>}

            {refs.current && <Accordion closeTab={closeTab}>{filledcommands.map((x, i) => <MyTab key={x.Name} titleText={x.LocalName}>
                <ListView controller={x.RecordType!} gridProps={refs.current![i]} handleSelections={keys => refs.current![i].RecordKeys = keys} showTools={true} activeTab={true} />
                <SmartButton testName="Okay" onClick={async () => {
                    const cmd = x as ColumnData2
                    cmd.commandParams = [...refs.current![i].RecordKeys]
                    cmd.reloader = loadRecord
                    const gp = defaultGridProps(rcrdid)
                    await doCommand(cmd, 1, gp, app)
                    setCloseTab(!closeTab)
                }}>{ctx.localized("Okay")}</SmartButton>
            </MyTab>)}
            </Accordion>}

            {groupedFields?.size && <div id="TestRecordFields" style={{ overflowY: 'auto', flexGrow: 1 }}>
                {groupedFields && [...groupedFields.keys()].map(grp => {
                    const allflds = groupedFields.get(grp)!
                    const cmnds = allflds.filter(x => x.memberData.IsCommand)
                    const talls = allflds?.filter(x => x.memberData.DataType === DataTypes.List || x.memberData.Multiline || x.memberData.FieldType === FieldTypes.Html || x.memberData.FieldType === FieldTypes.Audio)
                    const fils = allflds?.filter(x => x.memberData.DataType === DataTypes.Bytes && x.memberData.FieldType !== FieldTypes.Audio)
                    const tallnames = talls.map(x => x.Name!).concat(fils.map(x => x.Name!))
                    const flds = allflds?.filter(x => !x.memberData.IsCommand && !tallnames.includes(x.Name!))

                    function showControl(x: ControlProps2, style?: React.CSSProperties) {
                        let stl = style || {}
                        stl = { ...stl, backgroundColor: numberColor(x.memberData.BackColor), color: numberColor(x.memberData.ForeColor), fontSize: x.memberData.FontSize, opacity: x.Useful ? 1 : 0.2 }
                        if (x.memberData.Visible === false) stl.display = "none"
                        if (x.memberData.NoChange && x.Value && state && rcrdid! > 0) x.memberData.Locked = true
                        let waschanged = false
                        if (x.memberData.CausesUnconfirm && originalVals.current) {
                            const origval = originalVals.current.find(y => y[0] === x.Name)
                            if (origval && origval[1] !== x.Value) waschanged = true
                        }

                        const cp2 = x
                        cp2.modelGetter = () => controlRecord2(getControlProps2(recrd!.Fields, state!.Columns), rcrdid) //modelGetter
                        cp2.onChange = (v, f, nr, i) => handleFieldChange(v, f || x, nr, x.Name, i)
                        cp2.reloader = loadRecord
                        cp2.editPage = true
                        cp2.recordID = rcrdid

                        return <div key={x.Name} style={stl} >
                            <SmartDiv id={"Test_Label_" + x.Name} onContextMenu={e => app.showContextMenu!(e, undefined, fldcommands, x, undefined, SettingGroup.EditFields, undefined, undefined, loadRecord)} style={{ padding: 3, fontSize: "90%", display: 'inline-block', fontWeight: 'bold' }} title={x.memberData?.Tooltip} draggable onDragStart={e => e.dataTransfer.setData('text/plain', x.Name!)} onDragOver={e => e.preventDefault()}
                                onDrop={async e => {
                                    const drg = e.dataTransfer.getData('text/plain')
                                    if (drg) {
                                        await MachshevetClient.Global.MoveMember(x.memberData!.RecordType!, gp.SettingGroup, drg, x.Name!)
                                        await loadRecord()
                                    }
                                }}>{x.memberData?.LocalName}</SmartDiv>
                            <span id={"Test_Error_" + x.Name} style={{ color: "red" }}>{x.ErrorText}{x.ErrorRecordID ? <NavLink controller={rectyp!} recordID={x.ErrorRecordID} >{x.ErrorRecordID}</NavLink> : <></>}</span>
                            {!x.memberData?.IsCommand && <span style={{ color: "orange" }}>{x.WarningText}</span>}
                            <div id={"Test_Control_" + cp2.Name} style={{ display: 'flex' }} data-Editable={cp2.memberData?.Editable}><Control field={cp2} /></div>
                            {waschanged && <div style={{ color: "orangered" }}>{ctx.localized("CausesUnconfirm")}</div>}
                        </div>
                    }

                    return <fieldset key={grp} style={{ display: 'flex', flexDirection: 'column', rowGap: "5vh", borderColor: numberColor(ctx.data.SecondaryColor), borderRadius: 5 }}>
                        <legend style={{ fontSize: "120%", fontWeight: 'bold', color: numberColor(ctx.data.SecondaryColor) }}>{grp || ctx.localized('General')}</legend>
                        {cmnds.length > 0 && <div style={{ display: 'flex', gap: 5 }}>
                            {cmnds.sortBy(x => x.memberData!.SelectedPosition).map((x) => showControl(x))}
                        </div>}
                        <div style={fldStl}>
                            {flds!.sortBy(x => x.memberData?.SelectedPosition).map((x) => showControl(x, { flexGrow: 1 }))}
                        </div>
                        {talls.length > 0 && <div style={{ display: "flex", flexWrap: "wrap", gap: "15px" }}>
                            {talls.sortBy(x => x.memberData!.SelectedPosition).map((x) => showControl(x, { flexGrow: 1 }))}
                        </div>}
                        {fils.length > 0 && <div>
                            {fils.sortBy(x => x.memberData!.SelectedPosition).map((x) => showControl(x))}
                        </div>}
                    </fieldset>
                })}
            </div>}
            {!props.setSaveHandler && <div style={{ textAlign: "center" }}>
                <SmartButton testName="Save" disabled={haserr} onClick={save}>{ctx.localized('Save')}</SmartButton>
            </div>
            }
            {
                PublicUrl && <div style={{ textAlign: "center" }}><a style={{ fontSize: "130%" }} href={PublicUrl}>{ctx.localized("Back")}</a></div>
            }
        </div>

        <div style={{ padding: 7, display: 'flex', flexDirection: 'column' }}>
            <VecIcon name={menuShown ? "HideMenu" : "ShowMenu"} onClick={async () => {
                setMenuShown(!menuShown)
            }} />
            {menuShown && <HamburgerMenu onCommand={async c => {
                const gp = defaultGridProps(rcrdid)
                gp.SettingGroup = SettingGroup.EditFields
                c.reloader = async () => {
                    if (c.Name === 'Delete') {
                        props.onSaved && props.onSaved(rcrdid)
                        app.closeRecord() //if deleted on popup
                        window.history.back() //if deleted in edit page
                    } else {
                        await loadRecord()
                    }
                }
                await doCommand(c, 0, gp, app)
                setMenuShown(false)
            }} items={commands.filter(x => !x.NeedsField).map(x => x as ColumnData2)} />}

        </div>
    </div >
}
