//***Copyright Notice***
//____________________________________________________
//Copyright © 2024 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { FC, useState, useContext } from 'react'
//import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Control } from './Control'
import { MachshevetClient, RecordsData } from './Declarations'
import { adjustColor, getControlProps2, groupBy } from './globals'
import { DialogPlus, SmartButton, SmartDiv, useEffect2 } from './misc'
import { MainContext } from './styles'

export const Options: FC<{ onCancel: () => void }> = props => {
    const ctx = useContext(MainContext)!
    const [searchTerm, setSearchTerm] = useState<string>()
    const [group, setGroup] = useState<string>()
    const [state, setState] = useState<RecordsData>()
    const [changes, setChanges] = useState<Record<string, any>>({})
    useEffect2(async () => {
        const options = await MachshevetClient.Global.OptionList()
        setState(options)
    }, [])

    const srch = searchTerm?.toLowerCase()
    let fltrd = state && getControlProps2(state.Records[0].Fields, state.Columns)
    if (srch) fltrd = fltrd && fltrd.filter(x => x.Name!.toLowerCase().includes(srch) || x.memberData!.LocalName!.includes(srch) || x.memberData!.Group?.includes(srch))
    const groups = fltrd && groupBy(fltrd, x => x.memberData!.Group!)
    if (group) fltrd = fltrd && fltrd.filter(x => x.memberData!.Group === group)

    const showopts = srch !== undefined || group !== undefined
    return <DialogPlus {...props} onClose={props.onCancel} title={ctx.localized('Options')} footer={[
        <SmartButton key="Save" testName="Save" onClick={async () => {
            await MachshevetClient.Global.SetOptions(changes)
            props.onCancel()

        }} disabled={Object.keys(changes).length < 1}  >{ctx.localized('Save')} </SmartButton>,
    ]}
    >
        <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'var(--primary-light)' }}>
                <input id="TestSearch" type='search' value={searchTerm} onChange={e => setSearchTerm(e.currentTarget.value)} autoFocus autoComplete="off" />
                <div style={{ overflowY: 'auto' }}>
                    {groups && [...groups.keys()].sortBy(x => x).map(grp => ([
                        <SmartDiv key={grp} style={{ padding: '0.2em 0.7em', fontWeight: 'lighter', fontSize: 'x-large' }} onClick={() => setGroup(grp)}>{grp}</SmartDiv>,
                        searchTerm ?
                            fltrd && fltrd.filter(x => x.memberData.Group === grp).sortBy(x => x.memberData.LocalName).map((item, i) =>
                                <div id={"TestOption_" + item.Name} key={grp + i} style={{ transition: 'all 0.4s ease-out', padding: '0.2em 0.7em', cursor: 'pointer' }} >{item.memberData!.LocalName}</div>) :
                            undefined
                    ]))}
                </div>
            </div>

            {showopts && <div>
                {fltrd && fltrd.map(item => {
                    return <tr key={item.Name} id={"Test_Option_" + item.Name} data-fieldtype={item.memberData.FieldType}>
                        {!item.memberData.IsCommand ?
                            <>
                                <th style={{ color: adjustColor(ctx.data.PrimaryColor!, .6) }}>{item.memberData.LocalName}:</th>
                                <td >
                                    <Control field={{
                                        ...item, showTools: true, onChange: e => {
                                            //setState(prev => ({ ...prev!, Fields: prev!.Records[0].Fields.map(x => x.Name == item.Name ? { ...x, Value: e, DisplayString: e } : x) }))
                                            setState(prev => {
                                                const rec = prev
                                                rec!.Records[0].Fields = prev!.Records[0].Fields.map(x => x.Name == item.Name ? { ...x, Value: e, DisplayString: e } : x)
                                                return rec
                                            })
                                            setChanges({ ...changes, [item.Name!]: e })
                                        }
                                    }} />
                                </td>
                            </> :
                            <td colSpan={2}><SmartButton onClick={() => MachshevetClient.Global.DoCommand(item.Name!)}>{item.memberData.LocalName}</SmartButton></td>}
                    </tr>
                })
                }
            </div>}
        </div>
    </DialogPlus>
}
