//***Copyright Notice***
//____________________________________________________
//Copyright © 2024 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { FC, useContext, useRef, useState } from "react"
import { AppContext, AppContextType, MainContext } from "./styles"
import { getActionUrl, useMaxWidth, getControlProps2, ControlProps2, controlRecord, numberColor, adjustColor } from "./globals"
import { PortalData, MachshevetClient, RecordsData } from "./Declarations"
import { useEffect2, MyTabs, MyTab, Icon, SmartButton, useInterval, NavLink } from "./misc"
import { Control } from "./Control"

export const Portal: FC = () => {
    const ctx = useContext(MainContext)
    const [state, setState] = useState<PortalData>()
    const [user, setUser] = useState("")
    const [password, setPassword] = useState("")
    const [loginMessage, setLoginMessage] = useState<string>()
    const [record, setRecord] = useState<{ reportID: number, recordID?: number }>()

    const [myContext] = useState<AppContextType>({
        openReportRecord: (rep, rec) => setRecord({ reportID: rep, recordID: rec }),
        closeRecord: () => setRecord(undefined)
    })

    async function getData() {
        const newstt = await MachshevetClient.Global.GetPortalData()
        setState(newstt)
    }

    useEffect2(async () => {
        await getData()
    }, []);

    async function tryLogin() {
        const lgn = await MachshevetClient.Login.Login(user, password)
        if (!lgn.Error) await getData()
        else setLoginMessage(lgn.Error)
    }

    return state ? <AppContext.Provider value={myContext}>
        {record && <div style={{ position: "absolute", height: "100vh", width: "100vw", display: "flex", alignItems: "center" }}><dialog open style={{ padding: 0, maxWidth: "80vw" }}><PortalRecord {...record} onClose={() => setRecord(undefined)} /></dialog></div>}
        <div style={{ width: "100%", display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
            <div style={{ backgroundColor: adjustColor(ctx.data.PrimaryColor!,.6), padding: 5, borderRadius: 4, fontWeight: 'bold', display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', gap: 10, flexGrow: 1 }}>
                    <img src={getActionUrl('Global', 'Logo')} style={{ maxWidth: 100 }} />
                    <span> {state.CompanyName}</span>
                </div>
                <div>
                    {state.UserName}
                    <input type="button" value={ctx.localized("LogOut")} onClick={async () => {
                        await MachshevetClient.Login.LogOut()
                        await getData()
                    }} /></div>

            </div>
            {state.UserID ? <>
                <MyTabs>
                    {state.Reports.map(x => <MyTab titleText={x.LocalName!} key={x.ID} >
                        <PortalGrid reportID={x.ID} />
                    </MyTab>)
                    }
                </MyTabs></> : <div style={{ textAlign: "center" }}>
                <label>{ctx.localized("Email") + ":"}</label>
                <input type="text" style={{ backgroundColor: 'white', borderRadius: 8 }} onChange={e => setUser(e.target.value)} />
                <label>{ctx.localized("Password") + ":"}</label><br /><input type="password" style={{ backgroundColor: 'white', borderRadius: 8 }} onChange={e => setPassword(e.target.value)} /><br />
                <SmartButton onClick={() => tryLogin()} > {ctx.localized("Login")} </SmartButton>
            </div>}
            <div style={{ textAlign: "center" }}>
                <span style={{ textAlign: "center", color: "red" }}>{loginMessage}</span>
            </div>
        </div>
    </AppContext.Provider> : <div />
}

export const PortalGrid: FC<{ reportID: number }> = props => {
    const ctx = useContext(MainContext)
    const app = useContext(AppContext)!
    const [state, setState] = useState<RecordsData>()
    const isMobile = useMaxWidth(600)
    const lastRefresh = useRef<number>(0)
    const [isLoading, setIsLoading] = useState(true)

    useEffect2(async () => {
        await refresh()
    }, []);

    useInterval(async () => {
        if (ctx.docActive()) {
            if (lastRefresh.current) {
                const diff = Date.now() - lastRefresh.current
                if (diff > 8000) {
                    if (!isLoading) await refresh()
                }
            }
        }
    }, 500)

    async function refresh() {
        setIsLoading(true)
        const dt = await MachshevetClient.Global.PortalList(props.reportID)
        setState(dt)
        setIsLoading(false)
        lastRefresh.current = Date.now()
    }

    if (!state) return <div />

    if (isMobile) {
        return <div> {state.Records.map(x => {
            const flds = getControlProps2(x.Fields, state.Columns)
            return <table key={x.RecordID}>{flds.filter(x => x.memberData!.Visible).map(y => {
                const cp = y as ControlProps2
                cp.recordID = x.RecordID
                return <tr key={y.Name}><td>{y.memberData!.LocalName}</td><td><Control field={{
                    ...cp, modelGetter: () => {
                        const ret = controlRecord(flds, x.RecordID!)
                        return ret
                    }
                }} /></td></tr>
            })}
            </table>
        }
        )}</div>
    } else {
        return <div>
            <table id="TestPortalGrid">
                <thead style={{ backgroundColor: "#daeff1", color: "#2b686e", position: 'sticky', top: 0 }}>
                    {state.Columns.filter(x => x.Visible).map(x => <th key={x.Name} style={{ padding: "1vh" }}>{x.LocalName}</th>)} <th />
                </thead>
                <tbody>
                    {state.Records.map((x, i) => {
                        const flds = getControlProps2(x.Fields, state.Columns)
                        return <tr key={x.RecordID} style={{ backgroundColor: i % 2 === 1 ? "#edf7f8" : "" }}>{flds.filter(x => x.memberData!.Visible).map(y => {
                            const cp = y as ControlProps2
                            cp.recordID = x.RecordID
                            cp.memberData!.ForeColor = 2844782
                            return <td key={y.Name} style={{ padding: 10 }}><Control field={{
                                ...cp, modelGetter: () => {
                                    const ret = controlRecord(flds, x.RecordID!)
                                    return ret
                                }
                            }} /></td>
                        })}
                            <td><Icon name="Edit" onClick={() => app!.openReportRecord!(props.reportID, x.RecordID)} /> </td>
                        </tr>
                    }
                    )}
                </tbody>
            </table>
            <SmartButton onClick={() => app!.openReportRecord!(props.reportID)}>{ctx.localized("AddNew")}</SmartButton>
        </div>
    }
}

export const PortalRecord: FC<{ reportID: number, recordID?: number, onClose: () => void }> = props => {
    const ctx = useContext(MainContext)
    const [state, setState] = useState<RecordsData>()

    useEffect2(async () => {
        const dt = await MachshevetClient.Global.GetPortalRecord(props.reportID, props.recordID)
        setState(dt)
    }, [])

    const flds = state && getControlProps2(state.Records[0].Fields, state.Columns)
    return state ? <div>
        <div style={{ backgroundColor: numberColor(ctx.data.PrimaryColor), color: "white", textAlign: "center", padding: "1vh" }}>{state.Records[0].RecordName}</div>
        <div style={{ display: 'flex', flexWrap: "wrap", columnGap: "2vw", rowGap: "1vh", padding: "2vh" }}>
            {flds!.map(x => {
                x.onChange = e => setState(prev => ({ ...prev!, Records: [{ ...prev!.Records[0], Fields: prev!.Records[0].Fields.map(y => { return { ...y, Value: y.Name === x.Name ? e : y.Value, DisplayString: y.Name === x.Name ? e : y.DisplayString } }) }] }))
                return <div key={x.Name} style={{ display: "flex", flexDirection: "column" }}>
                    <div>
                        <span>{x.memberData?.LocalName}</span>
                        <span id={"Test_Error_" + x.Name} style={{ color: "red" }}>{x.ErrorText}{x.ErrorRecordID ? <NavLink controller={state?.RecordType!} recordID={x.ErrorRecordID} >{x.ErrorRecordID}</NavLink> : <></>}</span>
                    </div>
                    <Control field={x} />
                </div>
            })}
        </div>
        <SmartButton onClick={async () => {
            const obj = flds!.filter(x => x.memberData!.Editable).reduce((result, item) => {
                result[item.Name!] = item.Value;
                return result;
            }, {} as any)

            await MachshevetClient.Global.SavePortalRecord(props.reportID, obj, props.recordID)
            props.onClose()
        }}>{ctx.localized("Save")}</SmartButton>
        <SmartButton onClick={() => props.onClose()}>{ctx.localized("Close")}</SmartButton>
    </div> : <div />

}
