//***Copyright Notice***
//____________________________________________________
//Copyright © 2024 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { useState, useEffect, useRef, FC, MouseEvent, useContext, CSSProperties } from "react"
import { Editor } from '@tinymce/tinymce-react'
import { Editor as TinyMCEEditor } from 'tinymce'
import TextareaAutosize from 'react-textarea-autosize'
import { Icon, Popout, SmartDiv, VecIcon } from "./misc"
import { closestInterval, colorNumber, ControlProps2, divideFloat, docSource, GetFileBytes, KeyValuePair, leftCut, multipliers, myStringify, numberColor, parseDate, redirect, testAttribs, testid, UtcDate } from "./globals"
import { ColumnData, FieldTypes, Intervals, MachshevetClient, PickListItem, Units } from "./Declarations"
import { devLog } from "./shared"
import { MiniCal } from './MiniCal'
import { AppContext, MainContext } from "./styles"

export const PdfPick: FC<ControlProps2> = props => {
    const src = docSource(props.memberData!.RecordType!, props.recordID!, props.Name!)
    const toshow = props.recordID && !props.memberData!.RecordType?.endsWith("Input") ? true : false
    return <div>{props.memberData!.Editable && <div>
        <input {...testAttribs(props)} type="file" onChange={async e => {
            if (e.currentTarget.files && e.currentTarget.files[0]) {
                const file = e.currentTarget.files[0]
                const bts = await GetFileBytes(file)
                await props.onChange!(bts)
            }
        }} accept="application/pdf" />
        <VecIcon name="Download" onClick={() => window.location.href = docSource(props.memberData!.RecordType!, props.recordID!, props.Name!, undefined, true)} /></div>}
        {toshow && <embed id="TestPdfEmbed" src={src} style={{ width: "100%", height: 400 }} />}
    </div>
}

export const ImagePick: React.FC<ControlProps2> = props => {
    const src = props.Value ? 'data:image/*;base64,' + encodeURI(props.Value.toString()) : undefined
    return props.memberData!.Editable ? <>
        <input {...testAttribs(props)} type="file" style={{ display: 'block' }} onChange={async e => {
            const targ = e.currentTarget
            if (targ.files && targ.files[0]) {
                const file = targ.files[0]
                await props.onChange!(file)
            }
        }} accept="image/*" />
        <img src={src} style={{ maxWidth: 700 }} />
    </>
        : src ? <img src={src} style={{ width: "100%" }} /> : <></>
}

export const TifPick: FC<ControlProps2> = props => {
    const [page, setPage] = useState(1)
    const paglist = Array.from(Array(props.Pages).keys())
    const src = docSource(props.memberData!.RecordType!, props.recordID!, props.Name!, page)
    return <div><div style={{ display: "flex", gap: 5, justifyContent: "center" }}>{paglist.map(x => <span key={x} style={{ border: "var(--primary)", borderWidth: 1, borderStyle: "solid", padding: 3, cursor: "pointer", fontWeight: x + 1 === page ? 900 : "unset" }} onClick={() => setPage(x + 1)}>{x + 1}</span>)}</div><img src={src} /></div >
}

export const AddressPick: React.FC<ControlProps2> = props => {
    const [results, setResults] = useState<KeyValuePair<string, string>[]>([]);
    return <div>
        <input id={testid(props)} style={props.style} onChange={async e => {
            const text = e.currentTarget.value
            const arr = await MachshevetClient.Global.FindPlaces(text)
            setResults(arr!)
        }} />
        <div>
            {results.map(x => <SmartDiv key={x.Key} onClick={() => props.onChange!(x.Key)}>{x.Value}</SmartDiv>)}
        </div>
    </div>
}

export const FilePick: FC<ControlProps2> = props => {
    const app = useContext(AppContext)
    const vref = React.useRef<HTMLVideoElement>(null)
    const [video, setVideo] = useState(false)
    const prp2 = { ...props }
    prp2.memberData = { ...prp2.memberData!, Editable: false }
    const elm = () => {
        const enc = encodeURI(props.Value);
        switch (props.FileType) {
            case "pdf":
                return <PdfPick {...prp2} />
            case "csv":
            case "doc":
            case "txt":
                return <div>{props.FileText}</div>
            case "tif":
                return <TifPick {...prp2} />
            case "docx":
            case "htm":
            case "html":
            case "svg":
                return <div dangerouslySetInnerHTML={{ __html: props.FileHtml! }} />
            default: return <>
                <VecIcon name="Print" onClick={() => {
                    app?.printHtml!("<html><body><div><figure> <img src=\"data:image/*;base64," + props.Value + "\"style={width:\"100%\";}/></figure></div></body></html>")
                }} />
                <img src={'data:image/*;base64,' + enc} style={{ maxWidth: "100%" }} />
            </>
        }
    }

    return <div style={{ flexGrow: 1 }}>
        {props.memberData!.Editable && <div>
            <input {...testAttribs(props)} type="file" onChange={async e => {
                const trg = e.currentTarget;
                if (trg.files && trg.files[0]) {
                    const file = trg.files[0]
                    props.onChange!(file)
                }
            }} /><VecIcon name="StartCamera" onClick={async () => {
                setVideo(true)
                const strm = await navigator.mediaDevices.getUserMedia({ video: true })
                const vid = vref.current!
                vid.srcObject = strm
                await vid.play()

            }} />  
            <Icon name="Download" onClick={() => window.location.href = docSource(props.memberData!.RecordType!, props.recordID!, props.Name!, undefined, true)} /></div>}
        {video && <video ref={vref} />}
        {elm()}
    </div>
}

export const PhonePick: FC<ControlProps2> = props => {
    const p2: ControlProps2 = { ...props }
    const stl: React.CSSProperties = { ...p2.style }
    stl.direction = "ltr"
    p2.style = stl
    async function handleClick() {
        await MachshevetClient.Global.Dial(props.Value as number)
    }
    return <div style={{ display: "flex", alignItems: "center", gap: "1vh" }}>
        {props.memberData!.Editable ? <BasePick  {...p2} onClean={e => e.replace(/[^\d]/g, '')} /> : <a href={"tel:" + props.Value} style={{ direction: "ltr", textDecoration: "none" }}>
            <DisplayBox{...props} />
        </a>}
        {props.Value && <Icon name={'Dial'} onClick={handleClick} />}
    </div>
}

export const EmailPick: FC<ControlProps2> = props => {
    return props.memberData!.Editable ? <BasePick  {...props} /> : <a href={"mailto:" + props.Value} style={{ display: "inline-block", flexGrow: 1, textDecoration: "none" }} ><DisplayBox{...props} /></a>
}

export const NumberPick: FC<ControlProps2> = props => {
    return <BasePick {...props} onClean={v => {
        if (v.length > 18) return v//hack for longs, so ts doesnt ruin precision, might need less then 18
        return v === "" ? v : +v
    }} valueTip={props.Value} />
}

export const MoneyPick: FC<ControlProps2> = props => {
    return <BasePick {...props} onClean={v => {
        v = leftCut(v, 'Fr.')
        v = v.replace(/[^\d.-]/g, '')
        if (v === "-") return ""
        return +v
    }} valueTip={props.Value} />
}

export const BasePick: FC<ControlProps2 & { onClean?: (v: string) => any, onFocus?: React.FocusEventHandler<HTMLInputElement>, onClick?: React.MouseEventHandler<HTMLInputElement>, list?: string }> = props => {
    const [editFlag, setEditFlag] = useState(false)
    const [rawText, setRawText] = useState(props.DisplayString)
    const stl: React.CSSProperties = { ...props.style, borderWidth: 1, flexGrow: 1 }
    const edtbl = props.memberData?.Editable && !props.memberData!.Locked
    let valbind = editFlag ? rawText : props.DisplayString
    valbind = valbind || ""//null makes problems

    useEffect(() => {//needed mainly for datepick, where value is changed not via userinput (but rather via minical click)
        if (!props.serverRefresh && !editFlag) {
            let newval = props.Value
            if (newval instanceof Date) newval = newval.toLocaleString()
            setRawText(newval)
        }
    }, [props.Value])
    stl.minWidth = valbind.length + 'ch'
    stl.width = "100%"
    stl.whiteSpace = props.memberData?.WordWrap ? "break-spaces" : "pre"

    return edtbl ? <input {...testAttribs(props)} type='text' value={valbind} style={stl} onContextMenu={e => e.stopPropagation()} autoComplete="off" list={props.list} title={props.valueTip} draggable
        onDragStart={
            e => e.preventDefault()
        }
        onChange={e => {
            setEditFlag(true)
            const rawVal: any = e.currentTarget.value
            setRawText(rawVal)
            let cleanVal = rawVal
            if (props.onClean) cleanVal = props.onClean(cleanVal)//without this line typing - in moneypicks throws err
            props.onChange!(cleanVal)
        }}
        onBlur={e => {
            let vl = e.currentTarget.value
            if (editFlag) setEditFlag(false)
            if (props.onClean) vl = props.onClean(vl)//without this line, phonepick doesnt work in editmode in datagrid
            props.onBlur && props.onBlur(vl)
        }}
        onDoubleClick={e => e.stopPropagation()}
        onClick={props.onClick}
        onFocus={props.onFocus} />
        : <DisplayBox {...props} />
}

export const DisplayBox: FC<ControlProps2> = props => {
    const ctx = useContext(MainContext)
    const [changeTime, setChangeTime] = useState(Date.now() - 10000)
    const [firstLoad, setFirstLoad] = useState(true)

    useEffect(() => {
        if (!firstLoad) setChangeTime(Date.now())
        else setFirstLoad(false);
    }, [props.Value])

    const cp2 = { ...props }
    const datediff = (Date.now() - changeTime) / 1000
    const instyle: CSSProperties = { userSelect: "all" }
    instyle.whiteSpace = cp2.memberData.WordWrap ? "break-spaces" : "pre"
    instyle.fontSize = cp2.memberData.FontSize || props.style?.fontSize
    const outstyle: CSSProperties = { flexGrow: 1 }
    if (props.memberData.FieldType === FieldTypes.Percent && props.Value) {
        outstyle.backgroundColor = "aquamarine"
        outstyle.width = (props.Value * 100) + '%'
    }
    if (props.editPage) {
        if (props.style) {
            instyle.borderColor = props.style.borderColor
            instyle.borderStyle = props.style.borderStyle
            instyle.borderRadius = props.style.borderRadius
            instyle.borderWidth = props.style.borderWidth
            instyle.padding = props.style.padding
            instyle.fontWeight = props.style.fontWeight
        }
    } else {
        instyle.backgroundColor = numberColor(cp2.memberData.BackColor)
    }
    if (props.style?.backgroundColor) instyle.backgroundColor = props.style.backgroundColor

    if (props.showChanges !== false && props.recordKey !== "0" && !props.memberData?.Editable && (datediff < 9)) instyle.backgroundColor = numberColor(ctx.data.SecondaryColor)


    const color = cp2.memberData?.ForeColor
    return <div style={outstyle} >
        <div {...testAttribs(props)} title={props.valueTip} data-displayname={props.memberData?.LocalName} style={{ ...instyle, color: numberColor(color) || props.style?.color }}>{props.DisplayString || " "}</div>
    </div>
}

export const MeasurePick: React.FC<ControlProps2> = props => {
    const ctx = useContext(MainContext)
    //const [unit, SetUnit] = useState(props.memberData?.PickList[0])
    const [unit, setUnit] = useState((props.memberData!.MeasureUnit && props.memberData?.Editable) ? props.memberData!.PickList.find(x => x.Value === ctx.localized(Units[props.memberData!.MeasureUnit!])) : (props.memberData?.PickList ? props.memberData!.PickList[0] : undefined))
    const [editFlag, setEditFlag] = useState(false)
    const [dispValue, setDispValue] = useState('')
    const [value, setValue] = useState(props.Value)

    function refreshUnit() {
        if (value) {
            const descSorted = props.memberData!.PickList.sortBy(x => -x.Key)
            const u = descSorted.find(x => value / x.Key >= 1)
            setUnit(u)
            const vl = +value
            const ky = +u?.Key
            const endval = divideFloat(vl, ky)
            setValue(endval)
            setDispValue(endval.toString())
        }
    }
    useEffect(() => {
        if (props.memberData!.Editable) {
            if (props.memberData!.PickList) refreshUnit()
            if (!value && !unit && props.memberData) {
                const lst = props.memberData.PickList.sortBy(x => x.Key)
                let newunt = lst[0]
                if (props.memberData.MeasureUnit) {
                    const untnam = Units[props.memberData.MeasureUnit]
                    const trnslt = ctx.localized(untnam.toString())
                    newunt = props.memberData.PickList.find(x => x.Value === trnslt)!
                }
                setUnit(newunt)
            }
        }
    }, [])

    return props.memberData!.Editable ? <div style={{ gap: "20px" }}>
        <input {...testAttribs(props)} type='number' style={{ ...props.style }} value={editFlag || !props.serverRefresh ? dispValue : value}
            onChange={e => {
                setEditFlag(true)
                const inpt = e.currentTarget.value
                setDispValue(inpt)
                setValue(+inpt)
                const val = +inpt * (unit?.Key)
                props.onChange!(val)
            }}
            onBlur={() => setEditFlag(false)} />
        <select value={unit?.Value} style={props.style} onChange={e => {
            const u = props.memberData!.PickList.find(x => x.Value === e.currentTarget.value)!
            setUnit(u)
            props.onChange!(value * u.Key);
        }}>
            {props.memberData!.PickList && props.memberData!.PickList.sort((a, b) => a.Key - b.Key).map(x => <option key={x.Key} value={x.Value}>{x.Value}</option>)}
        </select>
    </div> : <DisplayBox {...props} />
}

export const PercentPick: FC<ControlProps2> = props => {
    function divideByHundred(value: string) {
        const v = value.replace(/[^\d.-]/g, '')
        const val = v.split(".")
        let wholeval = val[0]
        const decimalValue = val[1]

        if (wholeval.startsWith("-")) wholeval = '-00' + wholeval.substring(1, wholeval.length)
        else wholeval = '00' + wholeval

        const sStart = wholeval ? wholeval.substring(0, wholeval.length - 2) : '';
        const sEnd = wholeval ? wholeval.substring(wholeval.length - 2, wholeval.length) : '';
        const st2 = (sStart ? sStart : '') + '.' + (sEnd ? sEnd : '') + (decimalValue ? decimalValue : '');
        const val2 = parseFloat(st2);
        return val2;
    }
    return <BasePick {...props} onClean={v => divideByHundred(v)} valueTip={props.Value} />
}

export const ColorPick: React.FC<ControlProps2> = props => {
    const hex = props.Value ? numberColor(+props.Value) : ''//that plus is cuz sometimes it comes in as string
    return props.memberData!.Editable ?
        <div style={{ display: 'flex', flexGrow: 1 }}>
            <input type="color" value={hex} onChange={e => props.onChange!(colorNumber(e.currentTarget.value))} style={{ flexGrow: 1 }} />
            <BasePick {...props} />
            <Icon name="Clear" onClick={async () => await props.onChange!(null)} />
        </div>
        :
        <div style={{ backgroundColor: numberColor(props.Value) }}>{props.Value}</div>
}

export const SpanPick: FC<ControlProps2> = props => {
    const ctx = useContext(MainContext)
    const [interval, setInterval] = useState<Intervals>()
    const [num, setNum] = useState<number>()
    useEffect(() => {
        if (props.memberData!.Editable) {
            const val = closestInterval(props.Value)
            setInterval(val[0])
            setNum(val[1])
        }
    }, [props.Value])

    return props.memberData!.Editable ? <div style={{ display: 'flex', flexGrow: 1 }}>
        <select value={interval} id="TestIntervals" onChange={async e => {
            const intv = +e.currentTarget.value as Intervals
            setInterval(intv)
            const mlt = multipliers()[intv!]
            const newval = num! * mlt
            props.onChange!(newval)
        }} style={props.style}>
            <option>{ctx.localized('Choose') + '...'}</option>
            {Object.keys(Intervals).filter(x => !isNaN(+x)).map(x => <option id={"TestIntervals" + "_" + x} value={x} key={x} >{ctx.localized(Intervals[+x] + 's')}</option>)}
        </select>
        <input {...testAttribs(props)} type='number' style={{ ...props.style, flexGrow: 1 }} value={num || ""} onChange={e => {
            const value = e.currentTarget.valueAsNumber
            setNum(value)
            const mlt = multipliers()[interval!]
            const newval = value * mlt
            props.onChange!(newval)
        }} />
    </div> : <DisplayBox {...props} />
}

export const UrlPick: React.FC<ControlProps2 & { template?: string }> = props => {
    let str = props.Value ? props.Value as string : '';
    if (props.template) str = props.template.replace("{Handle}", props.Value)
    return props.memberData!.Editable ?
        <>
            <BasePick {...props} />
            <a href={str} style={{ display: 'flex' }}><Icon name="Navigate" /></a>
        </>
        :
        <a href={str} style={{ display: "block" }}>{str}</a>
}

export const DatePick: FC<ControlProps2> = props => {
    const ctx = useContext(MainContext)
    const [opened, setOpened] = useState(false)

    useEffect(() => {
        setOpened(false)
    }, [props.Value])


    let dat = props.Value && typeof props.Value == "string" ? parseDate(props.Value) : undefined
    if (props.Value instanceof Date) dat = props.Value
    const returntext = (num: number, interval: Intervals) => { return (Math.round(num * 100) / 100) + ' ' + ctx.localized(Intervals[interval] + 's') }
    const dt = new Date(props.Value)
    const curtim = new Date().getTime()
    const valtim = dt.getTime()
    const diff = Math.abs(valtim - curtim)
    let ttip = ""
    if (diff < 1000) ttip = returntext(diff, Intervals.Millisecond)
    else if (diff < 1000 * 60) ttip = returntext(diff / 1000, Intervals.Second)
    else if (diff < 1000 * 60 * 60) ttip = returntext(diff / 1000 / 60, Intervals.Minute)
    else if (diff < 1000 * 60 * 60 * 24) ttip = returntext(diff / 1000 / 60 / 60, Intervals.Hour)
    else if (diff < 1000 * 60 * 60 * 24 * 7) ttip = returntext(diff / 1000 / 60 / 60 / 24, Intervals.Day)
    else if (diff < 1000 * 60 * 60 * 24 * 30.4167) ttip = returntext(diff / 1000 / 60 / 60 / 24 / 7, Intervals.Week)
    else if (diff < 1000 * 60 * 60 * 24 * 30.4167 * 12) ttip = returntext(diff / 1000 / 60 / 60 / 24 / 30.4167, Intervals.Month)
    else ttip = returntext(diff / 1000 / 60 / 60 / 24 / 30.4167 / 12, Intervals.Year)
    return props.memberData!.Editable ?
        <div style={{ position: "relative", flexGrow: 1, display: 'flex', flexDirection: 'column' }}  >
            <BasePick {...props} onClick={() => setOpened(!opened)} onClean={v => {
                const dt = parseDate(v, ctx.data.PickerDateFormat!)
                if (!dt) return ""
                devLog("clean " + dt.toString(), dt)
                //return dt
                const ret = dt.toISOString()
                return ret
            }} />
            {opened && <Popout tabIndex={-1} style={{ display: "flex" }}>
                <MiniCal selectedDate={dat} showTime={(props.memberData!.ShowTime || true) && (props.memberData!.DatePrecision == undefined || props.memberData!.DatePrecision <= 3)} onSelected={e => {
                    //devLog("selected " + e.toString(), e)
                    //props.onChange!(e)
                    const dt = e.toISOString()
                    props.onChange!(dt)
                    setOpened(false)
                }} />
            </Popout>}
        </div >
        : <DisplayBox {...props} valueTip={ttip} />
}

export const BitPick: React.FC<ControlProps2> = props => {
    const ctx = useContext(MainContext)
    const val: boolean | null = props.Value
    if (props.memberData!.Editable) {
        if (props.memberData!.Required)
            return <div {...testAttribs(props)} style={{ backgroundColor: val ? numberColor(ctx.data.PrimaryColor) : "lightgray", borderRadius: 10, display: "flex", justifyContent: val ? "end" : "start", minWidth: 30, flexGrow: 1 }} onClick={async () => {
                const vl = !val
                await props.onChange!(vl)
            }}>
                <div style={{ height: 20, width: 15, borderRadius: 10, backgroundColor: "white", boxShadow: "0 0.1em 0.3em rgba(0,0,0,0.3)" }} />
            </div>
        else
            return <select {...testAttribs(props)} value={props.Value} onChange={(e) => {
                const val = e.currentTarget.value
                const newValue = val === "null" ? null : val === "true"
                props.onChange!(newValue)
            }} style={{ ...props.style, width: "100%" }}>
                <option id={"Test" + props.Name + "_"} value="null">{ctx.localized("Default")}</option>
                <option id={"Test" + props.Name + "_1"} value="true">{ctx.localized("Yes")}</option>
                <option id={"Test" + props.Name + "_0"} value="false">{ctx.localized("No")}</option>
            </select>
    }
    else {
        const pr2 = { ...props }
        //pr2.memberData!.ForeColor = val ? 32768 : 16711680
        pr2.DisplayString = val ? "✓" : "X"
        if (val === null || val === undefined) pr2.DisplayString = ""
        pr2.style!.color = numberColor(val ? 32768 : 16711680)
        return <DisplayBox {...pr2} />
    }
}

//export const GenderPick: React.FC<ControlProps2> = props => {
//    const ctx = useContext(MainContext)
//    return props.memberData!.Editable ? <select id={testid(props)} style={{ ...props.style, width: "100%" }} value={props.Value} onChange={e => props.onChange!(e.currentTarget.value)}>
//        <option value="true">{ctx.localized("Male")}</option>
//        <option value="false">{ctx.localized("Female")}</option>
//    </select> : <DisplayBox {...props} />
//}

export const AudioPick: React.FC<ControlProps2> = props => {
    const src = 'data:audio/wav;base64,' + props.Value?.toString()
    return <div>
        <input type="file" id={testid(props)} onChange={e => {

            if (e.currentTarget.files && e.currentTarget.files[0]) {
                const file = e.currentTarget.files[0]
                //const bts = GetFileBytes(file)
                props.onChange!(file)
            }




        }} style={{ display: 'block' }} accept="audio/*" />
        <audio controls>
            <source src={src} />
        </audio>
    </div>
}

export const TextPick: FC<ControlProps2> = props => {
    return <BasePick {...props} />
}

export const LongTextPick: FC<ControlProps2> = props => {
    const dval = props.Value ? props.Value.toString() : ''
    const stl = props.style || {}
    return <TextareaAutosize {...testAttribs(props)} value={dval} style={{ borderRadius: stl.borderRadius, borderWidth: stl.borderWidth, borderStyle: stl.borderStyle, padding: stl.padding, fontSize: stl.fontSize, fontWeight: stl.fontWeight, color: stl.color, width: "100%", backgroundColor: stl.backgroundColor, borderColor: stl.borderColor }}
        onChange={async e =>
            await props.onChange!(e.currentTarget.value, undefined, undefined, undefined)
        }
        onBlur={e => {
            props.onBlur && props.onBlur(e.currentTarget.value)
        }}
    />
}

export const ExpiryPick: React.FC<ControlProps2> = props => {
    return props.memberData!.Editable ? <BasePick {...props} onClean={e => {
        const match = e.match(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/)
        if (match) {
            const month = +match[1] - 1
            const year = '20' + match[2]
            //const dt = new Date(+year, month, 1)
            const dt = UtcDate(new Date(+year, month, 1))
            devLog("date" + dt, dt)
            return dt
        }
        return undefined
    }} /> : <DisplayBox {...props} />
}

export const HtmlPick: FC<ControlProps2> = props => {
    const ctx = useContext(MainContext)
    const injectedHtmlRef = useRef(null);
    useEffect(() => {
        if (injectedHtmlRef.current) {
            (injectedHtmlRef.current as HTMLElement).addEventListener('dblclick', (e) => {
                const t = e.target as HTMLElement
                const rcid = t.dataset.recordid;
                const rctyp = t.dataset.recordtype;
                if (rcid && rctyp) {
                    redirect(undefined, rctyp, +rcid, false);
                }
                console.log(rcid, rctyp);
            });
        }
    }, []);
    
    function setupHandler(editr: TinyMCEEditor) {
        editr.ui.registry.addButton('ClearMargin', {
            text: ctx.localized("ClearMargin"),
            onAction: function () {
                const body = editr.getBody()
                const pEls = body.querySelectorAll('p')
                pEls.forEach(p => {
                    if (p.style.marginBlock !== '0px') {
                        p.style.marginBlock = '0'
                        p.style.marginBottom = '0'
                        p.setAttribute('data-mce-style', 'margin-block: 0px;margin-bottom: 0px')
                    } else {
                        p.style.marginBlock = '16px'
                        p.style.marginBottom = '16px'
                        p.setAttribute('data-mce-style', 'margin-block: 16px;margin-bottom: 16px')
                    }
                })
                editr.save()
            }
        })

        editr.ui.registry.addMenuButton('fields', {
            text: ctx.localized("DataFields"),
            fetch: function (callback: any) {
                const items = props.memberData!.PickList ? props.memberData!.PickList.sortBy(x => x.Value).map(x => {
                    return {
                        type: 'menuitem',
                        text: x.Value,
                        onAction: function () {
                            editr.insertContent("{" + x.Key + "}");
                        }
                    };
                }) : [];
                callback(items);
            }
        });
    }
    const rand = Date.now().toString() + Math.random().toString(16).slice(2); //to show 2 html pickers in the same page - as when report is open in edit and in popup

    return props.memberData.Editable ?
        <Editor id={testid(props) + rand} value={props.Value as string || ''} data-value={props.Value} data-fieldtype={props.memberData.FieldType} apiKey="lxi00e9pxi95iuxlk5byy1l5jaq7a6jxx3a6fib0miqtqlkd" onEditorChange={e => props.onChange!(e)} plugins={'code'}
            init={{
                menubar: 'edit view format tools',
                plugins: ['directionality', 'pagebreak', 'link'],//, 'image'
                toolbar: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | ltr rtl | pagebreak | fields | ClearMargin | link',// | image',
                setup: setupHandler,
                relative_urls: false,
                remove_script_host: false,
                pagebreak_separator: '<div style="page-break-before: always"></div>',
                //newline_behavior: 'linebreak',
                //forced_root_block: 'p',
                //newline_behavior: 'invert',
                end_container_on_empty_block: true,
                // valid_elements:"@[id|data*|data-fieldtype]",
                ui_mode: 'split' //to show the menus in dialog - if doesnt work, try the next hack. nnp. 2/5/23
                //HACK - start
                //init_instance_callback: function (editor) {
                //    // move last aux helper to dialog, if field is in a dialog
                //    const dialogContainer = editor.editorContainer.closest('dialog')
                //    if (dialogContainer) {
                //        const auxElements = document.querySelectorAll('body > .tox-tinymce-aux')
                //        if (auxElements.length) dialogContainer.append(auxElements[auxElements.length - 1])
                //    }
                //}
                //HACK - end
            }} />
        :
        <>
            {/*<span>test test </span>*/}
            <span dangerouslySetInnerHTML={{ __html: props.Value }} ref={injectedHtmlRef} />
        </>
}

export const FormatPick: FC<ControlProps2> = props => {
    return props.memberData!.Editable ? <div style={{ display: "flex" }}>
        <BasePick  {...props} />
        <select style={{ ...props.style, width: "100%" }} onChange={e => props.onChange!(e.currentTarget.value)}>{props.memberData!.PickList?.map(x => <option value={x.Key} key={x.Key}>{x.Key + '  --  ' + x.Value} </option>)}</select>
    </div> : <DisplayBox {...props} />
}

export const PathPick: FC<ControlProps2> = props => {
    const [sign, setSign] = useState(props.Value)
    const [mouseDown, setMouseDown] = useState(false)

    function isTouchEvent(e: MouseEvent) { return e.type.match(/^touch/); }

    function getCoords(e: MouseEvent) {
        if (isTouchEvent(e)) {
            var t = e as unknown as TouchEvent
            return t.targetTouches[0].clientX + ',' + t.targetTouches[0].clientY;
        }
        var parentOffset = e.currentTarget.getBoundingClientRect();
        return (e.pageX - parentOffset.left) + ',' + (e.pageY - parentOffset.top);
    }

    function handleMouseDown(e: MouseEvent) {
        setMouseDown(true);
        var coords = getCoords(e);
        var newsign = (sign || "") + 'M' + coords + ' ';
        setSign(newsign);
    }

    function handleMouseMove(e: MouseEvent) {
        if (mouseDown) {
            var coords = getCoords(e);
            var newsign = (sign || "") + 'L' + coords + ' ';
            setSign(newsign);
        }
    }

    function handleMouseUp() {
        if (mouseDown) {
            setMouseDown(false);
            props.onChange!(sign);
        }
    }

    return props.memberData!.Editable ? < div style={{ display: "flex" }}>
        <svg style={{ color: "transparent", height: "50px", width: "300px" }} fill="White" >
            <rect style={{ color: "transparent", width: "100%", height: "50px" }} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onMouseMove={handleMouseMove} onMouseLeave={handleMouseUp} />
            <path stroke="navy" strokeWidth="2" fill="none" pointerEvents="none" d={sign} />
        </svg>
        <VecIcon name="Clear" onClick={async () => {
            setSign(null);
            await props.onChange!(null);

        }} />
    </div>
        : <svg style={{ color: "transparent", height: "50px", width: "300px" }}  >
            <path stroke="navy" strokeWidth="2" fill="none" pointerEvents="none" d={sign} />
        </svg>
}
export const PickListControlX: FC<ControlProps2> = props => {
    return <select id={testid(props)}>
        {props.memberData?.PickList.map(x => <option id={"Test" + props.Name + "_" + x.Key} value={x.Key} key={x.Key}>{x.Value}</option>)}
    </select>
}

export const PickListControl: FC<ControlProps2> = props => {
    const ctx = useContext(MainContext)
    const [options, setOptions] = useState(props.memberData!.PickList)

    let opts = options || []
    if (!opts.length) {
        //opts.push({ Key: props.Value, Value: props.DisplayString || "" })
        opts = opts.concat({ Key: props.Value, Value: props.DisplayString || "" })
    }

    async function refresh() {
        if (props.memberData!.RecordType && props.memberData!.Editable) {//in column>daterange filter there is not recordtype and should not be refreshed
            const mdl = props.modelGetter && props.modelGetter();
            if (props.mainCommand) {
                const js = myStringify(mdl)
                const res = await MachshevetClient.Global.GetInputOptions(props.mainRecordType!, props.mainCommand!, props.Name!, js);
                setOptions(res)
            } else {
                //this is neccessary for permissionzone>fieldname
                const res = await MachshevetClient.GetSelectOptions(props.memberData!.RecordType, mdl, props.Name!);
                setOptions(res)
            }
        }
    }
    let val = props.Value
    if (val === undefined) val = ""
    // if (props.ForeignID) val = props.ForeignID //this made bug in placememnt shiftid, changing in ui did not show on scren

    const usenew = opts.some(x => x.Icon)
    const md2 = { ...props.memberData } as ColumnData
    md2!.PickList = opts// options


    return props.memberData!.Editable ? <>
        {!usenew && <select {...testAttribs(props)} value={val} style={{ ...props.style, flexGrow: 1 }} onMouseDown={async () => refresh()} onChange={async e => {
            let val: unknown = e.currentTarget.value
            const num = Number(val)
            if (num || val === "0") val = num
            props.onChange!(val)
            if (props.onBlur) props.onBlur(val)
        }}>
            <option value="" id={"Test" + props.Name + "_"}>{ctx.localized("Choose") + '...'}</option>
            {opts.map(x => <option id={"Test" + props.Name + "_" + x.Key} value={x.Key} key={x.Key}>{x.Value}</option>)}
        </select>}
        {usenew && <SelectPlus {...props} memberData={md2} />}
    </> : <DisplayBox {...props} />
}

export const DataListControl: FC<ControlProps2> = props => {
    let val = props.Value
    if (val === undefined) val = ""
    const listid = props.Name + "DataList"
    return props.memberData?.Editable ?
        <>
            <BasePick  {...props} list={listid} />
            <datalist id={listid}>{props.memberData?.PickList?.map(x => <option key={x.Key}>{x.Value}</option>)}</datalist>
        </>
        : <DisplayBox {...props} />
}

export const SelectPlus: FC<ControlProps2> = props => {
    const ctx = useContext(MainContext)
    const [isOpen, setIsOpen] = useState(false)
    const selopt = props.memberData!.PickList.find(x => x.Key === props.Value)
    const pli: PickListItem = { Key: "", Value: ctx.localized("Choose") + "…" }
    const opts = [pli].concat(props.memberData!.PickList)
    const internalstyle = { ...props.style }
    internalstyle.padding = ""

    return <div {...testAttribs(props)} style={{ flexGrow: 1, position: "relative" }}>
        <div onClick={e => {
            e.stopPropagation()
            setIsOpen(!isOpen)
        }} style={{ ...props.style, display: "flex", justifyContent: "space-between" }} >
            {selopt ? <div style={{ display: "flex", cursor: "pointer", gap: ".3vmax" }}>
                <Icon name={selopt.Icon!} />
                <div style={{ whiteSpace: "nowrap" }}> {selopt.Value}</div>
            </div> : <div style={{ cursor: "default" }}>{ctx.localized("Choose" + "...")}</div>}
            ▼
        </div>
        {isOpen && <Popout style={{ ...props.style, overflowY: "auto" }}>
            {opts.map(x => (
                <SmartDiv key={x.Key} style={{ display: "flex", gap: ".3vmax", margin: ".2vmax", }} onClick={() => {
                    props.onChange!(x.Key)
                    setIsOpen(false)
                }}>
                    <Icon name={x.Icon!} />
                    <div style={{ whiteSpace: "nowrap" }} id={"Test" + props.Name + "_" + x.Key}> {x.Value}</div>
                </SmartDiv>
            ))}
        </Popout>
        }
    </div>

}
