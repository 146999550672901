//***Copyright Notice***
//____________________________________________________
//Copyright © 2024 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { FC, useState, useContext, useRef, CSSProperties } from "react"
import { Control } from "./Control"
import { SmartDiv, VecIcon } from "./misc"
import { ColumnData, DataTypes, DateRanges, FieldCountData, FieldTypes, GridProps, MachshevetClient, PickListItem, SettingGroup } from "./Declarations"
import { defaultControlProps, enumList, numberColor } from "./globals"
import { AppContext, MainContext } from "./styles"

interface colProps {
    column: ColumnData,
    stateSetter: (state: ColumnData) => void,
    gridProps: GridProps,
    fullPage: boolean,
    controller: string,
    editModeToggler: () => void,
    recordType?: string,
    showTools: boolean,
    commands?: ColumnData[],
    refresher: () => void
}

export const ColumnHeader: FC<colProps> = props => {
    const app = useContext(AppContext)!
    const ctx = useContext(MainContext)
    const [showFilter, setShowFilter] = useState<boolean>(false)
    const [searchOptions, setSearchOptions] = useState<FieldCountData[]>([])
    const [showButton, setShowButton] = useState<boolean>()

    const topstyle: React.CSSProperties = { position: "sticky", top: 0, backgroundColor: "white", padding: 4, zIndex: 1 }
    if (props.column.Visible === false) topstyle.color = "lightgray"
    const cd = props.column
    const cp = defaultControlProps()
    cp.memberData = cd
    cp.reloader = props.refresher

    function handleCheck(Key: string, toAdd: boolean) {
        let selected = cd.ForeignTypeName ? (props.column.IDFilter || "").split(",") : JSON.parse(props.column.FilterList || "[]") as string[]
        if (toAdd) {
            selected = selected.concat(Key)
        }
        else {
            const i = selected.indexOf(Key)
            selected.splice(i, 1)
        }
        if (cd.ForeignTypeName) cd.IDFilter = selected.join()
        else {
            cd.FilterList = selected.length ? JSON.stringify(selected) : undefined
        }
        props.stateSetter(cd)
    }
    function isSelected(value: string): boolean {
        let checked = false
        if (value != undefined) {
            let selected: string[] = JSON.parse(cd.FilterList || "[]")
            if (cd.ForeignTypeName) selected = (cd.IDFilter || "").split(",")// JSON.parse('[' + state.IDFilter + ']' || "[]")
            checked = selected.some(x => x === value)
        }
        //var a = 1
        //alert(a)
        //var dummy=abc
        return checked
    }

    const abortController = useRef(new AbortController())
    async function checkOptions(forceRefresh?: boolean) {
        const torefresh = !searchOptions.length || forceRefresh
        if (torefresh) {
            abortController.current.abort()
            abortController.current = new AbortController()
            const data = await MachshevetClient.FieldCount(props.controller, props.gridProps, cd.Name!, cd.FilterText!, abortController.current.signal)
            setSearchOptions(data)
        }
    }

    function Filter(Name: string, FieldName: 'MinFilter' | 'MaxFilter' | 'FilterText' | 'IncludeEmpties' | 'DateRange' | 'AutoFilter' | 'ReverseFilter' | 'StartSpan' | 'EndSpan', DataType?: DataTypes, FieldType?: FieldTypes, Picklist?: PickListItem[]) {
        const cp2 = defaultControlProps()
        cp2.memberData = { ...props.column }
        if (DataType == DataTypes.Text) cp2.memberData.DataType = DataTypes.Text
        cp2.memberData.DataType = DataType || cd.DataType
        cp2.Name = FieldName
        cp2.Value = cd[FieldName]
        cp2.DisplayString = cp2.Value
        if (FieldType) cp2.memberData.FieldType = FieldType
        if (cp2.memberData.FieldType === FieldTypes.Html) cp2.memberData.FieldType =undefined

        if (Picklist) {
            cp2.memberData.PickList = Picklist
            cp2.memberData.HasPickList = true
            cp2.memberData.DataType = undefined
        } else {
            cp2.memberData.HasPickList = false
        }
        cp2.memberData.RecordType = undefined//so it should not call server for refreshing options
        cp2.memberData.ForeignTypeName = undefined
        cp2.memberData.Locked = false
        cp2.memberData.Editable = true
        cp2.memberData.AutoComplete = false
        cp2.memberData.MergePicker=false
        cp2.style = { padding: 1 }

        return <div style={{ display: "flex", gap: 3 }}><span style={{ fontSize: "90%" }}>{ctx.localized(Name)}</span>
            <Control field={{
                ...cp2, onChange: async e => {
                    const fltval = e
                    if (FieldName == 'IncludeEmpties') cd.IncludeEmpties = fltval
                    else if (FieldName == 'ReverseFilter') cd.ReverseFilter = fltval
                    else cd[FieldName] = fltval
                    props.stateSetter(cd)
                    await checkOptions(true)
                }
            }} /></div>
    }

    const stlname: CSSProperties = { color: "#296067", cursor: "default", display: "flex", justifyContent: "center", gap: 5 }
    if (cd.AutoFilter || cd.DateRange || cd.FilterList || cd.FilterText || cd.IDFilter || cd.MinFilter || cd.MaxFilter) stlname.color = "orange";

    return <th id={"TestColumn" + cd.Name} style={topstyle} onMouseLeave={() => setShowButton(false)} onMouseEnter={() => setShowButton(true)} draggable onDragStart={e => e.dataTransfer.setData('text/plain', cd.Name!)} onDragOver={e => e.preventDefault()}
        onDrop={async e => {
            const drg = e.dataTransfer.getData('text/plain')
            if (drg) {
                await MachshevetClient.Global.MoveMember(cd.RecordType!, SettingGroup.Columns, drg, cd.Name!, props.gridProps.SettingKey, props.gridProps.ReportID)
                props.refresher()
            }
        }} >

        <SmartDiv id={"TestSort_" + cd.Name} style={stlname} onContextMenu={e => app.showContextMenu!(e, undefined, props.commands, cp, undefined, undefined, props.gridProps.SettingKey, props.stateSetter)} onClick={e => {
            if (e.ctrlKey) {
                if (cd.SortOrder !== undefined) cd.SortDescending = !cd.SortDescending
                else {
                    const last = Math.max(...props.gridProps.Fields.map(y => y.SortOrder || -1))
                    cd.SortOrder = last + 1
                }
            } else {
                if (cd.SortOrder) cd.SortDescending = !cd.SortDescending
                props.gridProps.Fields.forEach(x => {
                    if (x.ColumnKey === cd.ColumnKey) x.SortOrder = 1
                    else x.SortOrder = undefined
                })
            }
            props.stateSetter(cd)
        }}>   <span id={"Test_Title_" + cd.Name} >{cd.LocalName}</span>
            {props.column.SortOrder && <span id={"TestSort_Order_" + cd.Name} >{cd.SortDescending ? "▲" : "▼"}</span>}
        </SmartDiv>

        <div style={{ display: "flex" }}>
            {ctx.data.DisplayQuickFilter && props.showTools && <input type="text" id={"TestQuickFilter" + props.column.Name} placeholder="⌕" style={{ borderWidth: 1, borderRadius: 5, borderColor: "lightgray", fontSize: 10, borderStyle: "solid", flexGrow: 1 }} value={props.column.FilterText || ""}
                onChange={e => {
                    const term = e.currentTarget.value;
                    const stt = props.column
                    stt.FilterText = term
                    props.stateSetter(stt)
                }} />}
            <div id="TestButtonDiv">
                <VecIcon name="Filter" testName={"_" + props.column.Name + "_Filter"} width={16} onClick={async () => {
                    setShowFilter(!showFilter)
                    await checkOptions()
                }} style={{ visibility: showButton ? "visible" : "hidden" }} />
            </div>
        </div>

        {showFilter && <div id={"TestFilterBox" + props.column.Name} style={{ position: "absolute", display: "flex", flexDirection: "column", backgroundColor: "white", gap: 3, padding: 10, borderRadius: 5, borderWidth: 1, borderStyle: "solid", borderColor: numberColor(ctx.data.SecondaryColor), maxHeight: "50vh" }} >
            <div style={{ display: "flex", justifyContent: "end", cursor: "pointer" }} id="TestCloseFilter" onClick={() => setShowFilter(false)}>✖</div>
            {cd.RelevantAutoFilters.length > 0 && Filter("Auto", "AutoFilter", DataTypes.Enum, undefined, cd.RelevantAutoFilters)}
            {cd.DataType === DataTypes.DateTime && <> {Filter("Range", "DateRange", DataTypes.Enum, undefined, enumList(ctx, DateRanges))} {Filter("From", "MinFilter")} {Filter("Till", "MaxFilter")}  {Filter("StartSpan", "StartSpan", DataTypes.Number, FieldTypes.Span)}{Filter("EndSpan", "EndSpan", DataTypes.Number, FieldTypes.Span)}</>}
            {cd.DataType === DataTypes.Number && <>{Filter("From", "MinFilter")} {Filter("Till", "MaxFilter")} </>}
            {Filter("IncludeEmpties", "IncludeEmpties", DataTypes.Boolean)}
            {Filter("Reverse", "ReverseFilter", DataTypes.Boolean)}
            <div style={{ overflowY: "auto", display: "flex", flexDirection: "column" }}>
                <div>
                    <span>{ctx.localized("Search")}</span><input id="TestFilterText" style={{ padding: 3, borderRadius: 5, borderWidth: 1, borderStyle: "solid", borderColor: numberColor(ctx.data.SecondaryColor) }} onChange={async e => {
                        const term = e.currentTarget.value
                        const stt = props.column
                        stt.FilterText = term
                        props.stateSetter(stt)
                        await checkOptions(true)
                    }} />
                </div>
                <table id="TestSearchOptions" style={{ width: "100%", borderSpacing: 0, overflowY: "auto", display: "flex", flexDirection: "column" }}>
                    <tbody>
                        {searchOptions.map(x =>
                            <tr id="TestSearchOptionRow" key={x.Key} style={{ fontStyle: x.Distance > 0 ? "italic" : "normal", display: "flex" }}>
                                <td style={{ display: "block" }}>
                                    <input id={"TestOption_" + x.Key} style={{ margin: 2 }} type="checkbox" checked={isSelected(x.Key!)} onChange={e => {
                                        const checked = e.currentTarget.checked;
                                        handleCheck(x.Key!, checked);
                                    }} />
                                </td>
                                <td style={{ textAlign: "start", display: "block" }}><label htmlFor={x.Value} style={{ fontWeight: "normal" }} >{x.Value}</label></td>
                                <td style={{ display: "block", textAlign: "end", flexGrow: 1, paddingInlineEnd: 5 }}>
                                    <span style={{ fontWeight: "normal", color: "gray", lineHeight: '10px' }}>{x.Count}</span>
                                </td>
                            </tr>)}
                    </tbody>
                </table>
            </div>
            <div>
                <VecIcon name="EditMode" width={20} title={ctx.localized("EditMode")} onClick={() => {
                    props.editModeToggler()
                    setShowFilter(false)
                }} />
            </div>

        </div>}
    </th>
}
