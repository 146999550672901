//***Copyright Notice***
//____________________________________________________
//Copyright © 2024 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { FC, useContext, useEffect, useRef, useState } from "react"
import { Route, Routes, useParams } from "react-router-dom"
import { ChooserPopup } from "./chooseColumns"
import { ColumnChoice, ColumnData, CommandMessage, GridProps, LiteRecordData, MachshevetClient, RecordsData, SettingGroup } from "./Declarations"
import { EditGrid } from "./EditGrid"
import { adjustColor, defaultGridProps, dePascalCase, getActionUrl, getUrl, RecordFormProps } from "./globals"
import { GlobalSearch } from "./GlobalSearch"
import { ImportFile } from "./ImportFile"
import { Attention, ContextMenu, Dashboard, DialogPlus, EditDialog, Icon, Login, MessageEditor, NavLink, SmartCount, useEffect2, VecIcon } from "./misc"
import { RecordPage } from "./RecordPage"
import { SideNav } from "./sideNav"
import { CommandPopup } from "./CommandPopup"
import { ListView, menuField } from "./ListView"
import { AppContext, AppContextType, ColumnData2, CommandInputProps, doCommand, MainContext } from "./styles"

export const AdminTemplate: FC = () => {
    const ctx = useContext(MainContext)
    const [records, setRecords] = useState<RecordFormProps[]>([])
    const [cmdList, setCmdList] = useState<{ props: GridProps, rowDoubleClick?: (record: LiteRecordData) => void }>()
    const [pageTitle, setPageTitle] = useState('')
    const [pageIcon, setPageIcon] = useState<string>()
    const [cmdResult, setCmdResult] = useState<CommandInputProps>()
    const [chooser, setChooser] = useState<{ choice: ColumnChoice, reloader?: () => void }>()
    const [cmdMessage, setCmdMessage] = useState<CommandMessage>()
    const printframe = useRef<HTMLIFrameElement>(null)
    const [myRoutes, setMyRoutes] = useState<string[]>([])
    const [toast, setToast] = useState<string>()
    const [redirectUrl, setRedirectUrl] = useState<string>()
    const [oldMenuField, setOldMenuField] = useState<menuField>()//to keep nvokers, otherwise doesnt seem to work
    const [menuItems, setMenuItems] = useState<ColumnData[]>()
    const [menuQuickAdds, setMenuQuickAdds] = useState<{ Key: string, Value: string }[]>()
    const [menuPosition, setMenuPosition] = useState<[number, number]>()
    const [menuSetGroup, setMenuSetGroup] = useState<SettingGroup>()
    const [menuSetKey, setMenuSetKey] = useState<string>()
    const [menuRecordID, setMenuRecordID] = useState<number>()
    const [showAlerts, setShowAlerts] = useState(true)
    const [logOutFlag, setLogOutFlag] = useState(true)

    const wasLoaded = useRef(false)

    useEffect(() => {
        document.title = pageTitle
    }, [pageTitle])

    useEffect(() => {
        const globalclick = () => setMenuPosition(undefined)
        document.addEventListener('click', globalclick)
        return () => document.removeEventListener('click', globalclick)
    })

    function closeRec() {
        setRecords(prev => (prev.slice(0, -1)))
    }

    function openPrint(html: string) {
        const pri = printframe.current!.contentWindow!
        pri.document.open()
        pri.document.write(html)
        pri.document.close()
        pri.focus()
        setTimeout(() => pri.print(), 0)
    }

    const [myContext] = useState<AppContextType>({
        openRecord: (rec, sav, pres) => setRecords(prev => [...prev, { Record: rec, onSaved: sav, presetValues: pres, isPopup: true }]),
        closeRecord: closeRec,
        openTable: (gp, rdc) => setCmdList({ props: gp, rowDoubleClick: rdc }),
        closeTable: () => setCmdList(undefined),
        openDialog: c => setCmdResult(c),
        setPageTitle: (t, i) => {
            setPageTitle(t)
            setPageIcon(i)
        },
        openChooser: (c, rld) => setChooser({ choice: c, reloader: rld }),
        openMessage: setCmdMessage,
        printHtml: openPrint,
        showToast: (t) => {
            setToast(t)
            setTimeout(() => setToast(undefined), 5000)
        },
        showContextMenu: (event, invoker, items, field, quickAdds, setgroup, setkey, colSetter, reloader, col,recid) => {
            event.preventDefault()
            event.stopPropagation()
            const mf: menuField = { command: invoker, colSetter: colSetter, reloader: reloader }
            setOldMenuField(mf)
            setMenuItems(items)
            //if (recid) field?.recordID=recordid
            myContext.menuField = field
            myContext.menuColumn = col
            setMenuPosition([event.clientX, event.clientY])
            setMenuQuickAdds(quickAdds)
            setMenuSetGroup(setgroup)
            setMenuSetKey(setkey)
            setMenuRecordID(recid)
        }
    })

    async function fetchData() {
        if (!myRoutes.length) {
            const js = await MachshevetClient.Global.GetNavData()//really we should only fetch list of entitytypes. but we cannot put the timer in myapp, since it makes the whole page rerender again and again
            setMyRoutes(js!.AllLists)
        }
    }

    async function filltrans() {
        const uit = await MachshevetClient.Global.UITexts(ctx.data.Language!)
        ctx.translations = uit
    }

    function localtext(str: string) {
       // if (ctx.data.IsDebug && !str) alert("?")
        if (!ctx.data.UseLocalText) return  dePascalCase(str)
        const dctn = ctx.translations
        if (!dctn) {
            //filltrans()
            return str
        }
        const ret = dctn[str]
        if (!dctn.hasOwnProperty(str)) { //this finds keys with empty values as well, so we don';'t try to send again to the server
            MachshevetClient.Global.Localize(str)
        }
        return ret || str
    };

    ctx.localized = localtext;
    useEffect2(async () => {
        await fetchData()
        await filltrans()
    }, [ctx.data.UserID, logOutFlag])

    function loginRedirect() {
        if (ctx.data.UserID && !ctx.data.MustChangePassword) {
            if (redirectUrl && redirectUrl !== "#/Login/") {
                window.location.hash = redirectUrl;
                setRedirectUrl(undefined);
            }
        }
        else {
            let url = window.location.hash
            setRedirectUrl(url)
            url = getUrl(undefined, "Login")
            window.location.hash = url
        }
    }

    useEffect(() => {
        if (wasLoaded.current) loginRedirect()
        else wasLoaded.current = true
    }, [ctx.data.UserID, ctx.data.CoinSymbol, logOutFlag])

    const alrts = ctx.data.Alerts.filter(report => report.Count > 0)
    const totalalerts = alrts.reduce((sum, current) => sum + current.Count, 0)
    const popcount = ctx.data?.Popups.map(x => x.Records).sum(x => x.length) || 0

    return <AppContext.Provider value={myContext}>
        <SideNav />
        <div id='div7' style={{ overflowX: 'hidden', flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
            {cmdResult && <CommandPopup {...cmdResult} onClose={() => setCmdResult(undefined)} />}
            {records.map(rec => <EditDialog onClose={closeRec} onSaved={rec.onSaved} record={rec.Record} key={rec.Record!.RecordType} presetValues={rec.presetValues} />)}
            {chooser && <ChooserPopup columnChooser={chooser.choice} onCancel={() => setChooser(undefined)} onChanged={chooser.reloader} />}
            {cmdMessage && <MessageEditor {...cmdMessage} onClose={() => setCmdMessage(undefined)} />}
            {cmdList &&
                <DialogPlus title={ctx.localized(cmdList!.props.RecordType!)} onClose={() => setCmdList(undefined)} footer={<div />}>
                    <ListView showTools={true} controller={cmdList!.props.RecordType!} gridProps={cmdList!.props} saveState={false} activeTab={true} onRowDoubleClick={e => {
                        if (cmdList?.rowDoubleClick) {
                            cmdList.rowDoubleClick(e)
                            setCmdList(undefined)
                        }
                    }} />
                </DialogPlus>}
            {menuItems && menuPosition && <ContextMenu onCommand={async c => {
                if (oldMenuField?.command) {
                    oldMenuField!.command!(c)
                } else {
                    const gp = defaultGridProps(c.recordID || myContext.menuField?.recordID || menuRecordID)
                    if (menuSetGroup) gp.SettingGroup = menuSetGroup
                    if (menuSetKey) gp.SettingKey = menuSetKey
                    c.reloader = oldMenuField?.reloader || myContext.menuField?.reloader
                    await doCommand(c, 0, gp, myContext, undefined, oldMenuField?.colSetter)
                }
            }} items={menuItems.map(x => x as ColumnData2)} position={menuPosition} quickAdds={menuQuickAdds} />}

            <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between", padding: 10, gap: 10, boxShadow: "0 0 5px rgba(0, 0, 0, .4)" }} >
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                    <NavLink> <img src={getActionUrl('Global', 'Logo')} style={{ maxHeight: "7vh" }} /></NavLink>
                    <span> {ctx.data.DomainName}</span>
                </div>
                <div style={{ display: 'flex', alignItems: "center", gap: 40 }}>
                    {ctx.data.UserID ?
                        <div style={{ display: "flex", gap: 30, alignItems: "center" }}>
                            {alrts.length > 0 && <details style={{ position: "relative" }}>
                                <summary style={{ cursor: 'pointer', listStyle: "none" }}>
                                    <Icon name='Alert' />
                                    <span style={{ borderRadius: "30%", backgroundColor: "red", padding: 2 }}><SmartCount Number={totalalerts} /></span>
                                </summary>
                                <div style={{ position: 'absolute', padding: 10, background: 'white', boxShadow: "0 0 5px rgba(0, 0, 0, .4)", zIndex: 1 }}>
                                    {alrts.map(x => <NavLink key={x.ID} reportID={x.ID}><div style={{ display: "flex", gap: 3 }}> <span>  {x.Name} </span>   <SmartCount Number={x.Count} /></div></NavLink>)}
                                </div>
                            </details>}
                            <GlobalSearch />
                            <NavLink controller='Person' recordID={ctx.data.UserID}>
                                <div style={{ display: "flex", gap: 5 }}>
                                    <img src={getActionUrl('Person', 'GetDoc', ctx.data.UserID, 'Column=Image')} style={{ maxHeight: 40 }} />
                                    {ctx.data.UserName}
                                </div>
                            </NavLink>
                            <div>
                                <VecIcon name='Logout' width={20} onClick={async () => {
                                    await MachshevetClient.Login.LogOut()
                                    ctx.data.UserID = undefined
                                    setLogOutFlag(!logOutFlag)
                                }} />
                            </div>
                        </div>
                        :
                        <NavLink controller='Login' >{ctx.localized('Login')}</NavLink>
                    }
                </div>
            </div>
            {toast && <div id="TestToast" style={{ backgroundColor: "palegreen", borderRadius: 5, borderColor: "lime", borderStyle: "solid", textAlign: "center", margin: 5, padding: 3 }}>{toast}</div>}
            {(pageTitle || pageIcon) && <div id='div8' style={{ backgroundColor: adjustColor(ctx.data.PrimaryColor!, .5), padding: 5, color: "white", display: "flex", gap: 5, alignItems: "center" }}>
                {pageIcon && <Icon name={pageIcon} />}
                {pageTitle}
            </div>}
            <Routes>
                <Route index element={<Dashboard />} />
                <Route path='Login' element={<Login />} />
                {myRoutes.map(x => {
                    return <Route path={x} key={x} element={<ListView key={x} showTools={true} controller={x} fullPage={true} gridProps={defaultGridProps()} activeTab={true} />} />
                })}
                {myRoutes.map(x => {
                    return <Route path={x + '/Edit'} key={x} >
                        <Route index key={x} element={<EditRecord />} />
                        <Route path=":ID" key={x} element={<EditRecord />} />
                        <Route path=":ID/:Tab" key={x} element={<EditRecord />} />
                    </Route>
                })}
                <Route path="Report/:ID" element={<ReportID />} />
                <Route path="Report/Edit" element={<EditRecord />} />
                {myRoutes.map(x => <Route path={x + '/Import'} key={x} element={<ImportFile key={x} recordType={x} />} />)}
                {myRoutes.map(x => {
                    return <Route path={x + '/Insert'} key={x} element={<EditGrid recordType={x} />} />
                })}
            </Routes>
            <iframe ref={printframe} style={{ width: 1, height: 1, position: "absolute", opacity: 0 }} />
        </div>

        <div style={{ position: 'absolute', bottom: 0, left: ctx.data.IsRtl ? 0 : "inherit", right: ctx.data.IsRtl ? "inherit" : 0, display: 'flex', flexDirection: 'column', zIndex: 1, overflowY: "auto", maxHeight: "40vh" }}>
            {popcount > 0 && <div><VecIcon name={showAlerts ? "Collapse" : "Expand"} width={14} onClick={() => {
                setShowAlerts(!showAlerts)
            }} /></div>}
            {showAlerts && <>
                {ctx.data?.Popups.map(x => {
                    return <div key={x.RecordType} style={{ gap: 10, display: 'flex', flexDirection: 'column' }}> {
                        x.Records.map(y => <Attention record={y} data={x} key={y.RecordID} />)
                    }</div>
                })}
            </>
            }
        </div>
    </AppContext.Provider>
}

const ReportID: FC = () => {
    const params = useParams();
    const rid = params.ID!
    const gp = defaultGridProps()
    const rpid = parseInt(rid);
    gp.ReportID = rpid
    MachshevetClient.Global.MarkReportSeen(rpid)
    return <ListView key={'Report' + rid.toString()} controller='Report' gridProps={gp} fullPage={true} activeTab={true} showTools={true} />
}

const EditRecord: FC = () => {
    const params = useParams();
    const url = params['*']
    const routs = url!.split("/")
    const rout = routs[0];
    const rid = parseInt(params.ID!);
    //const rd = defaultRecordData(rout, rid)

    const rd = new RecordsData
    rd.RecordType = rout
    const lrd = new LiteRecordData()
    lrd.RecordID = rid
    rd.Records = [lrd]
    const tab = params.Tab ? parseInt(params.Tab) : 0
    return <RecordPage key={rout + rid.toString()} record={rd} fullPage={true} activeTab={tab} />
}