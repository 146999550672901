//***Copyright Notice***
//____________________________________________________
//Copyright © 2024 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { useContext, FC, useState, useEffect } from "react"
import { RecordForm } from './RecordAdapter'
import { Icon, MyTab, MyTabs, SmartCount, SmartText, useEffect2, VecIcon } from './misc'
import { DataTypes, MachshevetClient, RecordsData, ReportLite } from './Declarations'
import { adjustColor, defaultGridProps, getControlProps2, getUrl, headerItem, numberColor, redirect } from './globals'
import { ListView } from "./ListView"
import { AppContext, MainContext } from "./styles"

export const RecordPage: FC<{ record: RecordsData, fullPage?: boolean, activeTab?: number, redirectActiveTab?: boolean }> = props => {
    const app = useContext(AppContext)!
    const ctx = useContext(MainContext)
    const [state, setState] = useState(props.record)
    const [activeTab, setActiveTab] = useState(props.activeTab || 0)
    const [reports, setReports] = useState(props.record.Reports)
    const [headers, setHeaders] = useState<headerItem[]>([])

    useEffect(() => {
        if (props.activeTab && props.activeTab !== activeTab) {
            setActiveTab(props.activeTab)
        }
    }, [props.activeTab])
    const recrd = state.Records[0]
    const recid = recrd.RecordID

    useEffect2(async () => {
        if (props.fullPage) {
            app.setPageTitle!(recrd.RecordName!, state.RecordType)
        }
        if (!recrd || !recrd.Fields.length) {
            const rec = await MachshevetClient.PageRecord(state.RecordType!, recid)
            setState(rec)
        }
    }, [])

    useEffect(() => {
        if (props.fullPage && recrd.RecordName) app.setPageTitle!(recrd.RecordName, state.RecordType);
    }, [recrd.RecordName])

    const dummy = new ReportLite()
    dummy.ID = -1
    dummy.Name = ctx.localized('General')
    dummy.LocalName = ctx.localized('General')
    let reps = [dummy]
    reps = reps.concat(reports)

    function getGridProps(reportID: number, parentRecordID?: number) {
        const gp = defaultGridProps()
        gp.ReportID = reportID
        gp.ParentRecordID = parentRecordID
        return gp
    }

    let flds = recrd.Fields.length && state.Columns.length ? getControlProps2(recrd.Fields, state.Columns) : undefined//odd! how come are there ever fields without columns?
    flds = flds?.filter(x => x.memberData)//for some odd reason after adding a field, it takes a few seconds till it is also in fields and also in columns
    //const dumy = flds?.filter(x => !x.memberData)
    //if (dumy?.length) {
    //    var a=1
    //}

    return <div style={{ padding: "2vh", overflowY: 'auto', height: "100%", display: 'flex', flexDirection: 'column', gap: "2vh" }} id="div10">
        <div style={{ display: "flex", gap: 10 }}>{flds && flds.filter(x => x.memberData.ForHeader).sortBy(x => x.memberData.SelectedPosition).map(x => {
            const colr = x.memberData.ForeColor || ctx.data.PrimaryColor
            return <div key={x.Name} style={{ display: "flex", flexGrow: 1, borderRadius: 5, borderWidth: 1, borderStyle: "solid", borderColor: numberColor(colr), gap: 10, padding: "1vh" }}>
                <VecIcon name={x.memberData.Icon!} color={numberColor(colr)} />
                <div style={{ fontSize: "140%", color: numberColor(colr), fontWeight: "bold" }}>
                    {x.memberData.DataType == DataTypes.Number ? <SmartCount Number={x.Value} fieldType={x.memberData.FieldType} /> : <SmartText Text={x.Value} />}
                    <div style={{ fontSize: "50%" }}>{x.memberData.LocalName}</div>
                </div>
            </div>
        })}</div>
        <MyTabs selectedTabChanged={setActiveTab} activeTab={activeTab}>
            {reps.map((x, i) => {

                const hdr = headers.find(y => y.id === x.ID)

                return <MyTab key={x.ID} style={{ backgroundColor: numberColor(x.BackColor) }} reportID={x.ID} testName={"ReportTab_" + x.ID} titleElement={

                    <div style={{ display: 'flex', gap: "1vh" }}>
                        <div>
                            <Icon name={x.RecordType || 'General'} />&nbsp;
                            <span style={{ fontWeight: 'bold' }}>{x.LocalName!}</span>
                        </div>
                        {hdr?.aggregation && <div style={{ backgroundColor: adjustColor(ctx.data.SecondaryColor!, .5), borderRadius: ".6vh", fontSize: "80%", padding: ".5vh" }}> <SmartCount Number={hdr.aggregation} fieldType={hdr.column?.FieldType} />  </div>}
                        {hdr?.count && <SmartCount Number={hdr.count} />}
                    </div>




                }
                    onClick={async () => {
                        if (x.ID > 0) await MachshevetClient.Global.MarkReportSeen(x.ID)
                        if (props.redirectActiveTab !== false) {
                            const url = getUrl(undefined, props.record.RecordType, recid, undefined, i)
                            window.location.hash = url
                        }
                    }} >
                    <div style={{ padding: "1vh", overflowY: "auto", display: "flex", flexGrow: 1 }}>
                        {x.ID === -1 ?
                            <RecordForm Record={state} onRefreshed={e => {
                                setState(prev => ({ ...prev, Records: e.Records }))
                                if (reports.length != e.Reports.length) setReports(e.Reports)
                            }} onSaved={(id, oldid) => {
                                app.showToast!(ctx.localized('Saved'))
                                if (!oldid) redirect(undefined, state.RecordType, id);
                            }} />
                            :
                            <ListView controller='Report' showTools={true} gridProps={getGridProps(x.ID, recid)} parentRecordID={recid} onResultsFetched={hdr => {
                                setHeaders(prev => {
                                    const rp = prev.find(y => y.id === x.ID)
                                    if (!rp) prev.push({ id: x.ID, column: hdr.column })
                                    //rp?.count = c
                                    return prev.map(y => y.id === x.ID ? { ...y, count: hdr.count, aggregation: hdr.aggregation } : y)
                                })
                            }} activeTab={i === activeTab} />
                        }
                    </div>
                </MyTab>
            })
            }
        </MyTabs>
    </div>
}