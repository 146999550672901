//***Copyright Notice***
//____________________________________________________
//Copyright © 2024 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { FC, useContext } from "react"
import { getControlProps2, GetDayName, GetMonthName, getWeekNumber, isSameDay, monthFirstDay, numberColor, range, rightCut, weekFirstDay } from "./globals"
import { NavLink, SmartButton, SmartDiv } from "./misc"
import { TableBodyProps } from "./ListView"
import { AppContext, MainContext } from "./styles"
import { RecordsData } from "./Declarations"

export const Calendar: FC<TableBodyProps> = props => {
    const app = useContext(AppContext)!
    const ctx = useContext(MainContext)
    const StartTime = 8;
    const EndTime = 19;
    const fd = props.gridProps?.CalendarFromDate || new Date()
    const gp = props.gridProps
    const records = props.records

    function setProps(newDate?: Date, weekView?: boolean) {
        const newstt = props.gridProps!
        if (newDate) newstt.CalendarFromDate = newDate
        if (weekView !== undefined) newstt.CalendarWeekView = weekView
        props.onPropsChanged!(newstt)
    }

    const next = () => {
        if (gp.CalendarWeekView)
            setProps(new Date(fd.getFullYear(), fd.getMonth(), fd.getDate() + 7));
        else {
            const dt = new Date(fd.getFullYear(), fd.getMonth() + 1, fd.getDate());
            setProps(dt)
        }
    }
    const weekview = gp.CalendarWeekView

    return <div id="divcal1" style={{ width: "100%", overflowY: "auto" }}>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <div>
                <button tabIndex={-1} onClick={() => {
                    if (gp.CalendarWeekView)
                        setProps(new Date(fd.getFullYear(), fd.getMonth(), fd.getDate() - 7));
                    else
                        setProps(new Date(fd.getFullYear(), fd.getMonth() - 1, fd.getDate()));
                }} >{ctx.data.IsRtl ? ">" : "<"}</button>
                <select value={weekview ? getWeekNumber(fd) : fd.getMonth()} onChange={weekview ? e => setProps(new Date(fd.getFullYear(), 1, +e.currentTarget.value * 7)) : e => setProps(new Date(fd.getFullYear(), +e.currentTarget.value, 1))}>
                    {weekview ?
                        range(0, 52).map(week => {
                            const sunday = new Date(fd.getFullYear(), 0, week * 7);
                            const saturday = new Date(sunday.getFullYear(), sunday.getMonth(), sunday.getDate() + 6);
                            return <option value={week} key={week}>
                                {week + 1} | {sunday.getDate()}/{sunday.getMonth() + 1}-{saturday.getDate()}/{saturday.getMonth() + 1}
                            </option>
                        }) :
                        range(0, 11).map(x => {
                            const txt = (x + 1) + '|' + GetMonthName(ctx, x)
                            return <option key={x} value={x}>{txt}</option>
                        })
                    }
                </select>
                <select
                    style={{ width: 'initial' }}
                    onChange={e => setProps(new Date(+e.currentTarget.value, fd.getMonth(), fd.getDate()))}
                    value={fd.getFullYear()}
                >
                    {range(fd.getFullYear() - 10, fd.getFullYear() + 10)
                        .map(index => {
                            return <option value={index} key={index}>
                                {index}
                            </option>
                        })}
                </select>
                <button tabIndex={-1} onClick={next} >{ctx.data.IsRtl ? "<" : ">"}</button>
            </div>

            <SmartButton onClick={() => setProps(gp?.CalendarWeekView ? weekFirstDay(new Date()) : new Date())} >{ctx.localized("Today")}</SmartButton>
            <div onClick={() => {
                const wv = !props.gridProps!.CalendarWeekView
                const newdt = wv ? weekFirstDay(new Date()) : monthFirstDay(new Date())
                setProps(newdt, wv)
            }}>
                <div>{ctx.localized("Weekly")}</div>
                <div>{ctx.localized("Monthly")}</div>
            </div>
        </div>
        <table cellSpacing="0" style={{ width: "100%", height: "100%" }}>
            {weekview ?
                <>
                    <thead>
                        <tr>
                            <th style={{ width: '2em' }}></th>
                            {range(0, 6).map(index => {
                                const day = new Date(fd.getFullYear(), fd.getMonth(), fd.getDate() + index);
                                return <th key={index}>
                                    <div>
                                        <div>{day.toDateString()}</div>
                                    </div>
                                </th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        <>
                            {range(StartTime, EndTime).map(hour => {
                                const hourRecs = records!.Records.filter(x => {
                                    const fd = x.FromDate!
                                    const hr = new Date(fd).getHours();
                                    return hr >= hour && hr < (hour + 1)
                                })
                                if (hour === StartTime) {
                                    hourRecs.push(...records!.Records.filter(rec => {
                                        const fd = rec.FromDate!
                                        return (new Date(fd).getHours() < hour)
                                    }))// record befor start time go to begining
                                }
                                if (hour === EndTime) {
                                    hourRecs.push(...records!.Records.filter(rec => {
                                        const fd = rec.FromDate!
                                        return (new Date(fd).getHours() > hour)
                                    }))// record befor start time go to begining
                                }
                                return <tr style={{ verticalAlign: "top" }} key={hour}>
                                    <td style={{ textAlign: 'center', fontWeight: 700 }} >{hour}</td>
                                    {range(0, 6).map(i => {
                                        const day = new Date(fd.getFullYear(), fd.getMonth(), fd.getDate() + i);
                                        const recs = hourRecs?.filter(x => {
                                            const fd = x.Fields.filter(y => y.Name === "FromDate")[0].Value
                                            return isSameDay(new Date(fd), day)
                                        })
                                        recs?.sortBy(x => x.Fields.filter(y => y.Name === "FromDate")[0].Value)
                                        const istoday = isSameDay(day, new Date())
                                        return <td style={{ borderColor: numberColor(ctx.data.PrimaryColor), borderWidth: istoday ? 2 : 1 }} key={i}>
                                            {recs.map(Hrec => {
                                                return <div key={Hrec.RecordID}>
                                                    <NavLink controller={props.listType} recordID={Hrec.RecordID}>
                                                        {Hrec.Fields.map(y => y.DisplayString).join("/")}
                                                    </NavLink>
                                                </div>
                                            })}
                                        </td>
                                    })
                                    }
                                </tr>
                            })}
                        </>
                    </tbody>
                </>
                :
                <>
                    <thead>
                        <tr>
                            {range(0, 6).map(x => <th key={x}>{GetDayName(ctx, x)}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {records && range(0, 5).map(week => {
                            return <tr key={week}>
                                {range(0, 6).map(i => {
                                    const firstDayOfMonth = new Date(fd.getFullYear(), fd.getMonth(), 1);
                                    const day = new Date(fd.getFullYear(), fd.getMonth(), 1 - firstDayOfMonth.getDay() + week * 7 + i);
                                    let recs = records?.Records.filter(x => {
                                        const fd = x.FromDate!
                                        const recday = new Date(fd)
                                        const isd = isSameDay(recday, day)
                                        return isd
                                    });
                                    recs = recs?.sortBy(x => x.FromDate)
                                    //const istoday = isSameDay(day, new Date())


                                    if (day.getMonth() === fd.getMonth()) {
                                        // boxShadow: istoday ? "0 0 6px var(--primary-light)" : "" }
                                        return <td key={i} style={{ borderColor: numberColor(ctx.data.SecondaryColor), borderWidth: .5, borderStyle: "solid", verticalAlign: "top" }} onDoubleClick={() => {
                                            const rd = new RecordsData()
                                            rd.RecordType = props.listType
                                            app.openRecord!(rd, undefined, { FromDate: rightCut(day.toISOString(), "Z") })
                                        }}>
                                            <span style={{ fontSize: 18, color: numberColor(ctx.data.SecondaryColor) }}>{day.getDate()}</span>
                                            {recs && recs.map(x => {
                                                const fd = x.FromDate!
                                                const flds = getControlProps2(x.Fields, records.Columns)
                                                const visfields = flds.filter(x => x.memberData!.Visible)
                                                return <SmartDiv id="Test_Record" key={x.RecordID} data-recordid={x.RecordID} style={{ backgroundColor: numberColor(x.BackColor) }} onClick={() => props.setSelected!([x], true, false)} onContextMenu={e => {
                                                    props.setSelected!([x], true, false)
                                                    props.setMenuField!(e)
                                                }}>
                                                    <div style={{ fontWeight: "bold" }}>{new Date(fd).toLocaleTimeString()}</div>
                                                    <NavLink controller={x.RecordType} recordID={x.RecordID}>{visfields.map(y => y.DisplayString).filter(x => x !== undefined && x !== null).join(" / ")}</NavLink>
                                                </SmartDiv>
                                            }
                                            )}
                                        </td>
                                    } else return <td />
                                })}
                            </tr>
                        })}
                    </tbody>
                </>
            }
        </table>
    </div>

}

