//***Copyright Notice***
//____________________________________________________
//Copyright © 2024 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

//import React from "react";

export function queryVals(obj: any, inclueEmpties = false): string {
    let query: string | undefined
    for (let k in obj) {
        let value = obj[k]
        if (value || inclueEmpties) {
            if (!value) value = ''
            const argument = `${encodeURIComponent(k)}=${encodeURIComponent(value)}`
            if (query) query = query + '&' + argument
            else query = argument
        }
    }
    return query || ''
}

export async function fetchPlus(input: RequestInfo, init?: RequestInit) {
    const rsp = await fetch(input, init)
    if (rsp.status != 200) {
        let msg = await rsp.text()
        if (msg.startsWith("<")) {
            const parser = new DOMParser()
            const doc = parser.parseFromString(msg, "text/xml")
            msg = doc.getElementsByTagName('title')[0].innerHTML
        }
        throw new Error(msg)
    }
    return rsp
}

export function devLog(log: string, ...optionalParams: any[]) {
    //if (getServerData().InDebug) console.log(log, optionalParams)
    console.log(log, optionalParams)//cant check debugmode in shared.ts
    //console.log(log, optionalParams)
}

export async function fetchJson<T>(input: RequestInfo, init?: RequestInit) {
    const rsp = await fetchPlus(input, init)
    const js = rsp.json();
    return js as Promise<T>;
}
