//***Copyright Notice***
//____________________________________________________
//Copyright © 2024 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { useState, FC, CSSProperties, useRef } from 'react'
import { useEffect2, useInterval } from './misc'
import { Routes, Route, HashRouter } from 'react-router-dom'
import { AdminTemplate } from './AdminTemplate'
import { AppData, MachshevetClient } from './Declarations'
import { MainAppType, numberColor } from './globals'
import { Portal } from './portal'
import { MainContext } from './styles'


//export const MyApp3: FC = () => {
//    const [state, setState] = useState("abcdefg")
//    return <div>{state}</div>
//}

export const MyApp: FC = () => {
    const [state, setState] = useState<MainAppType>({ data: new AppData(), localized: s => s, setUser: setuser, docActive: documentActive })
    const actionTime = useRef<number>(0)
    const lastPing = useRef<number>(Date.now() - 100000)

    useEffect2(async () => {
        await getData()

        document.addEventListener('mousemove', async () => {
            actionTime.current = Date.now()
            if (Date.now() - lastPing.current > 100 * 1000) {
                lastPing.current = Date.now()
                await MachshevetClient.Login.KeepSessionLive()
            }
        })
    }, [])

    useInterval(() => getData(), 1500)

    function setuser(userid?: number) {
        setState(prev => ({ ...prev, data: { ...prev.data, UserID: userid } }))
    }

    async function getData() {
        const js = await MachshevetClient.Global.GetAppData()
        const oldjs = JSON.stringify(state.data)
        const eq = JSON.stringify(js) === oldjs
        if (!eq)
            setState(prev => ({ ...prev, data: js }))
    }


    function documentActive() {
        const intvl = 3 * 60 * 1000
        const hdn = document.hidden
        const ret = !hdn && (Date.now() - actionTime.current) < intvl
        return ret
    }

    const mainstl: CSSProperties = { display: 'flex', direction: state.data.IsRtl ? "rtl" : "ltr", fontSize: "calc(.4vmax + 0.4vw)", color: numberColor(state.data.PrimaryColor), fontFamily: "Segoe UI", flexGrow: 1, overflowY: 'auto' }
    if (state.data.IsRemote) {
        mainstl.borderColor = "red"
        mainstl.borderWidth = 5
        mainstl.borderStyle = "solid"
    }

    return <MainContext.Provider value={state}>
        <HashRouter>
            <div style={mainstl}>
                <Routes>
                    <Route path={"/*"} element={<AdminTemplate />} />
                    <Route path={'Portal'} element={<Portal />} />
                </Routes>
            </div>
        </HashRouter>
    </MainContext.Provider>
}