import { performAction, performActionT, invokeFunction } from "./globals";
export enum Aggregations {
    Count,
    Sum,
    Average,
    Min,
    Max,
}

export enum AlertLevels {
    Error,
    Warning,
    NoDelete,
    NoChange,
}

export class AppData{
  UseLocalText: boolean = false;
  Language?: string = undefined;
  IsRtl: boolean = false;
  IsRemote: boolean = false;
  MinDecimals: number = 0;
  CommandsInSameTab: boolean = false;
  PickerTimeFormat?: string = undefined;
  PickerDateFormat?: string = undefined;
  DisplayQuickFilter: boolean = true;
  UserID?: number = undefined;
  ResultOptions?: string = undefined;
  IsSysAdmin: boolean = false;
  IsSandbox: boolean = true;
  UserName?: string = undefined;
  MustChangePassword: boolean = false;
  PrimaryColor?: number = undefined;
  SecondaryColor?: number = undefined;
  SelectedDomainID?: number = undefined;
  DomainName?: string = undefined;
  IsDebug: boolean = true;
  CoinSymbol?: string = undefined;
  NodePrefix?: string = undefined;
  OldGrid: boolean = false;
  Progresses: MiniExecution[] = [];
  Alerts: ReportLite[] = [];
  HasMailBox: boolean = true;
  Popups: RecordsData[] = [];
}

export interface Attachment{
  Title?: string;
  Inline: boolean;
  RecordID?: number;
  ContentType?: string;
  Extension?: string;
  Bytes: string;
  Data?: string;
  Text?: string;
  Name?: string;
}

export enum AttachmentTypes {
    Pdf,
    Html,
    Csv,
    Xlsx,
}

export enum AutoFilters {
    Empties,
    Fulls,
    Mine,
    Yes,
    No,
    NotZero,
    ThisWeekday,
    NotRelated,
    Duplicates,
    Positive,
    Negative,
}

export class ClipboardData{
  Text?: string = undefined;
  Html?: string = undefined;
}

export interface ColumnChoice{
  Group: SettingGroup;
  RecordType?: string;
  ReportID?: number;
  Key?: string;
  Columns: ColumnData[];
  ForAll: boolean;
  ReportMode: boolean;
}

export class ColumnData{
  LocalName?: string = undefined;
  IsFilter: boolean = false;
  SelectName?: string = undefined;
  SubReportID?: number = undefined;
  BarFilterOptions: FieldCountData[] = [];
  PickList: PickListItem[] = [];
  BarFilterValues: Set<string> = new Set([]);
  ColumnKey?: string = undefined;
  SelectedPosition: number = -1;
  RelevantAutoFilters: {Key: AutoFilters, Value: string}[] = [];
  PossibleValues: {Key: number, Value: string}[] = [];
  IDFilter?: string = undefined;
  FilterList?: string = undefined;
  FilterText?: string = undefined;
  MinFilter?: string = undefined;
  MaxFilter?: string = undefined;
  IncludeEmpties: boolean = false;
  ReverseFilter: boolean = false;
  DateRange?: DateRanges = undefined;
  AutoFilter?: AutoFilters = undefined;
  SelectExpression?: string = undefined;
  StartSpan?: number = undefined;
  EndSpan?: number = undefined;
  ReportFieldID?: number = undefined;
  ReportID?: number = undefined;
  Title?: string = undefined;
  FieldName?: string = undefined;
  ID?: number = undefined;
  AdjustInterval?: Intervals = undefined;
  Aggregation?: Aggregations = undefined;
  DashboardAggregation?: Aggregations = undefined;
  Type: ReportTypes = 0;
  MainRecordType?: string = undefined;
  Active: boolean = false;
  ForPrint: boolean = false;
  Command?: string = undefined;
  RecipientField?: string = undefined;
  AggregationCount: number = 0;
  Name?: string = undefined;
  DataType?: DataTypes = undefined;
  FieldType?: FieldTypes = undefined;
  IDFieldName?: string = undefined;
  ForeignTypeName?: string = undefined;
  Warning?: string = undefined;
  Group?: string = undefined;
  PickReportID?: number = undefined;
  FontSize?: number = undefined;
  FontWeight?: number = undefined;
  Uses: number = 0;
  IsNew: boolean = false;
  ShowTime?: boolean = undefined;
  Visible?: boolean = undefined;
  SpecificCommand: boolean = false;
  Required: boolean = false;
  IsCommand: boolean = false;
  Editable: boolean = true;
  Multiline: boolean = false;
  LetZero?: boolean = undefined;
  MaxLength?: number = undefined;
  Internal: boolean = false;
  KeepOpen: boolean = true;
  NoChange: boolean = false;
  ShowAll: boolean = false;
  AutoComplete?: boolean = undefined;
  WordWrap?: boolean = undefined;
  FilterBar?: boolean = undefined;
  NeedsField: boolean = false;
  NeedsReport: boolean = false;
  NeedsList: boolean = false;
  NeedsRecord: boolean = true;
  CausesUnconfirm: boolean = false;
  Locked: boolean = false;
  Licensed: boolean = true;
  SpecialPicker: boolean = false;
  MergePicker: boolean = false;
  ForeColor?: number = undefined;
  BackColor?: number = undefined;
  DefaultPosition: number = 200;
  Tooltip?: string = undefined;
  Icon?: string = undefined;
  Key?: string = undefined;
  ListType?: string = undefined;
  NavigationField?: string = undefined;
  RecordType?: string = undefined;
  MeasureUnit?: Units = undefined;
  DatePrecision?: Intervals = undefined;
  SortOrder?: number = undefined;
  SortDescending?: boolean = undefined;
  ForHeader?: boolean = undefined;
  HasPickList: boolean = false;
  PrefillOptions?: boolean = undefined;
  EditMode: boolean = false;
  ForAppList: boolean = false;
  DefaultType?: DefaultTypes = undefined;
  DefaultID?: number = undefined;
  AskIfEmpty?: boolean = undefined;
  ForGlobalSearch?: boolean = undefined;
  SectionID?: number = undefined;
  ForSimilars?: boolean = undefined;
  DefaultValue?: any;
  CoinField?: string = undefined;
  ForPortalEdit?: boolean = undefined;
  FormatPicker: boolean = false;
  InitialValue?: any;
}

export class CommandCall{
  Number: number = 0;
  PersonID?: number = undefined;
}

export interface CommandList{
  RecordType?: string;
  Filters: ColumnData[];
}

export interface CommandMessage{
  Subject?: string;
  Body?: string;
  Recipients: {Key: string, Value: string}[];
  BlindCopies: {Key: string, Value: string}[];
  FaxNumber?: number;
  Attachments: Attachment[];
  MailboxID?: number;
  ScreenName?: string;
  ReplyAddress?: string;
  UserName?: string;
  Password?: string;
  LinkedIDs: { [index:string]: number };
  ReportID?: number;
  GetCopy: boolean;
  PersonID?: number;
  SendSplit: boolean;
  OriginalMessageID?: number;
}

export interface CommandResult{
  ObjectType?: string;
  Object?: any;
  Record: RecordsData;
  Columns: ColumnData[];
  Fields: ControlProps[];
}

export interface ContactForm{
  Name?: string;
  Email?: string;
  Phone?: string;
  Message?: string;
}

export interface ControlProps{
  DebugInfo?: string;
  Name?: string;
  Value?: any;
  ForeignID?: number;
  DisplayString?: string;
  ErrorText?: string;
  ErrorRecordID?: number;
  Useful: boolean;
  FileType?: string;
  FileText?: string;
  FileHtml?: string;
  Pages?: number;
  CoinSymbol?: string;
  ReportFieldID?: number;
  WarningText?: string;
  ColumnKey?: string;
}

export interface DashboardData{
  Entities: DashboardTile[];
  Recents: DashboardTile[];
}

export interface DashboardTile{
  RecordType?: string;
  ReportID?: number;
  RecordID?: number;
  DisplayName?: string;
  Count?: number;
  AggregationFieldType?: FieldTypes;
  SortOrder?: number;
  ShowCount: boolean;
  LastCount?: Date;
  RecountSpan?: any;
  IsLicensed: boolean;
  ForeColor?: number;
  BackColor?: number;
  Key?: string;
}

export enum DataTypes {
    Text,
    Number,
    Boolean,
    DateTime,
    Enum,
    Bytes,
    TimeSpan,
    List,
}

export enum DateRanges {
    Today,
    ThisWeek,
    ThisMonth,
    ThisYear,
    Past,
    Future,
    PastWeek,
    PastMonth,
    PastYear,
    LastMonth,
    Yesterday,
    LastYear,
    NextMonth,
    ThisSchoolYear,
    PastHour,
    Tomorrow,
}

export enum DefaultTypes {
    Now,
    Today,
    Mine,
    Yes,
    ThisSchoolYearStart,
    NextSchoolYearStart,
}

export interface FieldCountData{
  Key?: string;
  Value?: string;
  Distance: number;
  Count: number;
  SortValue?: any;
}

export enum FieldTypes {
    Bitmap,
    Clock,
    Color,
    Css,
    Csv,
    DrawPath,
    Email,
    Gender,
    Html,
    IsraelNationalCode,
    Measure,
    Money,
    Percent,
    Phone,
    Span,
    Weekdays,
    Track,
    Json,
    Url,
    Rtf,
    Xaml,
    FolderPath,
    Language,
    Xml,
    Audio,
    CardExpiry,
    Weekday,
    Font,
    Pdf,
    DateFormat,
    Docx,
    Svg,
    TimeFormat,
    CardNumber,
    SocialSecurity,
}

export interface FilterDescription{
  Group: number;
  Descriptions: {Key: string, Value: string}[];
}

export interface fncEntitySetting{
  RecordType?: string;
  PasswordDelete: boolean;
  ForTopMenu: boolean;
  SectionID?: number;
  Color?: number;
  SectionName?: string;
  ForOffline: boolean;
  Title?: string;
  ListTitle?: string;
  OfflineKeepSpan?: number;
  OfflineSyncFrequency?: number;
  ImportSkipRows: number;
  ImportDateFormat?: string;
  ForBackup?: boolean;
  LocalRecordType?: string;
  ImportCodePage?: number;
  FolderPath?: string;
  ImportSkipDataRows: number;
  IsLicensed: boolean;
  DisplayName?: string;
  ID: number;
  LastNotifiedOn?: Date;
  LastPrintedOn?: Date;
  LastConfirmedBy?: number;
  NameString?: string;
  AddedOn: Date;
  AddedBy?: number;
  Comment?: string;
  LastEditedOn?: Date;
  LastSeenOn?: Date;
  LastConfirmedOn?: Date;
  InUserInterface: boolean;
  IsInEditMode: boolean;
}

export class GridProps{
  Term?: string = undefined;
  ReportID?: number = undefined;
  ParentRecordID?: number = undefined;
  SettingGroup: SettingGroup = 0;
  Member?: string = undefined;
  InputParams: {Key: string, Value: any}[] = [];
  Fields: ColumnData[] = [];
  RecordKeys: Set<string> = new Set([]);
  Page: number = 1;
  SettingKey?: string = undefined;
  FilterReportID?: number = undefined;
  CalendarWeekView: boolean = false;
  CalendarFromDate?: Date = undefined;
  DisplayType?: ReportTypes = undefined;
  Command?: string = undefined;
  Filters: ColumnData[] = [];
  RecordType?: string = undefined;
  ParentRecordType?: string = undefined;
}

export interface ImportSetting{
  ImportColumns: MiniImportColumn[];
  EntityColumns: {Key: string, Value: string}[];
  Sheets: string[];
  Prompts: ControlProps[];
  PromptColumns: ColumnData[];
}

export enum Intervals {
    Millisecond,
    Second,
    Minute,
    Hour,
    Day,
    Week,
    Month,
    Year,
}

export interface KeyData{
  ForeignField?: string;
  ForeignBase?: string;
  IDFieldName?: string;
}

export interface LiteCallBase{
  Code?: string;
  PersonID?: number;
  Status?: string;
  StartDate: Date;
  EndDate?: Date;
  Transcript?: string;
  Incoming: boolean;
  FromPhone?: number;
  ToPhone?: number;
  PersonName?: string;
  Duration?: number;
  ViaSipClient: boolean;
  SipAddress?: string;
  ScreenName?: string;
  ScreenNumber?: string;
  Message: string;
  MessageSize?: number;
  ServerData?: string;
  WorkerID?: number;
  FromExtension?: number;
  ToExtension?: number;
  WorkerName?: string;
  Channel?: string;
  ReachedPhone?: number;
  RawData?: string;
  Provider?: string;
  MainCode?: number;
  RecordingsUrl?: string;
  RecordingChecked?: Date;
  AnsweredOn?: Date;
  TranscribedOn?: Date;
  ID: number;
  LastNotifiedOn?: Date;
  LastPrintedOn?: Date;
  LastConfirmedBy?: number;
  NameString?: string;
  AddedOn: Date;
  AddedBy?: number;
  Comment?: string;
  LastEditedOn?: Date;
  LastSeenOn?: Date;
  LastConfirmedOn?: Date;
  InUserInterface: boolean;
  IsInEditMode: boolean;
}

export interface LiteMessageBase{
  Subject?: string;
  Folder?: string;
  MailboxID: number;
  Code?: number;
  Body?: string;
  BodyText?: string;
  UserName?: string;
  ScreenName?: string;
  FailedRecipient?: string;
  FailedReason?: string;
  SenderID?: number;
  ReplyEmail?: string;
  SentDate: Date;
  SenderName?: string;
  Reference?: string;
  MailboxName?: string;
  Response?: string;
  WorkerID?: number;
  WorkerName?: string;
  SendWorkerID?: number;
  SendWorkerName?: string;
  Incoming?: boolean;
  ReplyPersonID?: number;
  ReplyPersonName?: string;
  ReportID?: number;
  ReportName?: string;
  Flags?: string;
  LastSync?: Date;
  ApiCode?: string;
  Host?: string;
  InitialCode?: string;
  DomainID?: number;
  DomainName?: string;
  ID: number;
  LastNotifiedOn?: Date;
  LastPrintedOn?: Date;
  LastConfirmedBy?: number;
  NameString?: string;
  AddedOn: Date;
  AddedBy?: number;
  Comment?: string;
  LastEditedOn?: Date;
  LastSeenOn?: Date;
  LastConfirmedOn?: Date;
  InUserInterface: boolean;
  IsInEditMode: boolean;
}

export class LiteRecordData{
  RecordID: number = 0;
  RecordKey?: string = undefined;
  RecordType?: string = undefined;
  RecordName?: string = undefined;
  BackColor?: number = undefined;
  ForeColor?: number = undefined;
  PersonID?: number = undefined;
  AddedOn?: Date = undefined;
  FromDate?: Date = undefined;
  Incoming: boolean = false;
  IsDeleted: boolean = false;
  Index: number = 0;
  TempBulkField?: string = undefined;
  TempBulkIDs?: string = undefined;
  TempParentRecordType?: string = undefined;
  ChildKeyField?: string = undefined;
  Fields: ControlProps[] = [];
}

export interface LoginModel{
  Email?: string;
  Password?: string;
  LastName?: string;
  FirstName?: string;
}

export interface LoginResult{
  PersonID?: number;
  Error?: string;
  Validation?: string;
  Language?: string;
  MustReset: boolean;
}

export enum LogModes {
    Insert,
    Change,
    Delete,
    None,
}

export enum MeasureTypes {
    Capacity,
    Length,
    Volume,
    Temperature,
    Weight,
    Area,
}

export class MemberData{
  Name?: string = undefined;
  DataType?: DataTypes = undefined;
  FieldType?: FieldTypes = undefined;
  IDFieldName?: string = undefined;
  ForeignTypeName?: string = undefined;
  Warning?: string = undefined;
  Group?: string = undefined;
  PickReportID?: number = undefined;
  FontSize?: number = undefined;
  FontWeight?: number = undefined;
  Uses: number = 0;
  IsNew: boolean = false;
  ShowTime?: boolean = undefined;
  Visible?: boolean = undefined;
  SpecificCommand: boolean = false;
  Required: boolean = false;
  IsCommand: boolean = false;
  Editable: boolean = true;
  Multiline: boolean = false;
  LetZero?: boolean = undefined;
  MaxLength?: number = undefined;
  Internal: boolean = false;
  KeepOpen: boolean = true;
  NoChange: boolean = false;
  ShowAll: boolean = false;
  AutoComplete?: boolean = undefined;
  WordWrap?: boolean = undefined;
  FilterBar?: boolean = undefined;
  NeedsField: boolean = false;
  NeedsReport: boolean = false;
  NeedsList: boolean = false;
  NeedsRecord: boolean = true;
  CausesUnconfirm: boolean = false;
  Locked: boolean = false;
  Licensed: boolean = true;
  SpecialPicker: boolean = false;
  MergePicker: boolean = false;
  ForeColor?: number = undefined;
  BackColor?: number = undefined;
  DefaultPosition: number = 200;
  Tooltip?: string = undefined;
  Icon?: string = undefined;
  Key?: string = undefined;
  ListType?: string = undefined;
  NavigationField?: string = undefined;
  RecordType?: string = undefined;
  MeasureUnit?: Units = undefined;
  DatePrecision?: Intervals = undefined;
  SortOrder?: number = undefined;
  SortDescending?: boolean = undefined;
  ForHeader?: boolean = undefined;
  HasPickList: boolean = false;
  PrefillOptions?: boolean = undefined;
  EditMode: boolean = false;
  ForAppList: boolean = false;
  DefaultType?: DefaultTypes = undefined;
  DefaultID?: number = undefined;
  AskIfEmpty?: boolean = undefined;
  ForGlobalSearch?: boolean = undefined;
  SectionID?: number = undefined;
  ForSimilars?: boolean = undefined;
  DefaultValue?: any;
  CoinField?: string = undefined;
  ForPortalEdit?: boolean = undefined;
  FormatPicker: boolean = false;
  InitialValue?: any;
}

export interface MiniExecution{
  ID: number;
  LocalRecordType?: string;
  LocalCommand?: string;
  Progress: number;
}

export interface MiniImportColumn{
  ID: number;
  Name?: string;
  FieldName?: string;
  SubRecordType?: string;
  SubFieldName?: string;
  IsKey: boolean;
  Row?: number;
  LocalSubRecordType?: string;
  Samples: string[];
}

export interface MiniRecordData{
  RecordType?: string;
  RecordID?: number;
  BackColor?: number;
  ForeColor?: number;
  RecordName?: string;
  RecordKey?: string;
  IsDeleted: boolean;
  FromDate?: Date;
  AddedOn?: Date;
  Incoming: boolean;
  PersonID?: number;
  TempBulkField?: string;
  TempBulkIDs?: string;
  QuickAdds: {Key: string, Value: string}[];
  SimpleFields: { [index:string]: any };
  Index: number;
  TempParentRecordType?: string;
  ChildKeyField?: string;
}

export class MiniReportField{
  IDFilter?: string = undefined;
  FilterList?: string = undefined;
  FilterText?: string = undefined;
  MinFilter?: string = undefined;
  MaxFilter?: string = undefined;
  IncludeEmpties: boolean = false;
  ReverseFilter: boolean = false;
  DateRange?: DateRanges = undefined;
  AutoFilter?: AutoFilters = undefined;
  SelectExpression?: string = undefined;
  StartSpan?: number = undefined;
  EndSpan?: number = undefined;
  ReportFieldID?: number = undefined;
  ReportID?: number = undefined;
  Title?: string = undefined;
  FieldName?: string = undefined;
  ID?: number = undefined;
  AdjustInterval?: Intervals = undefined;
  Aggregation?: Aggregations = undefined;
  DashboardAggregation?: Aggregations = undefined;
  Type: ReportTypes = 0;
  MainRecordType?: string = undefined;
  Active: boolean = false;
  ForPrint: boolean = false;
  Command?: string = undefined;
  RecipientField?: string = undefined;
  AggregationCount: number = 0;
  Name?: string = undefined;
  DataType?: DataTypes = undefined;
  FieldType?: FieldTypes = undefined;
  IDFieldName?: string = undefined;
  ForeignTypeName?: string = undefined;
  Warning?: string = undefined;
  Group?: string = undefined;
  PickReportID?: number = undefined;
  FontSize?: number = undefined;
  FontWeight?: number = undefined;
  Uses: number = 0;
  IsNew: boolean = false;
  ShowTime?: boolean = undefined;
  Visible?: boolean = undefined;
  SpecificCommand: boolean = false;
  Required: boolean = false;
  IsCommand: boolean = false;
  Editable: boolean = true;
  Multiline: boolean = false;
  LetZero?: boolean = undefined;
  MaxLength?: number = undefined;
  Internal: boolean = false;
  KeepOpen: boolean = true;
  NoChange: boolean = false;
  ShowAll: boolean = false;
  AutoComplete?: boolean = undefined;
  WordWrap?: boolean = undefined;
  FilterBar?: boolean = undefined;
  NeedsField: boolean = false;
  NeedsReport: boolean = false;
  NeedsList: boolean = false;
  NeedsRecord: boolean = true;
  CausesUnconfirm: boolean = false;
  Locked: boolean = false;
  Licensed: boolean = true;
  SpecialPicker: boolean = false;
  MergePicker: boolean = false;
  ForeColor?: number = undefined;
  BackColor?: number = undefined;
  DefaultPosition: number = 200;
  Tooltip?: string = undefined;
  Icon?: string = undefined;
  Key?: string = undefined;
  ListType?: string = undefined;
  NavigationField?: string = undefined;
  RecordType?: string = undefined;
  MeasureUnit?: Units = undefined;
  DatePrecision?: Intervals = undefined;
  SortOrder?: number = undefined;
  SortDescending?: boolean = undefined;
  ForHeader?: boolean = undefined;
  HasPickList: boolean = false;
  PrefillOptions?: boolean = undefined;
  EditMode: boolean = false;
  ForAppList: boolean = false;
  DefaultType?: DefaultTypes = undefined;
  DefaultID?: number = undefined;
  AskIfEmpty?: boolean = undefined;
  ForGlobalSearch?: boolean = undefined;
  SectionID?: number = undefined;
  ForSimilars?: boolean = undefined;
  DefaultValue?: any;
  CoinField?: string = undefined;
  ForPortalEdit?: boolean = undefined;
  FormatPicker: boolean = false;
  InitialValue?: any;
}

export interface NavData{
  Domains: {Key: number, Value: string}[];
  AppName?: string;
  Populars: ReportLite[];
  AllLists: string[];
  Lists: DashboardTile[];
  SystemAlert: {Key: string, Value: string};
  QuickAdds: {Key: string, Value: string}[];
  Printables: string[];
  Recents: DashboardTile[];
}

export enum Operators {
    Equals,
    In,
    LessThan,
    LessThanOrEquals,
    GreaterThan,
    GreaterThanOrEquals,
}

export enum OutputType {
    Html,
    Csv,
    Speech,
    Json,
    Sms,
}

export interface PickListItem{
  Key?: any;
  Value?: string;
  Icon?: string;
}

export interface PortalData{
  UserID?: number;
  Reports: ReportLite[];
  CompanyName?: string;
  UserName?: string;
}

export interface ProgressData{
  Description?: string;
  Index?: number;
  Total?: number;
  Date: Date;
}

export interface RecordError{
  FieldName?: string;
  FieldTitle?: string;
  Value?: any;
  Message?: string;
  RecordType?: string;
  Priority?: AlertLevels;
  Useless: boolean;
  RecordID?: number;
  OtherRecordID?: number;
  OtherRecordType?: string;
}

export class RecordsData{
  ReportID?: number = undefined;
  RecordType?: string = undefined;
  Records: LiteRecordData[] = [];
  Columns: ColumnData[] = [];
  QuickAdds: {Key: string, Value: string}[] = [];
  Reports: ReportLite[] = [];
  SampleRecord: ControlProps[] = [];
}

export class ReportLite{
  Count: number = 0;
  LastCount: Date= new Date();
  RecountSpan?: any = undefined;
  BackColor?: number = undefined;
  ForeColor?: number = undefined;
  Position: number = 0;
  DisplayString?: string = undefined;
  AlertLevel?: AlertLevels = undefined;
  ForDashboard: boolean = false;
  ShowCount: boolean = false;
  Language?: string = undefined;
  Frequency: number = 0;
  DoneOn?: Date = undefined;
  ParentID?: number = undefined;
  IsAlert: boolean = false;
  Group?: string = undefined;
  ForTopMenu: boolean = false;
  PauseTill?: Date = undefined;
  DomainID?: number = undefined;
  ForRestDays: boolean = false;
  ValueFontSize?: number = undefined;
  WorkingOn?: Date = undefined;
  RetryDelay?: any = undefined;
  UserID?: number = undefined;
  ForPortal: boolean = false;
  Uses: number = 0;
  ForMainRecord: boolean = false;
  Paused: boolean = false;
  ShowWhenEmpty: boolean = false;
  LocalSmsTemplate?: string = undefined;
  LocalName?: string = undefined;
  FilterCount: number = 0;
  AlertColor?: number = undefined;
  AggregationFieldType?: FieldTypes = undefined;
  FilterDescriptions: FilterDescription[] = [];
  ID: number = 0;
  Name?: string = undefined;
  RecordType?: string = undefined;
  MainRecordType?: string = undefined;
  MainRecordField?: string = undefined;
  AddedOn: Date= new Date();
  ForOffline?: boolean = undefined;
  LastSync?: Date = undefined;
  KeyFields: MemberData[] = [];
  IsUnion: boolean = false;
  HasTableType: boolean = false;
  Active: boolean = false;
  ForPrint: boolean = false;
  RecipientField?: string = undefined;
  Type: ReportTypes = 0;
  LabelWidth?: number = undefined;
  LabelHeight?: number = undefined;
  Subject?: string = undefined;
  AggregationCount: number = 0;
  EvalRecordType?: string = undefined;
  Template?: string = undefined;
  Command?: string = undefined;
}

export enum ReportTemplates {
    DeleteOldTraces,
    DeleteOldExchanges,
    DownloadAllMessages,
    Workers,
    UploadDriveSessions,
    ImportData,
    FillFileData,
    TrimEdits,
    PendingUploads,
    DownloadNewMessages,
    PendingTasks,
    TrimExecutions,
    FullDatabaseAlert,
    CallsPopup,
    BalanceReminder,
    MessagesPopup,
    FailedErrands,
}

export enum ReportTypes {
    List,
    Single,
    Count,
    Calendar,
    Cards,
    Chat,
    Popup,
}

export class SearchResult{
  Name?: string = undefined;
  Value?: string = undefined;
  RecordName?: string = undefined;
  RecordID?: number = undefined;
  RecordType?: string = undefined;
}

export interface SearchResultGroup{
  Name?: string;
  LocalName?: string;
  List: SearchResult[];
  Total: number;
}

export enum SettingGroup {
    AppListSort,
    EditFields,
    Columns,
    ColumnsWidth,
    Popup,
    EditPage,
    ColumnsSetAll,
    Options,
    PageRows,
    PanelView,
    RecordPage,
    SetValue,
    ShowAllLists,
    ShowPreview,
    Sorting,
    Splitter,
    TabSelection,
    WindowRect,
    RecordPrint,
    PrintColumns,
    CsvColumns,
    ShowFilters,
    PreviewFields,
    AppDetails,
    Misc,
    EditColumns,
    PickColumns,
    Dashboard,
}

export enum Shipper {
    Fedex,
    Ups,
    Usps,
}

export interface ShipRate{
  Provider?: string;
  CompanyName?: string;
  Shipper?: Shipper;
  ShipTypeID: number;
  Key?: string;
  ShipCost: number;
  InsuranceCost: number;
  ServiceName?: string;
  ServiceCode?: string;
  ForCart: boolean;
  DeliverDate?: Date;
  DeliverDays?: number;
  DeliverTime?: any;
  Container?: string;
  CompanyCode?: string;
  RateCode?: string;
  TotalCost: number;
}

export interface TestingData{
  IsRemote: boolean;
  AppName?: string;
  PickerDateFormat?: string;
  CustomizationMode: boolean;
  Reports: any[];
  Members: MemberData[];
  ChargeProviders: string[];
  RecordTypes: any[];
  ReportFields: MiniReportField[];
}

export enum TextAligns {
    Center,
    Start,
    End,
    Right,
    Left,
}

export enum Units {
    Bytes,
    Centimeters,
    Celsius,
    Farenheit,
    Feet,
    FluidOunces,
    Gallons,
    Gigabytes,
    Grams,
    Inches,
    Kilobytes,
    Kilograms,
    Kilometers,
    Liters,
    Megabytes,
    Meters,
    Miles,
    Miligrams,
    Milimeters,
    Mililiters,
    Ounces,
    Pixels,
    Points,
    Pounds,
    Terabytes,
    SquareCentimeters,
    SquareMeters,
    Tons,
}

export interface ViewTable{
  Records: RecordsData;
  ShowPreview: boolean;
  DisplayName?: string;
  PageRows: number;
  ValueBorderColor?: number;
  InputParams: ColumnData[];
  Filters: FilterDescription[];
  AllowedKeys: string[];
  ReportType?: ReportTypes;
  FilterReports: {Key: number, Value: string}[];
  ListType?: string;
}
export const MachshevetClient = {Global: {GetCommands: (RecordType: string,ReportID?: number, signal?: AbortSignal) => invokeFunction<ColumnData[]>("Global", "GetCommands", {RecordType,ReportID},false, signal),

PrepareJson: (Input: string,Type: any, signal?: AbortSignal) => invokeFunction<any>("Global", "PrepareJson", {Input,Type},false, signal),

InputCommand: (Command: string,Url: string,InputJson: string,InputObject: any, signal?: AbortSignal) => performAction("Global", "InputCommand", {Command,Url,InputJson,InputObject},true, signal),

Preview: (RecordType: string,ID: number, signal?: AbortSignal) => invokeFunction<RecordsData>("Global", "Preview", {RecordType,ID},false, signal),

Dial: (Number: number, signal?: AbortSignal) => performAction("Global", "Dial", {Number},false, signal),

GetColumnData: (Group: SettingGroup,Key?: string,RecordType?: string,ForAll?: boolean,ReportID?: number, signal?: AbortSignal) => invokeFunction<ColumnData[]>("Global", "GetColumnData", {Group,Key,RecordType,ForAll,ReportID},false, signal),

MoveMember: (RecordType: string,Group: SettingGroup,Name: string,PushedName: string,Key?: string,ReportID?: number, signal?: AbortSignal) => performAction("Global", "MoveMember", {RecordType,Group,Name,PushedName,Key,ReportID},false, signal),

SetColumns: (Choices: ColumnChoice, signal?: AbortSignal) => performAction("Global", "SetColumns", {Choices},true, signal),

SetAuthorizationCode: (Code: string,State: number, signal?: AbortSignal) => performAction("Global", "SetAuthorizationCode", {Code,State},false, signal),

ExternalLogin: (Code: string, signal?: AbortSignal) => performAction("Global", "ExternalLogin", {Code},false, signal),

Ping: ( signal?: AbortSignal) => invokeFunction<any>("Global", "Ping", {},false, signal),

GlobalSearch: (Term: string, signal?: AbortSignal) => invokeFunction<SearchResultGroup[]>("Global", "GlobalSearch", {Term},false, signal),

OptionList: ( signal?: AbortSignal) => invokeFunction<RecordsData>("Global", "OptionList", {},false, signal),

SetOptions: (Changes: { [index:string]: any }, signal?: AbortSignal) => performAction("Global", "SetOptions", {Changes},true, signal),

SetValue: (RecordType: string,RecordKeys: Set<string>,FieldName: string,NewValue: any, signal?: AbortSignal) => performAction("Global", "SetValue", {RecordType,RecordKeys,FieldName,NewValue},true, signal),

GetInputOptions: (RecordType: string,Command: string,FieldName: string,InputJson: string, signal?: AbortSignal) => invokeFunction<PickListItem[]>("Global", "GetInputOptions", {RecordType,Command,FieldName,InputJson},true, signal),

MarkRecordSeen: (RecordType: string,ID: number, signal?: AbortSignal) => performAction("Global", "MarkRecordSeen", {RecordType,ID},false, signal),

MarkReportSeen: (ReportID: number, signal?: AbortSignal) => performAction("Global", "MarkReportSeen", {ReportID},false, signal),

FixDB: (RemoteAlso?: boolean, signal?: AbortSignal) => invokeFunction<any>("Global", "FixDB", {RemoteAlso},false, signal),

CheckObjects: ( signal?: AbortSignal) => invokeFunction<any>("Global", "CheckObjects", {},false, signal),

GetLists: ( signal?: AbortSignal) => invokeFunction<any>("Global", "GetLists", {},false, signal),

SetSetting: (Name: string,Value: string,Group: SettingGroup,RecordType?: string,ReportID?: number, signal?: AbortSignal) => invokeFunction<any>("Global", "SetSetting", {Name,Value,Group,RecordType,ReportID},false, signal),

Static: (path: string, signal?: AbortSignal) => performAction("Global", "Static", {path},false, signal),

AppStatic: (path: string, signal?: AbortSignal) => performAction("Global", "AppStatic", {path},false, signal),

VectorIcon: (Name: string, signal?: AbortSignal) => performAction("Global", "VectorIcon", {Name},false, signal),

Favicon: ( signal?: AbortSignal) => invokeFunction<any>("Global", "Favicon", {},false, signal),

AddCaching: (Span?: any, signal?: AbortSignal) => performAction("Global", "AddCaching", {Span},false, signal),

Logo: ( signal?: AbortSignal) => invokeFunction<any>("Global", "Logo", {},false, signal),

DashboardBackground: ( signal?: AbortSignal) => invokeFunction<any>("Global", "DashboardBackground", {},false, signal),

Localize: (value: string, signal?: AbortSignal) => invokeFunction<string>("Global", "Localize", {value},false, signal),

UITexts: (Language: string, signal?: AbortSignal) => invokeFunction<{ [index:string]: string }>("Global", "UITexts", {Language},false, signal),

ClearCache: ( signal?: AbortSignal) => performAction("Global", "ClearCache", {},false, signal),

ClearBrowser: ( signal?: AbortSignal) => performAction("Global", "ClearBrowser", {},false, signal),

FindEmailAddresses: (input: string, signal?: AbortSignal) => invokeFunction<{Key: string, Value: string}[]>("Global", "FindEmailAddresses", {input},false, signal),

Mailboxes: ( signal?: AbortSignal) => invokeFunction<{Key: number, Value: {Key: number, Value: string}[]}>("Global", "Mailboxes", {},false, signal),

FindPlaces: (Term: string,Type?: string, signal?: AbortSignal) => invokeFunction<{Key: string, Value: string}[]>("Global", "FindPlaces", {Term,Type},false, signal),

DoCommand: (Name: string, signal?: AbortSignal) => performAction("Global", "DoCommand", {Name},false, signal),

DownloadData: ( signal?: AbortSignal) => invokeFunction<any>("Global", "DownloadData", {},false, signal),

SetDomainID: (DomainID?: number, signal?: AbortSignal) => performAction("Global", "SetDomainID", {DomainID},false, signal),

GetAppData: ( signal?: AbortSignal) => invokeFunction<AppData>("Global", "GetAppData", {},false, signal),

GetNavData: ( signal?: AbortSignal) => invokeFunction<NavData>("Global", "GetNavData", {},false, signal),

GetDashboardData: ( signal?: AbortSignal) => invokeFunction<DashboardData>("Global", "GetDashboardData", {},false, signal),

SendMail: (Input: CommandMessage, signal?: AbortSignal) => performAction("Global", "SendMail", {Input},true, signal),

GetPerson: (Email: string,Columns?: string[], signal?: AbortSignal) => performAction("Global", "GetPerson", {Email,Columns},false, signal),

GetPortalRecord: (ReportID: number,RecordID?: number, signal?: AbortSignal) => invokeFunction<RecordsData>("Global", "GetPortalRecord", {ReportID,RecordID},false, signal),

CheckRec: (ReportID: number,RecordID?: number, signal?: AbortSignal) => performAction("Global", "CheckRec", {ReportID,RecordID},false, signal),

SavePortalRecord: (ReportID: number,JsonInput: { [index:string]: any },RecordID?: number, signal?: AbortSignal) => performAction("Global", "SavePortalRecord", {ReportID,JsonInput,RecordID},true, signal),

PortalList: (ReportID: number, signal?: AbortSignal) => invokeFunction<RecordsData>("Global", "PortalList", {ReportID},false, signal),

GetPortalData: ( signal?: AbortSignal) => invokeFunction<PortalData>("Global", "GetPortalData", {},false, signal),

DeleteRecord: (RecordType: string,RecordID: number, signal?: AbortSignal) => performAction("Global", "DeleteRecord", {RecordType,RecordID},false, signal),

GetTestingData: ( signal?: AbortSignal) => performActionT<TestingData>("Global", "GetTestingData", {},false, signal),

WebHook: ( signal?: AbortSignal) => performAction("Global", "WebHook", {},false, signal),

OnAuthorization: (filterContext: any, signal?: AbortSignal) => performAction("Global", "OnAuthorization", {filterContext},false, signal),

SafeJsonResult: (Input: any,IncludeNoJsons?: boolean,IncludeNulls?: boolean, signal?: AbortSignal) => invokeFunction<any>("Global", "SafeJsonResult", {Input,IncludeNoJsons,IncludeNulls},false, signal),

InvokeFunction: (Name: string,JsonInput: string, signal?: AbortSignal) => invokeFunction<any>("Global", "InvokeFunction", {Name,JsonInput},false, signal),

CommandResult: (Result: any[],Context: any,Command: string, signal?: AbortSignal) => performAction("Global", "CommandResult", {Result,Context,Command},false, signal),

},Login: {GenerateTempPassword: (LoginUserName: string, signal?: AbortSignal) => performAction("Login", "GenerateTempPassword", {LoginUserName},false, signal),

Login: (LoginUserName: string,LoginPassword: string, signal?: AbortSignal) => invokeFunction<LoginResult>("Login", "Login", {LoginUserName,LoginPassword},false, signal),

MicrosoftLogin: ( signal?: AbortSignal) => invokeFunction<string>("Login", "MicrosoftLogin", {},false, signal),

ChangePassword: (NewPassword: string, signal?: AbortSignal) => performAction("Login", "ChangePassword", {NewPassword},false, signal),

LogOut: ( signal?: AbortSignal) => performAction("Login", "LogOut", {},false, signal),

Register: (Model: LoginModel, signal?: AbortSignal) => performAction("Login", "Register", {Model},true, signal),

KeepSessionLive: ( signal?: AbortSignal) => performAction("Login", "KeepSessionLive", {},false, signal),

OnAuthorization: (filterContext: any, signal?: AbortSignal) => performAction("Login", "OnAuthorization", {filterContext},false, signal),

SafeJsonResult: (Input: any,IncludeNoJsons?: boolean,IncludeNulls?: boolean, signal?: AbortSignal) => invokeFunction<any>("Login", "SafeJsonResult", {Input,IncludeNoJsons,IncludeNulls},false, signal),

InvokeFunction: (Name: string,JsonInput: string, signal?: AbortSignal) => invokeFunction<any>("Login", "InvokeFunction", {Name,JsonInput},false, signal),

CommandResult: (Result: any[],Context: any,Command: string, signal?: AbortSignal) => performAction("Login", "CommandResult", {Result,Context,Command},false, signal),

},GetRecordName: (controller: string, ID: number, signal?: AbortSignal) => invokeFunction<string>(controller, "GetRecordName", {ID},false, signal),

GetDoc: (controller: string, Column: string,ID: number,Download?: boolean,Page?: number,Width?: number, signal?: AbortSignal) => invokeFunction<any>(controller, "GetDoc", {Column,ID,Download,Page,Width},false, signal),

Insert: (controller: string, Records: any[], signal?: AbortSignal) => performAction(controller, "Insert", {Records},true, signal),

SaveBulk: (controller: string, Record: any,Url: string, signal?: AbortSignal) => performAction(controller, "SaveBulk", {Record,Url},true, signal),

ApiSaveEdit: (controller: string, RecordID: number,Values: { [index:string]: any },Url: string, signal?: AbortSignal) => invokeFunction<RecordsData>(controller, "ApiSaveEdit", {RecordID,Values,Url},true, signal),

PrepareJsonInput: (controller: string, Input: any, signal?: AbortSignal) => performAction(controller, "PrepareJsonInput", {Input},false, signal),

RecordData: (controller: string, Record: any,ChangedFieldName?: string,Index?: number,SubFieldName?: string, signal?: AbortSignal) => invokeFunction<RecordsData>(controller, "RecordData", {Record,ChangedFieldName,Index,SubFieldName},true, signal),

PageRecord: (controller: string, RecordID?: number,PresetValues?: { [index:string]: any }, signal?: AbortSignal) => invokeFunction<RecordsData>(controller, "PageRecord", {RecordID,PresetValues},true, signal),

PrepareImportFile: (controller: string, File: File,DefaultValues?: string, signal?: AbortSignal) => performActionT<ImportSetting>(controller, "PrepareImportFile", {File,DefaultValues},true, signal),

GetPrompts: (controller: string,  signal?: AbortSignal) => invokeFunction<ImportSetting>(controller, "GetPrompts", {},false, signal),

SaveImportColumns: (controller: string, Columns: MiniImportColumn[], signal?: AbortSignal) => performActionT<MiniImportColumn[]>(controller, "SaveImportColumns", {Columns},true, signal),

GetImportColumns: (controller: string,  signal?: AbortSignal) => performActionT<MiniImportColumn[]>(controller, "GetImportColumns", {},false, signal),

Import: (controller: string, File: File,Columns: MiniImportColumn[],MaxRows?: number,SkipRows?: number,Sheet?: string,DefaultValues?: string, signal?: AbortSignal) => performActionT<number>(controller, "Import", {File,Columns,MaxRows,SkipRows,Sheet,DefaultValues},true, signal),

GetEntitySettingID: (controller: string,  signal?: AbortSignal) => performActionT<number>(controller, "GetEntitySettingID", {},false, signal),

EntitySetting: (controller: string,  signal?: AbortSignal) => performActionT<fncEntitySetting>(controller, "EntitySetting", {},false, signal),

GetSelectOptions: (controller: string, Record: any,SelectFieldName: string, signal?: AbortSignal) => performActionT<PickListItem[]>(controller, "GetSelectOptions", {Record,SelectFieldName},true, signal),

GetAllIDs: (controller: string, GridProps: GridProps, signal?: AbortSignal) => performActionT<number[]>(controller, "GetAllIDs", {GridProps},true, signal),

GetFieldSetting: (controller: string, FieldName: string, signal?: AbortSignal) => performActionT<RecordsData>(controller, "GetFieldSetting", {FieldName},false, signal),

FieldCount: (controller: string, GridProps: GridProps,Field: string,Term?: string, signal?: AbortSignal) => invokeFunction<FieldCountData[]>(controller, "FieldCount", {GridProps,Field,Term},true, signal),

GetOfflineList: (controller: string, ReportID?: number, signal?: AbortSignal) => invokeFunction<any>(controller, "GetOfflineList", {ReportID},false, signal),

GetListType: (controller: string, GridProps: GridProps,JsonInput?: string, signal?: AbortSignal) => invokeFunction<string>(controller, "GetListType", {GridProps,JsonInput},false, signal),

InsertGrid: (controller: string,  signal?: AbortSignal) => performActionT<RecordsData>(controller, "InsertGrid", {},false, signal),

GetCount: (controller: string, Model: any,GridProps: GridProps,JsonInput?: string, signal?: AbortSignal) => performActionT<number>(controller, "GetCount", {Model,GridProps,JsonInput},true, signal),

GetTotals: (controller: string, Model: any,GridProps: GridProps,JsonInput?: string, signal?: AbortSignal) => invokeFunction<{Key: string, Value: any}[]>(controller, "GetTotals", {Model,GridProps,JsonInput},true, signal),

QuickSearch: (controller: string, Model: any,GridProps: GridProps,JsonInput?: string,ShowBarFilters?: boolean, signal?: AbortSignal) => invokeFunction<ViewTable>(controller, "QuickSearch", {Model,GridProps,JsonInput,ShowBarFilters},true, signal),

DoCommandMulti: (controller: string, Command: string,GridProps: GridProps,Record?: any,FieldName?: string,Value?: any,Url?: string,ReportFieldID?: number,Params?: string[], signal?: AbortSignal) => performAction(controller, "DoCommandMulti", {Command,GridProps,Record,FieldName,Value,Url,ReportFieldID,Params},true, signal),

GetFinalQuery: (controller: string, GridProps: GridProps,Context: any,Record?: any,Fields?: Set<string>,CountOnly?: boolean,DoFilterBars?: boolean,DoGrouping?: boolean,JsonInput?: string, signal?: AbortSignal) => invokeFunction<any[]>(controller, "GetFinalQuery", {GridProps,Context,Record,Fields,CountOnly,DoFilterBars,DoGrouping,JsonInput},false, signal),

GetSuggestions: (controller: string, GridProps: GridProps, signal?: AbortSignal) => invokeFunction<string[]>(controller, "GetSuggestions", {GridProps},true, signal),

GetDisplayQuery: (controller: string, Context: any,GridProps: GridProps,Record?: any,CountOnly?: boolean,Fields?: Set<string>,DoFilterBars?: boolean,DoGrouping?: boolean,JsonInput?: string,DoSorting?: boolean, signal?: AbortSignal) => invokeFunction<any[]>(controller, "GetDisplayQuery", {Context,GridProps,Record,CountOnly,Fields,DoFilterBars,DoGrouping,JsonInput,DoSorting},false, signal),

OnActionExecuting: (controller: string, filterContext: any, signal?: AbortSignal) => performAction(controller, "OnActionExecuting", {filterContext},false, signal),

OnAuthorization: (controller: string, filterContext: any, signal?: AbortSignal) => performAction(controller, "OnAuthorization", {filterContext},false, signal),

SafeJsonResult: (controller: string, Input: any,IncludeNoJsons?: boolean,IncludeNulls?: boolean, signal?: AbortSignal) => invokeFunction<any>(controller, "SafeJsonResult", {Input,IncludeNoJsons,IncludeNulls},false, signal),

InvokeFunction: (controller: string, Name: string,JsonInput: string, signal?: AbortSignal) => invokeFunction<any>(controller, "InvokeFunction", {Name,JsonInput},false, signal),

CommandResult: (controller: string, Result: any[],Context: any,Command: string, signal?: AbortSignal) => performAction(controller, "CommandResult", {Result,Context,Command},false, signal),

}