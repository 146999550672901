//***Copyright Notice***
//____________________________________________________
//Copyright © 2024 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { FC, useState, useRef, useContext } from "react"
import { Icon, SmartButton, useEffect2 } from "./misc"
import { ColumnData, ImportSetting, LiteRecordData, MachshevetClient, MiniImportColumn, RecordsData } from "./Declarations"
import { ControlProps2, defaultGridProps, getControlProps2, myStringify, numberColor, showConfirm, useQuery } from "./globals"
import { RecordPage } from "./RecordPage"
import { Control } from "./Control"
import { AppContext, MainContext } from "./styles"
let clmID: number | undefined = 0

export const ImportFile: FC<{ recordType: string }> = props => {
    const app = useContext(AppContext)!
    const ctx = useContext(MainContext)
    const [state, setState] = useState<ImportSetting>()
    const [columns, setColumns] = useState<MiniImportColumn[]>()
    const [maxRows, setMaxRows] = useState<number>()
    const [SkipRows, setSkipRows] = useState<number>()
    const [importID, setImportID] = useState<number>()
    const [sheet, setSheet] = useState<string>();
    const [defaultValues, setDefaultValues] = useState<ControlProps2[]>()
    const [foreignSetted, setForeignSetted] = useState(false)

    const query = useQuery();
    const rid = query.get("ForeignRecordID")
    const foreignRT = query.get("ForeignRecordType")
    setDefaultsFromURL()
    function setDefaultsFromURL() {
        if (rid && foreignRT && defaultValues && !foreignSetted) {
            const rpid = parseInt(rid);
            setDefaultValues(prev => prev!.map(z => z.Name == foreignRT + "ID" ? { ...z, Value: rpid } : z))
            setForeignSetted(true)
        }
    }

    const fileInput = useRef<HTMLInputElement>(null);

    function getRecType() { return props.recordType }

    useEffect2(async () => {
        app.setPageTitle!(ctx.localized(props.recordType) + ' [' + ctx.localized("Import") + ']', props.recordType);
        await getPrompts();
        setDefaultsFromURL()
    }, [])

    const inpstyle: React.CSSProperties = { borderRadius: 5, borderWidth: 1, borderStyle: "solid", borderColor: numberColor(ctx.data.SecondaryColor) }
    const gp = defaultGridProps()
    const fld = new ColumnData()
    fld.Name = "ImportID"
    fld.MinFilter = (importID || 0).toString()
    fld.MaxFilter = (importID || 0 + 1).toString()
    gp.Fields.push(fld)

    async function prepareFile() {
        const fils = fileInput.current?.files
        if (fils) {
            const dvals = getDefVals()
            const imps = await MachshevetClient.PrepareImportFile(getRecType(), fils[0], dvals)
            setState(imps)
            setColumns(imps.ImportColumns)
            //if (!defaultValues) setDefaultValues(imps.Prompts.map(x => x as ControlProps2))
            if (!defaultValues) setDefaultValues(getControlProps2(imps.Prompts, imps.PromptColumns))
        }
    }
    async function getPrompts() {
        const imps = await MachshevetClient.GetPrompts(getRecType())
        //if (!defaultValues) setDefaultValues(imps.Prompts.map(x => x as ControlProps2))
        if (!defaultValues) setDefaultValues(getControlProps2(imps.Prompts, imps.PromptColumns))
    }

    function setCol(column: MiniImportColumn, changedProp: 'FieldName' | 'IsKey' | 'Row', changedval: any) {
        if (changedProp === "IsKey") {
            column[changedProp] = changedval || false;
        } else {
            column[changedProp] = changedval;
        }
        setState(prev => ({ ...prev!, FileColumns: columns?.map(x => x.Name! == column.Name ? column : x) }));
    }
    //const rd = defaultRecordData("Import", importID)
    const rd = new RecordsData()
    rd.RecordType = "Import"
    const lrd = new LiteRecordData()
    lrd.RecordID = importID || 0
    rd.Records = [lrd]

    const fillngth = fileInput.current?.files?.length
    const dsbld = fillngth === undefined || fillngth === 0

    async function save() {
        const newcols = await MachshevetClient.SaveImportColumns(getRecType(), columns!)
        refreshCols(newcols)
        return newcols
    }

    function refreshCols(newcols: MiniImportColumn[]) {
        let newstt = columns
        newstt = newstt?.map(x => {
            const newcol = newcols.find(y => y.Name === x.Name)
            if (newcol) { newcol.Samples = x.Samples }
            return newcol || x
        })
        setColumns(newstt)
    }

    function getDefVals() {
        const dctn = defaultValues?.reduce((acc, item) => {
            acc[item.memberData!.LocalName!] = item.Value;
            return acc;
        }, {} as any)
        return myStringify(dctn)
    }

    return <div style={{ display: "flex", flexDirection: "column", overflowY: "auto", gap: 10 }}>
        <div style={{ display: "flex", padding: 5, justifyContent: "space-between" }}>
            <div style={{ display: "flex" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <span> {ctx.localized("File")}</span>
                    <input id={"TestFileUpload"} type="file" accept=".csv,.xlsx,.xls,.dbf,.zip" ref={fileInput} onChange={prepareFile} />
                </div>
                <SmartButton onClick={prepareFile}>{ctx.localized("PrepareFile")}</SmartButton>
                <SmartButton onClick={save}>{ctx.localized("Save")}</SmartButton>

                {state && state.Sheets.length && <div style={{ display: "flex", flexDirection: "column" }}><span> {ctx.localized("Sheets")}</span> <select value={sheet} onChange={e => setSheet(e.currentTarget.value)}>
                    <option>{'(' + ctx.localized("All") + ')'}</option>
                    {state.Sheets.map(x => <option key={x}>{x}</option>)}
                </select></div>}
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <span> {ctx.localized("MaxRows")}</span>
                    <input type="number" value={maxRows} onChange={e => setMaxRows(e.currentTarget.valueAsNumber)} style={inpstyle} />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <span> {ctx.localized("SkipRows")}</span>
                    <input type="number" value={SkipRows} onChange={e => setSkipRows(e.currentTarget.valueAsNumber)} style={inpstyle} />
                </div>
            </div>
            {<div style={{ display: "flex", borderWidth: 2, borderRadius: 5, borderStyle: "solid", borderColor: numberColor(ctx.data.PrimaryColor), padding: 1, gap: 1 }}>
                {defaultValues?.map(x => {
                    const y = x as ControlProps2
                    y.onChange = e => setDefaultValues(prev => prev!.map(z => z.Name == x.Name ? { ...z, Value: e, DisplayString: e } : z))
                    return <div key={x.Name}>{x.memberData!.LocalName}<Control field={y} /></div>
                })}
            </div>}
        </div>

        <div style={{ display: 'flex', flexWrap: 'wrap', gap: 4, overflowY: "auto", resize: "vertical" }}>
            {columns && columns.map(column => {
                const inuse = column.FieldName || column.SubFieldName
                const clr = inuse ? numberColor(ctx.data.PrimaryColor) : "lightgray"
                const brdstyl: React.CSSProperties = { borderWidth: 2, borderStyle: "solid", borderColor: clr, borderRadius: 7, padding: 3 }
                return <div style={brdstyl} key={column.Name} id={"TestColumnBox" + column.Name}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>
                            <span style={{ fontWeight: "bold", color: clr }}> {column.Name} </span>
                            <input id="TestIsKey" type="checkbox" checked={column.IsKey} onChange={e => setCol(column, 'IsKey', e.currentTarget.checked)} />
                            <span> {ctx.localized('IsKey')} </span>
                            <input type="number" style={{ ...inpstyle, width: 40 }} value={column.Row} onChange={e => setCol(column, 'Row', e.currentTarget.valueAsNumber)} />
                        </div>
                        <div style={{ display: "flex" }}>
                            <Icon name='Settings' onClick={async () => {
                                if (!column.ID && column.FieldName) {
                                    const newcols = await save()
                                    clmID = newcols.filter(c => c.FieldName === column.FieldName)[0].ID
                                }
                                //const rd = defaultRecordData("ImportColumn", column.ID || clmID)
                                const rd = new RecordsData()
                                rd.RecordType = "ImportColumn"
                                const lrd = new LiteRecordData()
                                lrd.RecordID = column.ID || clmID || 0
                                rd.Records = [lrd]
                                app.openRecord!(rd, async () => {
                                    const newcols = await MachshevetClient.GetImportColumns(getRecType())
                                    refreshCols(newcols)
                                }, { Name: column.Name, RecordType: getRecType() })
                            }} />
                            <Icon name="Delete" onClick={async () => {
                                const cnf = showConfirm(ctx.localized("AreYouSureYouWantToDeleteTheseSettings") + "?")
                                if (cnf) {
                                    await MachshevetClient.Global.DeleteRecord('ImportColumn', column.ID)
                                    await prepareFile()
                                }
                            }} />
                        </div>
                    </div>

                    <div >
                        {column.SubRecordType ? <span style={{ fontSize: 12, fontWeight: "bold" }}>{column.LocalSubRecordType}</span> : <select id="TestFieldName" value={column.FieldName || ""} onChange={e => setCol(column, 'FieldName', e.currentTarget.value)}>
                            <option />
                            {state && state.EntityColumns.map(x => <option id={"TestFieldName_" + x.Key} value={x.Key} key={x.Key} >{x.Value}</option>)}
                        </select>


                        }
                    </div>

                    <div>
                        {column.Samples?.map(sample => <div key={sample} style={{ fontSize: 10, color: 'gray' }}>{sample}</div>)}
                    </div>
                </div>
            }
            )}
        </div>
        <div style={{ display: "flex", justifyContent: "center", padding: 8 }}>
            <SmartButton testName="Upload" onClick={async () => {
                const inpfile = fileInput
                const curfil = inpfile.current
                const fils = curfil!.files
                const fil = fils![0]
                const dvals = getDefVals()
                const impid = await MachshevetClient.Import(getRecType(), fil, columns!, maxRows, SkipRows, sheet, dvals)
                setImportID(impid);
            }} disabled={dsbld}>{ctx.localized("Upload")}</SmartButton>
        </div>
        <RecordPage record={rd} key={importID} redirectActiveTab={false} />
    </div>
}