//***Copyright Notice***
//____________________________________________________
//Copyright © 2024 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { useContext } from 'react'
import { FC, useState, useRef } from 'react'
import { MachshevetClient, SearchResultGroup } from './Declarations'
import { adjustColor, numberColor, redirect } from './globals'
import { NavLink, Preview, SmartDiv, useEffect2, VecIcon } from './misc'
import { MainContext } from './styles'

export const GlobalSearch: FC = () => {
    const ctx = useContext(MainContext)!;
    const [results, setResults] = useState<SearchResultGroup[]>()
    const [selected, setSelected] = useState<[number, number]>();
    const [focused, setFocused] = useState(false);
    const [showResults, setShowResults] = useState(false);
    const [term, setTerm] = useState<string>();
    const [previewKey, setPreviewKey] = useState<string>();
    const abortController = useRef(new AbortController());

    async function globalKeyPressed(event: KeyboardEvent) {
        const key = event.code
        if (event.ctrlKey && key == "KeyQ") {
            setFocused(true)
            const perm = await navigator.permissions.query({ name: 'clipboard-read' as PermissionName })
            if (perm.state !== 'denied') {
                const clp = await navigator.clipboard.readText()
                setTerm(clp)
            }

        } else if (key === "Esc" || key === "Escape") {
            setShowResults(false)
            setFocused(false)
            setResults([])
            setSelected([0, 0])
        }
    }

    document.addEventListener('keydown', globalKeyPressed)

    useEffect2(async () => {
        if (term) {
            setShowResults(true);
            if (term.length >= 2) {
                //await getdata()
                abortController.current.abort();
                abortController.current = new AbortController()
                const data = await MachshevetClient.Global.GlobalSearch(term!, abortController.current.signal)
                setResults(data)
            }
        } else {
            setShowResults(false);
            setResults([]);
        }
    }, [term]);

    function inputKeyDown(event: KeyboardEvent | React.KeyboardEvent<HTMLInputElement>) {
        const key = event.key
        if (results && key === 'ArrowDown') {
            if (!selected) setSelected([0, 0])
            else {
                //if its the last one in group:
                if (results[selected[0]].List.length - 1 === selected[1]) {
                    //if its the last group:
                    if (results.length - 1 === selected[0])
                        setSelected([0, 0]);
                    else
                        setSelected([selected[0] + 1, 0]);
                }
                else setSelected([selected[0], selected[1] + 1]);
            }
        } else if (results && key === 'ArrowUp') {
            if (!selected) setSelected([results.length - 1, results[results.length - 1].List.length - 1]);
            else {
                //if its the first in this group:
                if (selected[1] === 0) {
                    //if its the first group:
                    if (selected[0] === 0)
                        setSelected([results.length - 1, results[results.length - 1].List.length - 1]);
                    else
                        setSelected([selected[0] - 1, results[selected[0] - 1].List.length - 1]);
                }
                else setSelected([selected[0], selected[1] - 1]);
            }
        }
        else if (results && selected && key === "Enter") {
            const res = results[selected[0]].List[selected[1]]
            redirect(res.RecordType == 'Report' ? res.RecordID : undefined, res.RecordType, res.RecordID)
            setShowResults(false)
            setFocused(false)
            setSelected([0, 0])
        }
        else if (key === "Esc" || key === "Escape") {
            setShowResults(false)
            setResults([])
            setFocused(false)
            setSelected([0, 0])
        }
    };

    return <div id="TestFixedGlobalSearch">
        <input id="TestGlobalSearch" type="search" autoComplete="off" onChange={e => setTerm(e.currentTarget.value)} onKeyDown={inputKeyDown} onFocus={async e => e.target.select()} value={term} onClick={e => e.stopPropagation()}
            ref={input => focused && input && input.focus()}
            placeholder={ctx.localized('GlobalSearch') + "..."}
            style={focused ? { fontSize: 28, boxShadow: '0 0 30px ' + numberColor(ctx.data.SecondaryColor), } : { width: "100%", borderRadius: '30px', border: '1px solid #c3c3c3', padding: '6px 10px' }}
        />
        {showResults && <>
            {results ?
                <div id="TestGlobalSearchResults" style={{ backgroundColor: 'white', borderColor: numberColor(ctx.data.SecondaryColor), borderWidth: 1, borderStyle: 'solid', maxHeight: "60vh", position: 'absolute', borderRadius: 5, overflow: 'auto', zIndex: 2 }}>
                    {results && results.map((x, groupIndex) => <div key={x.Name}>
                        <div style={{ padding: 5, display: 'flex', alignItems: 'center', backgroundColor: adjustColor(ctx.data.SecondaryColor!,.7) }}>
                            <div style={{ fontWeight: 'bold', flexGrow: 1 }}>{x.LocalName}</div>
                            <div style={{ fontSize: 10, direction: 'ltr' }}>{x.List.length} / {x.Total}</div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 2, padding: 4 }}>
                            {x.List.map((x, resultIndex) => {
                                const ky = x.RecordType + '.' + x.RecordID
                                const _selected = selected && selected[0] === groupIndex && selected[1] === resultIndex
                                return <SmartDiv key={x.RecordID}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: _selected ? numberColor(ctx.data.SecondaryColor) : "unset", gap: 5 }}>
                                        <NavLink key={x.RecordID} style={{ display: "block" }} controller={x.RecordType} reportID={x.RecordType == 'Report' ? x.RecordID : undefined} recordID={x.RecordID} onClick={() => {
                                            setFocused(false)
                                            setResults([])
                                            setShowResults(false)
                                        }}>{x.Name}</NavLink><VecIcon name="Preview" width={16} onClick={() => {
                                            if (previewKey === ky) {
                                                setPreviewKey(undefined)
                                            } else setPreviewKey(ky)
                                        }} />
                                    </div>
                                    {previewKey === ky && <Preview recordType={x.RecordType!} id={x.RecordID!} miniMode={true} />}
                                </SmartDiv>
                            })}
                        </div>
                    </div>)}
                </div> :
                undefined}
        </>}
    </div>
}
GlobalSearch.displayName = "GlobalSearch"