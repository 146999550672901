//***Copyright Notice***
//____________________________________________________
//Copyright © 2024 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { useContext, useEffect, useRef, useState } from "react"
import { ColumnData, MachshevetClient, RecordsData, SettingGroup } from "./Declarations"
import { ControlProps2, controlRecord, defaultGridProps, getControlProps2 } from "./globals"
import { Control } from "./Control"
import { HamburgerMenu, NavLink, VecIcon } from "./misc"
import { AppContext, ColumnData2, doCommand, MainContext, prioritizeCommands } from "./styles"

export const EditGrid: React.FC<{ recordType: string }> = props => {
    const app = useContext(AppContext)!
    const ctx = useContext(MainContext)
    const [state, setState] = useState<RecordsData>()
    const [sample, setSample] = useState<RecordsData>()
    const [commands, setCommands] = useState<ColumnData[]>([])
    const [hamburgerShown, setHamburgerShown] = useState(false)
    const aborter = useRef(new AbortController())

    const gp = defaultGridProps()
    gp.SettingGroup = SettingGroup.EditColumns

    async function getSample() {
        const stt = await MachshevetClient.InsertGrid(props.recordType)
        setSample(stt)
        const cmnds = await MachshevetClient.Global.GetCommands(props.recordType)
        setCommands(cmnds)
    }
    useEffect(() => {
        getSample()
    }, []);

    const cmds = sample && prioritizeCommands(ctx, commands)

    async function docmd(c: ColumnData2) {
        await doCommand(c, 0, gp, app)
    }

    const relevrecs = state?.Records.filter(x => !x.IsDeleted).sortBy(x => x.Index)
    return <div style={{ display: 'flex' }}>
        <div style={{ flexGrow: 1 }}>
            <table>
                <thead style={{ fontSize: 12 }} >
                    <th />{sample && sample.Columns.filter(x => x.Visible).map(x => <th key={x.Name}>{x.LocalName}</th>)}<th />
                </thead>
                <tbody>
                    {relevrecs?.map(x => {
                        //const flds = getControlProps2(x.Fields,x.Columns)
                        const flds = getControlProps2(x.Fields, state!.Columns)
                        return <tr key={x.Index} onContextMenu={e => {
                            app.showContextMenu!(e, docmd, cmds)
                        }}><td>{x.Index}</td>{flds.filter(x => x.memberData!.Visible).map(y => {
                            const cp = y as ControlProps2
                            cp.recordID = x.RecordID
                            cp.serverRefresh = true
                            return <td key={cp.Name} >{<Control field={{
                                ...cp, modelGetter: () => {
                                    const ret = controlRecord(flds, x.RecordID!)
                                    return ret;
                                }, onChange: async (v, f) => {
                                    const newrow = flds.map(z => z.Name === y.Name ? { ...z, Value: v, DisplayString: v } : z)
                                    setState(prev => ({ ...prev!, Records: prev!.Records.map(rd => rd.Index === x.Index ? { ...rd, Fields: newrow } : rd) }))

                                    aborter.current.abort()
                                    aborter.current = new AbortController()
                                    const signal = aborter.current.signal
                                    const prec = controlRecord(newrow, 0)
                                    const data = await MachshevetClient.RecordData(x.RecordType!, prec, y.Name, undefined, undefined, signal)
                                    setState(prev => ({
                                        ...prev!, Records: prev!.Records!.map(rd => {
                                            if (rd.Index === x.Index) {
                                                //rd.Fields = data.Fields// newdata
                                                rd.Fields = data.Records[0].Fields// newdata
                                                return rd
                                            }
                                            else
                                                return rd
                                        })
                                    }))
                                    //setState(prev => prev!.map(rd => {
                                    //    if (rd.Index === x.Index) {
                                    //        rd.Fields = data.Fields// newdata
                                    //        return rd
                                    //    }
                                    //    else
                                    //        return rd
                                    //}))
                                }
                            }} />}</td>
                        })}
                            <td>
                                {x.Fields.map(x => x as ControlProps2).filter(y => y.ErrorText).map(y => <span key={y.Name} style={{ color: "red" }}>{y.ErrorRecordID ? <NavLink controller={x.RecordType} recordID={y.ErrorRecordID} >{y.ErrorText}</NavLink> : <span>{y.memberData!.LocalName + " " + y.ErrorText}</span>}</span>)}
                                <VecIcon name="Delete" width={20} onClick={() => {
                                    //setState(prev => prev!.map(y => {
                                    //    const rw = y
                                    //    if (rw.Index === x.Index) {
                                    //        rw.IsDeleted = true
                                    //    }
                                    //    return rw 
                                    //}))
                                    setState(prev => ({
                                        ...prev!, Records: prev!.Records.map(y => {
                                            const rw = y
                                            if (rw.Index === x.Index) {
                                                rw.IsDeleted = true
                                            }
                                            return rw
                                        })
                                    }))
                                }} /></td>
                        </tr>
                    }
                    )}
                </tbody>
                <tfoot>
                    <td colSpan={2} >
                        <VecIcon name='Add' width={20} onClick={() => {
                            const newrow = { ...sample!.Records[0] }
                            //const idxs = state.map(x => x.Index)
                            const idxs = state!.Records.map(x => x.Index)
                            const newidx = idxs.length ? Math.max(...idxs) + 1 : 0
                            newrow.Index = newidx// idxs.length ? newidx + 1 : 0
                            const newstt = state//!.Records.concat(newrow)
                            newstt?.Records.concat(newrow)
                            setState(newstt)
                        }} />
                    </td>
                </tfoot>
            </table>
            <button onClick={async () => {
                //await MachshevetClient.Insert(props.recordType, state.filter(x => !x.IsDeleted).map(x => controlRecord(getControlProps2(x.Fields, x.Columns), 0)))
                await MachshevetClient.Insert(props.recordType, state!.Records.filter(x => !x.IsDeleted).map(x => controlRecord(getControlProps2(x.Fields, state!.Columns), 0)))
                setState(undefined)
            }}>{ctx.localized("Save")}</button></div>
        <VecIcon name="Menu" onClick={() => setHamburgerShown(!hamburgerShown)} />
        {hamburgerShown && <HamburgerMenu onCommand={() => docmd} items={commands.map(x => x as ColumnData2)} />}
    </div>
}
