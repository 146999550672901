//***Copyright Notice***
//____________________________________________________
//Copyright © 2024 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import { CSSProperties, ReactElement, ReactNode, useEffect, useState, useMemo } from "react"
import { useLocation } from "react-router-dom";
import { AppData, ColumnData, ControlProps, GridProps, Intervals, LiteRecordData, MemberData, RecordsData, SettingGroup } from "./Declarations"
import { fetchJson, fetchPlus, queryVals } from "./shared"

declare global {
    interface Array<T> {
        sortBy: (generator: (value: T) => any[] | any) => T[]
        sum<K extends keyof T>(getKey: (item: T) => T[K]): number
        removeMany: (elements: T[]) => boolean[]
        options: () => JSX.Element[]
    }
}

Array.prototype.sortBy = function <T>(this: Array<T>, sortkeysGenerator: (x: T) => any[] | any): Array<T> {
    this.sort((a, b) => {
        const sortkeysA = sortkeysGenerator(a);
        const sortkeysB = sortkeysGenerator(b);
        if (!(sortkeysA instanceof Array)) { return compare(sortkeysA, sortkeysB); }
        const length = sortkeysA.length;
        for (let i = 0; i < length; i++) {
            const compareResult = compare(sortkeysA[i], sortkeysB[i]);
            if (compareResult !== 0) { return compareResult; }
        }
        return 0;
    });
    return this;
}

Array.prototype.sum = function <T, K extends keyof T>(getKey: (item: T) => T[K]): number {
    return this.reduce((acc, item) => acc + Number(getKey(item)), 0);
}

export function groupBy<T>(list: Array<T>, keyGetter: (x: T) => string) {
    const ret = list.reduce((entryMap, e) => entryMap.set(keyGetter(e), [...entryMap.get(keyGetter(e)) || [], e]), new Map<string, T[]>());
    return ret
}

window.addEventListener('error', function (event) {
    alert(event.message)
})

window.addEventListener('unhandledrejection', function (event) {
    if (event.reason instanceof (DOMException)) {
        if (event.reason.name === "AbortError") return
    }
    if (event.reason instanceof (PromiseRejectionEvent)) {
        return
    }
    let msg = event.reason + ''
    const m = msg.toLowerCase()
    if (m.includes("failed to fetch") || m.includes("the resource cannot be found") || m.includes("failed to execute 'observe' on")) return
    msg = msg.replace(/<br>/g, "\n")
    msg = msg.replace(/<\/br>/g, "")
    alert(msg)
})

export interface KeyValuePair<Key = number, Value = string> {
    Key: Key;
    Value: Value;
}

export function getActionUrl(controller: string, action: string, id?: number, search?: string): string {
    if (!controller) return ""
    //const ignores = ["Global", "Login"];
    //const dmn = ignores.includes(controller) ? "" : leftCut(window.location.pathname, "/")
    let dmn = leftCut(window.location.pathname, "/");
    if (dmn.toLowerCase().includes("backend")) dmn = "";
    let qry = search || '';
    if (qry !== '') qry = '?' + qry;
    let ret = '/' + [dmn, controller, action, id].filter(x => x).join('/') + qry;//who took out preceding flash?
    ret = ret.replace("//", "/");
    return ret;
}

export function performAction(controller: string, action: string, vals: Record<string, any>, usePost?: boolean, signal?: AbortSignal) {
    if (usePost) {
        const js = myStringify(vals)
        const hfile = hasFile(vals)
        const url = getActionUrl(controller, action, undefined)
        if (hfile) {
            const formData = new FormData();
            const flat = flatten(vals);
            flat.forEach(x => {
                const val = x[1]
                formData.append(x[0], val)
            })

            const ret = fetchPlus(url, {
                method: "POST",
                body: formData,
                signal
            });
            return ret
        } else {
            return fetchPlus(url, {
                method: "POST",
                body: js,
                headers: {
                    'Content-Type': 'application/json'
                },
                signal: signal
            });
        }
    } else {
        return fetchPlus(getActionUrl(controller, action, undefined, queryVals(vals)))
    }
}
export class headerItem {
    id = 0;
    count?: number;
    aggregation?: number;
    column?: ColumnData;
    //coinSymbol?:string
}

export function dePascalCase(input: string) {
    const words = input.replace(/([A-Z])/g, ' $1').trim().split(' ')
    return words.join(" ")
}



export function invokeFunction<T>(controller: string, name: string, vals: Record<string, any>, usePost?: boolean, signal?: AbortSignal) {
    if (!name) {
        alert("??")
    }

    const vals2 = { Name: name, JsonInput: myStringify(vals) }
    if (usePost) {
        const url = getActionUrl(controller, "InvokeFunction", undefined)
        const hfile = hasFile(vals)
        if (hfile) {
            const formData = new FormData()
            //const flat = flatten(vals2)
            //flat.forEach(x => {
            //    formData.append(x[0], x[1])
            //})
            const fils = flatten(vals).filter(x => x[1] instanceof File)
            fils.forEach(x => {
                formData.append(x[0], x[1])
            })

            //const jsonInput = myStringify(vals2) //JSON.stringify(yourJsonData);
            //const blob = new Blob([jsonInput], { type: 'application/json' })
            //formData.append('JsonInput', blob, 'jsonInput.json')

            formData.append('Name', name)

            // Append "JsonInput" as a string
            formData.append('JsonInput', myStringify(vals))



            const ret = fetchJson<T>(url, {
                method: "POST",
                body: formData,
                signal
            })
            return ret
        }
        else {
            const jvals = myStringify(vals2)
            const ret = fetchJson<T>(url, {
                method: "POST",
                body: jvals,
                headers: {
                    'Content-Type': 'application/json'
                },
                signal
            })
            return ret
        }
    }
    else {
        const qvals = queryVals(vals2)
        const url = getActionUrl(controller, "InvokeFunction", undefined, qvals)
        return fetchJson<T>(url, { signal })
    }

}

export function performActionT<T>(controller: string, action: string, vals: Record<string, any>, usePost?: boolean, signal?: AbortSignal) {
    if (usePost) {
        const url = getActionUrl(controller, action, undefined)
        const hfile = hasFile(vals);
        if (hfile) {
            const formData = new FormData();
            const flat = flatten(vals);
            flat.forEach(x => {
                formData.append(x[0], x[1]);
            })
            const ret = fetchJson<T>(url, {
                method: "POST",
                body: formData,
                signal
            });
            return ret
        }
        else {
            const jvals = myStringify(vals)
            const ret = fetchJson<T>(url, {
                method: "POST",
                body: jvals,
                headers: {
                    'Content-Type': 'application/json'
                },
                signal
            });
            return ret
        }
    }
    else {
        const qvals = queryVals(vals)
        const url = getActionUrl(controller, action, undefined, qvals)
        return fetchJson<T>(url, { signal })
    }
}

export interface ControlProps2 extends ControlProps {
    //onChange?: (value?: any, field?: ControlProps, newrow?: RecordData, idx?: number) => void;
    onChange?: (value?: any, field?: ControlProps, newrow?: LiteRecordData, idx?: number) => void;
    onBlur?: (value?: any) => void;
    placeholder?: string;
    modelGetter?: () => any;
    commandInputGetter?: () => ControlProps2[];
    mainRecordType?: string;
    mainCommand?: string;
    items?: ColumnData[];
    expanded?: boolean;
    recordID?: number;
    style?: CSSProperties;
    recordKey?: string;
    serverRefresh?: boolean;//probaly not so in use anymore
    reloader?: () => void;
    showChanges?: boolean;
    showTools: boolean;
    commandParams?: string[];
    valueTip?: string;
    memberData: ColumnData;
    editPage?: boolean
}

export function testAttribs(props: ControlProps2) {
    return { id: testid(props), 'data-datatype': props.memberData.DataType, 'data-fieldtype': props.memberData.FieldType, 'data-value': props.Value, 'data-visible': props.memberData.Visible, 'data-foreigntypename': props.memberData.ForeignTypeName, 'data-required': props.memberData.Required, 'data-listtype': props.memberData.ListType, 'data-navigationfield': props.memberData?.NavigationField }
}

export function defaultGridProps(RecordID?: number) {
    const ret = new GridProps()
    ret.Page = 1
    ret.SettingGroup = SettingGroup.Columns
    if (RecordID || RecordID === 0) {//0 is important for commands that work on lists (like delete) should happen on all recs when invoked on new rec
        ret.RecordKeys = new Set([RecordID.toString()])
    }
    return ret
}

export function defaultControlProps() {
    const md = new MemberData()
    const cd = md as ColumnData
    const ret = { ...md, showTools: true, Useful: true, memberData: cd } as ControlProps2
    return ret
}

export function range(first: number, last: number): number[] {
    return [...new Array(last + 1 - first)].map((_, i) => i + first);
}

export function weekFirstDay(date: Date) {
    return new Date(new Date(date).setDate(date.getDate() - date.getDay()))
}
export function monthFirstDay(date: Date) {
    return new Date(date.getFullYear(), date.getMonth(), 1)
}
export function getUrl(reportID?: number, controller?: string, recordID?: number, action?: string, activeTab?: number, foreignController?: string, foreignRecordID?: number) {
    //let urlstart = '/'
    let urlstart = ''
    let searchParams = '?'
    function addToSearch(toAdd: string) {
        if (searchParams !== '?') searchParams += '&'
        searchParams += toAdd
    }

    if (reportID) {
        urlstart += '/Report/' + reportID
    } else {
        if (controller) urlstart += '/' + controller;
        if (action) urlstart += '/' + action;
        if (recordID != undefined) {
            urlstart += '/Edit/' + (recordID || 0);
            if (activeTab) urlstart += '/' + activeTab;
            //if (activeTab) urlstart += '?Tab=' + activeTab;
        }
        if (foreignController && foreignRecordID) {
            addToSearch("ForeignRecordType=" + foreignController + "&ForeignRecordID=" + foreignRecordID)
        }
    }
    if (searchParams !== '?') urlstart += searchParams
    return urlstart
}
export function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}
export function redirect(reportID?: number, controller?: string, recordID?: number, sametab = true) {
    let url = getUrl(reportID, controller, recordID);
    if (url === "") url = "/";
    if (sametab) window.location.hash = url;
    else {
        if (window.location.hash === "") window.location.hash = "/"; //if the url ends with "/#" and no "/" 
        window.open(window.location.href.replace(window.location.hash, '#') + url);
    }
}

export function UtcDate(input: Date) {
    //return new Date(Date.UTC(input.getFullYear(), input.getMonth(), input.getDate(), input.getUTCHours(), input.getUTCMinutes(), input.getSeconds()))
    return new Date(Date.UTC(input.getFullYear(), input.getMonth(), input.getDate(), input.getHours(), input.getUTCMinutes(), input.getSeconds()))
}


export function parseDate(input: string, format = 'yyyy-mm-ddThh:nn:ss') {
    if (input === "") return undefined
    let fmt = format
    const bks = String.fromCharCode(92)
    if (input.includes(" ") && !fmt.includes(" ")) fmt = fmt + " hh:nn"

    fmt = fmt.replace(/d+/i, "(?<c>\\d+)")//after this we can freely add "d"s, and the wont getr replaced
    const ys = fmt.match(/y+/i)![0]
    const ypat = `(?<a>${(bks + "d").repeat(ys.length)})`
    fmt = fmt.replace(ys, ypat)
    fmt = fmt.replace(/m+/i, "(?<b>\\d+)")
    fmt = fmt.replace(/h+/i, "(?<d>\\d+)")
    fmt = fmt.replace(/n+/i, "(?<e>\\d+)")
    fmt = fmt.replace(/s+/i, "(?<f>\\d+)")



    const regex1 = RegExp(fmt);
    const parts = regex1.exec(input);
    if (!parts) return undefined

    const a = +parts!.groups!["a"]
    if (a < 2) return undefined//probably default .net date 0001
    const b = +parts!.groups!["b"]
    const c = +parts!.groups!["c"]
    const d = +parts!.groups!["d"] || 0
    const e = +parts!.groups!["e"] || 0
    const f = +parts!.groups!["f"] || 0

    //let yr = a
    //if (yr < 99) yr = yr + 2000
    const dt = new Date(Date.UTC(a, b - 1, c, d, e, f))
    //const dt2 = new Date(yr, +b - 1, +c, +d, +e, +f)
    return dt
}

export function testid(input: ControlProps) {
    return "Test_Field_" + input.Name
}

export function divideFloat(firstNum: number, secondNum: number) {
    const ndI = 1 + ''.indexOf.call(firstNum, '.'); //Index of the Number's Dot
    const ddI = 1 + ''.indexOf.call(secondNum, '.'); // Index of the Divisors Dot
    if (ndI || ddI) { // IF its a float
        const deci = Math.max(('' + firstNum).length - ndI, ('' + secondNum).length - ddI); //Longest Decimal Part
        const pow = Math.pow(10, deci);
        return (firstNum * pow) / (secondNum * pow);
    }
    return firstNum / secondNum;
}

export async function downloadFile(rsp: Response) {
    const disposition = rsp.headers.get('Content-Disposition');
    const a = document.createElement('a');
    const blob = await rsp.blob();
    const url = URL.createObjectURL(blob);
    a.style.display = 'none';
    a.href = url;
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition!);
    if (matches != null && matches[1]) {
        var fn = matches[1];
        if (fn.startsWith('UTF-8')) {
            fn = fn.replace("UTF-8''", "");
            fn = decodeURI(fn);
        }
        a.download = fn.replace(/['"]/g, '');
    }
    document.body.appendChild(a);
    a.click();
}

export function hasFile(obj: any) {
    const ents = Object.entries(obj)
    const ret = ents.some(x => {
        const val = x[1]
        if (val) {
            if (val instanceof File) {
                return true
            }
            else if (typeof val == 'object') {
                const ret = hasFile(val)
                return ret
            }
        }
        return false
    })
    return ret
}

export function docSource(recordType: string, recordID: number, Column: string, page?: number, download?: boolean) {
    const ret = getActionUrl(recordType, 'GetDoc', recordID, 'Column=' + Column + (page ? '&Page=' + page : '') + (download ? "&Download=True" : ""))
    return ret
}

export function flatten(obj: any) {
    let ret: [string, string | Blob][] = [];
    Object.entries(obj).forEach(x => {
        const key = x[0]
        const val = x[1]
        const isary = Array.isArray(obj)
        const isary2 = Array.isArray(val)
        if (val !== null && val != undefined) {
            if (val instanceof File) {
                ret.push([key, val])
            }
            else if (typeof val == "object") {
                const flts = flatten(val)
                const mp = flts.map(y => {
                    const ky = (isary ? '[' + key + ']' : key) + (isary2 ? '' : '.') + y[0];
                    const vl = y[1]
                    return [ky, vl] as [string, string | Blob]
                })
                ret = ret.concat(mp);
            }
            else {
                ret.push([key, val as string | Blob])
            }
        }

    })
    return ret
}

export function rightCut(input: string, toCut: string) {
    let ret = input;
    if (ret.endsWith(toCut)) {
        ret = input.substring(0, input.length - toCut.length);
    }
    return ret;
};

export function leftCut(input: string, toCut: string) {
    let ret = input;
    if (ret.startsWith(toCut)) {
        ret = input.substring(toCut.length);
    }
    return ret;
}

export interface Stored<T> {
    expires: string,
    readonly item: T
}

export class Stored<T> implements Stored<T> {
    expires: string;
    constructor(readonly item: T) {
        const expires = new Date();
        expires.setHours(expires.getHours() + 1);
        this.expires = expires.toISOString();
    }
    store(key: string) {
        localStorage.setItem(key, JSON.stringify(this));
    }
    static tryLoad<T>(key: string): T | null {
        const serialized = localStorage.getItem(key);

        if (serialized) {
            const stored = JSON.parse(serialized) as Stored<T>;
            if (new Date() < new Date(stored.expires)) {
                return stored!.item;
            }
        }

        return null;
    }
}

export async function GetFileBytes(file: File) {
    const base64 = (await ReadBytes(file)).toString();
    return base64.substring(base64.indexOf(',') + 1, base64.length);
}

export const ReadBytes = (file: File) => new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        const res = reader.result as string
        resolve(res)
    }
    reader.onerror = error => reject(error);
});

export const ReadText = (file: File) => new Promise<string>(callback => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
        const res = reader.result as string
        callback(res)
    };
    reader.onerror = () => { throw reader.error }
})
export function controlRecord2(fields: ControlProps2[], recordID?: number) {
    const ret: any = {}
    ret.ID = recordID
    fields.map(x => {
        let val = x.Value
        //if ((x.SubTableProps && x.SubTableProps.Fields.length) || (x.ListType && x.Internal)) {
        // if ((x.memberData?.SubTableProps && x.memberData!.SubTableProps.length) || (x.memberData?.ListType && x.Internal)) {
        if (x.memberData?.ListType) {
            //const recs: RecordData[] = val
            if (val) {

                const recs: RecordsData = val
                //if (recs?.length && "IsDeleted" in recs[0]) {//minireprottfield doesnt have isdeleted
                //if (recs.length && "IsDeleted" in recs[0]) {//minireprottfield doesnt have isdeleted
                const newsubrecs: any[] = []
                recs.Records.filter(x => !x.IsDeleted).forEach(x => {
                    const newsubrec: Record<string, any> = {}
                    x.Fields.forEach(y => {
                        let v = y.Value
                        if (v === undefined) v = null
                        newsubrec[y.Name!] = v
                    })
                    newsubrec.ID = x.RecordID;
                    newsubrec.InUserInterface = true;
                    newsubrecs.push(newsubrec)
                })
                //recs.Records = newsubrecs
                val = newsubrecs
                //}



            }

        }
        ret[x.Name!] = val
    })
    return ret
}
export function controlRecord(input: ControlProps2[], recordID?: number, domainID?: number) {
    // const maxbyts = 2573253
    const rec = input.reduce((acc, cur) => {
        let newval = cur.Value
        if (cur.memberData!.ListType) {
            if (newval) {
                const arr: any[] = newval
                if (arr.length && "IsDeleted" in arr[0]) {//HAAAACK!!! just to make sure were iterating over recorddatas
                    //const recs: RecordData[] = newval;
                    //const newsubrecs: any[] = [];
                    //recs.filter(x => !x.IsDeleted).forEach(x => {
                    //    const newsubrec: Record<string, any> = {}
                    //    x.Fields.forEach(y => {
                    //        newsubrec[y.Name!] = y.Value
                    //    })
                    //    newsubrec['ID'] = x.RecordID;
                    //    newsubrec['InUserInterface'] = true;
                    //    newsubrecs.push(newsubrec)
                    //})
                    //newval = newsubrecs;
                }
            }
        }
        if (cur.Name == "DomainID" && newval) domainID = newval
        return (acc[cur.Name!] = newval, acc)
    }, {} as any);
    let ret = { ...rec, ID: recordID }
    if (domainID) ret = { ...ret, DomainID: domainID }
    return ret
}

export function reloadPage() {
    document.location.reload();
}

export function colorNumber(hex: string) {
    let clr = hex.replace('#', '');
    return parseInt(clr, 16);
}

export function numberColor(input: number | undefined) {
    if (!input) return ''
    return '#' + input.toString(16).padStart(6, '0')
}

export function enumList(app: MainAppType, enumObject: any) {
    const keysList = Object.keys(enumObject).filter(key => key !== String(parseInt(key, 10)))
    const ret = keysList.map(x => ({ Key: +enumObject[x], Value: app.localized(x) }))
    return ret
}
export function myStringify(input: any) {
    const ret = JSON.stringify(input, function (k, v) {
        if (v === "") return null
        if (v instanceof Set) return [...v]
        if (v instanceof Date) return v.toISOString()
        return v
    })
    return ret
}
export function showConfirm(message: string) {
    //the odd char is so we can detect confirms in selenium
    return window.confirm('\u00A0' + message)
}
export function useMediaQuery(query: string) {
    const [matches, setMatches] = useState(false);

    useEffect(() => {
        const media = window.matchMedia(query);
        if (media.matches !== matches) {
            setMatches(media.matches);
        }
        const listener = () => {
            setMatches(media.matches);
        };
        media.addListener(listener);
        return () => media.removeListener(listener);
    }, [matches, query]);
    return matches;
}

export function useMaxWidth(pixels: number) {
    return useMediaQuery(`(max-width: ${pixels}px)`)
}

export type TabType = { titleText?: string, style?: React.CSSProperties, onClick?: () => void, titleCount?: number, onResultsFetched?: (count: number) => void, titleElement?: ReactElement, children?: ReactNode, reportID?: number, testName?: string }

export function getControlProps2(input: ControlProps[], columns: ColumnData[]) {
    const ret = input.map(x => {
        const y = x as ControlProps2
        y.memberData = columns.find(z => z.ColumnKey === x.ColumnKey)!
        return y
    })
    return ret
}
export function columnControlProps2(columns: ColumnData[]) {
    return columns.map(x => {
        const y = defaultControlProps()
        y.memberData = x
        const m = { ...y, ...x }//this should eventually be removed propbably
        return m
    })
}

export interface MarkerProps { name: string, lat: number, long: number }

export type MainAppType = {
    data: AppData;
    localized: (input: string) => string;
    translations?: { [index: string]: string };
    setUser: (input?: number) => void;
    docActive: () => boolean;
}
export interface RecordFormProps {
    setSaveHandler?: any,
    onSaved?: (id?: number, oldId?: number) => void,
    onRefreshed?: (state: RecordsData) => void,
    onDirty?: (isDirty: boolean) => void,
    Record?: RecordsData,
    presetValues?: any,
    isPopup?: boolean
}

export function closestInterval(seconds: number): [Intervals, number] {
    let number = 0
    let interval = Intervals.Second
    const isminus = (seconds < 0)
    seconds = Math.abs(seconds)
    if (seconds > 0) {
        if (seconds >= 31536000) {
            number = seconds / 31536000
            interval = Intervals.Year
        } else if (seconds >= 2628000) {
            number = seconds / 2628000
            interval = Intervals.Month
        } else if (seconds >= 604800) {
            number = seconds / 604800
            interval = Intervals.Week
        } else if (seconds >= 86400) {
            number = seconds / 86400
            interval = Intervals.Day
        } else if (seconds >= 3600) {
            number = seconds / 3600
            interval = Intervals.Hour
        } else if (seconds >= 60) {
            number = seconds / 60
            interval = Intervals.Minute
        } else if (seconds >= 1) {
            number = seconds
            interval = Intervals.Second
        } else {
            number = seconds * 1000
            interval = Intervals.Millisecond
        }
        if (isminus) number = -number
    }
    return [interval, number]
}

export function multipliers() {
    var ret = {
        [Intervals.Millisecond]: 0.001,
        [Intervals.Second]: 1,
        [Intervals.Minute]: 60,
        [Intervals.Hour]: 3600,
        [Intervals.Day]: 86400,
        [Intervals.Week]: 604800,
        [Intervals.Month]: 2628000,
        [Intervals.Year]: 31536000
    }
    return ret
}
export function isSameDay(d1: Date, d2: Date) {
    return d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate();
}
export function getWeekNumber(d: Date): number {
    // Copy date so don't modify original
    d = new Date(d);
    // Get first day of year
    var yearStart = new Date(d.getFullYear(), 0, 1);
    const sunday = new Date(d.getFullYear(), d.getMonth(), d.getDate() - d.getDay());
    const millis_from_one_jan = sunday.getMilliseconds() - yearStart.getMilliseconds();
    const millis_per_day = 8.64e+7;
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(millis_from_one_jan / millis_per_day) / 7;
    // Return array of year and week number
    return weekNo;
}

export function GetMonthName(ctx: MainAppType, month: number) {
    var mnth = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][month]
    return ctx.localized(mnth)
}

export function GetDayName(ctx: MainAppType, day: number): string {
    return ctx.localized((['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'])[day]) || day.toString();
}

function compare<T>(x: T, y: T) {
    if (x === y) { return 0; }
    if (x < y) { return -1; }
    return 1
}
export function findScrollableParent(element: Element) {
    while (element) {
        const { overflowY } = window.getComputedStyle(element)
        if (overflowY === 'scroll' || overflowY === 'auto') return element
        element = element.parentNode as Element
    }
    return null
}

export function adjustColor(color: number, factor: number) {

    // Ensure the factor is between 0 and 1
    factor = Math.max(0, Math.min(1, factor));

    const clr = numberColor(color)

    // Convert hex to RGB
    let r = parseInt(clr.slice(1, 3), 16);
    let g = parseInt(clr.slice(3, 5), 16);
    let b = parseInt(clr.slice(5, 7), 16);

    // Calculate the adjusted color values
    r = Math.round(r + (255 - r) * factor);
    g = Math.round(g + (255 - g) * factor);
    b = Math.round(b + (255 - b) * factor);

    // Convert back to hex
    const hex = `#${(r << 16 | g << 8 | b).toString(16).padStart(6, '0')}`;

    return hex;
}