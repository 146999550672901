//***Copyright Notice***
//____________________________________________________
//Copyright © 2024 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import { createContext, CSSProperties } from "react"
import { AppData, ClipboardData, ColumnChoice, ColumnData, CommandList, CommandMessage, CommandResult, GridProps, LiteRecordData, MachshevetClient, MiniReportField, NavData, RecordsData, SettingGroup } from "./Declarations"
import { ControlProps2, downloadFile, MainAppType, reloadPage, showConfirm } from "./globals"

export class ColumnData2 extends ColumnData {
    items?: ColumnData2[];
    showTools=true;
    reloader?: () => void;
    commandParams?: string[];
    style?: CSSProperties;
    recordID?: number;
    expanded?: boolean;
}
export interface CommandInputProps extends CommandResult {
    command: ColumnData2;
    colSetter?: (input: ColumnData) => void;
    selectedKeys: Set<string>;
    mainRecordType?: string;
    tempFields: MiniReportField[]
}
const dt = new AppData()
export const MainContext = createContext<MainAppType>({ data: dt, localized: () => "", translations: {}, setUser: () => { }, docActive: () => true });

export async function handleCommandResult(input: CommandResult, command: ColumnData2, gridProps: GridProps, context: AppContextType, mainRecordType?: string, colSetter?: (state: ColumnData) => void) {
    const res = input
    const obj = res.Object
    const objtyp = res.ObjectType

    if (objtyp) {
        if (objtyp.endsWith('Input')) {
            const cip: CommandInputProps = { ...res, command: { ...command, showTools: true }, selectedKeys: gridProps.RecordKeys, mainRecordType: mainRecordType, tempFields: gridProps.Fields, colSetter: colSetter }
            context.openDialog!(cip)
        } else if (objtyp === 'ClipboardData') {
            const cd: ClipboardData = obj
            const clipitms: ClipboardItem[] = []
            if (cd.Text) {
                const type = "text/plain";
                const blob = new Blob([cd.Text], { type });
                const data = new ClipboardItem({ [type]: blob })
                clipitms.push(data)
            }
            if (cd.Html) {
                const type = "text/html";
                const blob = new Blob([cd.Html], { type });
                const data = new ClipboardItem({ [type]: blob })
                clipitms.push(data)
            }
            await navigator.clipboard.write(clipitms)
        } else if (objtyp === 'Uri') {
            window.open(obj, "_blank")
        } else if (objtyp === 'String') {
            context.showToast!(obj)
        } else if (objtyp === 'Boolean') {
            const txt = obj ? "✓" : "X"
            context.showToast!(txt)
        } else if (objtyp === 'CommandMessage') {
            context.openMessage!(obj)
        } else if (objtyp === 'ColumnChoice') {
            context.openChooser!(obj, command.reloader)
        } else if (objtyp === 'CommandList') {
            const gp = new GridProps() 
            const cmlist: CommandList = res.Object
            gp.RecordType = cmlist.RecordType
            gp.Filters = cmlist.Filters
            context.openTable!(gp)
        } else if (objtyp === 'ColumnData') {
            colSetter!(res.Object)
        } else if (res.Record) {
            context.openRecord!(res.Record, command.reloader)
        }
    } else {
        if (command.reloader) command.reloader()
        else reloadPage()
    }
}

export async function handleResult(response: Response, command: ColumnData2, gridProps: GridProps, context: AppContextType, recordType?: string, colSetter?: (state: ColumnData) => void) {
    const disposition = response.headers.get('Content-Disposition')
    if (disposition && disposition.startsWith('attachment')) {
        const prnt = response.headers.get('X-ForPrint')
        if (prnt) {
            const reader = new FileReader()
            const blb = await response.blob()
            reader.readAsText(blb)
            reader.onload = () => context.printHtml!(reader.result!.toString())
        } else {
            await downloadFile(response)
        }
    } else {
        const res = await response.json()
        await handleCommandResult(res, command, gridProps, context!, recordType, colSetter)
    }
}
export async function doCommand(command: ColumnData2, TotalRowCount: number, gridProps: GridProps, context: AppContextType, record?: any, colSetter?: (state: ColumnData) => void, controller?: string) {
    if (command.Warning) {
        let recordCount = gridProps.RecordKeys.size
        if (!recordCount) recordCount = TotalRowCount
        const warning = command.Warning.replace("{#}", recordCount.toString())
        if (!showConfirm(warning)) return
    }
    const url = window.location.href
    const prms = command.commandParams
    const fld = context?.menuField
    const rectyp = command.RecordType!
    const fldnam = fld?.memberData?.Name || context?.menuColumn?.Name
    const rsp = await MachshevetClient.DoCommandMulti(controller || rectyp, command.Name!, gridProps, record, fldnam, fld?.Value, url, fld?.memberData?.ReportFieldID, prms)
    await handleResult(rsp, command, gridProps, context!, rectyp, colSetter)
}
export function prioritizeCommands(ctx: MainAppType, cmnds: ColumnData[]) {
    //const flt = cmnds.filter(x => x.Uses > 0).sortBy(x => x.LocalName).map(x => x as ControlProps2)
    const flt = cmnds.filter(x => x.Uses > 0).sortBy(x => x.LocalName).map(x => x as ColumnData2)
    //const mor = defaultControlProps()
    const mor = new ColumnData2()
    mor.Name = "More"
    mor.LocalName = ctx.localized("More")
    mor.items = cmnds.filter(x => x.Uses == 0).sort((one, two) => {
        return one.LocalName! < two.LocalName! ? -1 : 1
    }).map(x => x as ColumnData2)
    return [...flt, mor];
}
export interface MenuProps {
    onCommand: (command: ColumnData2) => void,
    items: ColumnData2[];
    hoverable?: boolean;
}
export type AppContextType = {
    openRecord?: (record: RecordsData, onSaved?: (id?: number) => void, presetValues?: any) => void;
    openReportRecord?: (reportID:number,recordID?:number) => void;
    closeRecord: () => void;
    openTable?: (gp: GridProps, onRowDoubleClick?: (record: LiteRecordData) => void) => void;
    openChooser?: (input: ColumnChoice, reloader?: () => void) => void;
    openMessage?: (input: CommandMessage) => void;
    openDialog?: (result: CommandInputProps) => void;
    printHtml?: (body: string) => void;
    setPageTitle?: (title: string, icon?: string) => void;
    closeTable?: () => void;
    data?: NavData;
    showToast?: (input: string) => void;
    showContextMenu?: (event: React.MouseEvent, invoker?: (command: ColumnData2) => void, items?: ColumnData[], field?: ControlProps2, quickAdds?: { Key: string, Value: string }[], settingGroup?: SettingGroup, settingKey?: string, colSetter?: (column: ColumnData) => void, reloader?: () => void, column?: ColumnData2,recordID?:number ) => void,
    menuField?: ControlProps2;
    menuColumn?: ColumnData2
};
export async function openFieldSetting(recordType: string, fieldName: string, context: AppContextType) {
    const rd = await MachshevetClient.GetFieldSetting(recordType, fieldName)
    context.openRecord!(rd)
}
export const AppContext = createContext<AppContextType | undefined>(undefined);
export interface ContextMenuProps extends MenuProps {
    position?: [number, number];
    quickAdds?: { Key: string, Value: string }[]
}