//***Copyright Notice***
//____________________________________________________
//Copyright © 2024 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { useState, FC, useContext, CSSProperties } from 'react'
import { MyTab, MyTabs, NavLink, SmartCount, SmartDiv, useEffect2, useInterval, VecIcon } from './misc'
import { Options } from './Options'
import { MachshevetClient } from './Declarations'
import { getActionUrl, numberColor } from './globals'
import { AppContext, MainContext } from './styles'

export const SideNav: FC = () => {
    const app = useContext(AppContext)!
    const ctx = useContext(MainContext)
    const [visible, setVisible] = useState(true)
    const [isOptions, setIsOptions] = useState(false)
    let isFirstLoad = true

    async function fetchData() {
        const actv = ctx.docActive()
        if (isFirstLoad || actv) {
            isFirstLoad = false
            const js = await MachshevetClient.Global.GetNavData()
            app.data = js
        }
    }

    useInterval(async () => await fetchData(), 3300)
    useEffect2(async () => await fetchData(), [ctx.data.UserID])

    if (app.data) {
        app.data.Printables.forEach(x => {
            const byteArray = new Uint8Array(atob(x).split('').map(char => char.charCodeAt(0)))
            const file = new Blob([byteArray], { type: 'application/pdf' })
            const fileURL = URL.createObjectURL(file)
            window.open(fileURL)
        })
    }

    const sideTabsStyle: CSSProperties = { backgroundColor: 'transparent', fontWeight: 'bold', borderColor: 'red' }
    const indicWidth="1vmax"


    return <>
        {visible ?
            <>
                <aside style={{ color: 'white', display: 'flex', padding: 10, flexDirection: 'column', backgroundColor: numberColor(ctx.data.PrimaryColor) }}>
                    
                    <div style={{ display: 'flex', flexDirection: 'row-reverse' }}><VecIcon name='Cancel' onClick={() => setVisible(false)} /></div>
                    {app.data?.Domains?.length ?
                        <div style={{ backgroundColor: 'inherit' }}>
                            <label>{ctx.localized('Domain')}</label>
                            <select defaultValue={ctx.data.SelectedDomainID} style={{ backgroundColor: 'inherit' }} onChange={async e => {
                                const did = e.target.selectedOptions.item(0)?.value;
                                await MachshevetClient.Global.SetDomainID(did ? +did : undefined)
                            }}><option value='' style={{ backgroundColor: 'inherit' }}>{ctx.localized('All')}</option>
                                {app.data.Domains.map(x => <option key={x.Key} value={x.Key} style={{ backgroundColor: 'inherit' }} >{x.Value}</option>)}
                            </select>
                        </div> : undefined}

                    {ctx.data.UserID && <>
                        {app.data?.SystemAlert.Key ?
                            <span style={{ backgroundColor: 'pink', color: 'red' }}>{app.data.SystemAlert.Key + ': ' + app.data.SystemAlert.Value}</span>
                            : undefined}

                        <div style={{ padding: 4 }}>
                            <span style={{ fontWeight: 'bold' }}>{ctx.localized('Add').toUpperCase()}</span>
                        </div>
                    </>}
                    <div style={{ whiteSpace: 'nowrap', display: 'flex', justifyContent: 'center' }}>
                        {app.data?.QuickAdds.map(x => <NavLink key={x.Key} title={x.Value} controller={x.Key} action='Edit' style={{ padding: 3 }} > <SmartDiv><VecIcon name={x.Key} width="1.5vmax" /></SmartDiv></NavLink>)}
                    </div>
                    <MyTabs>
                        <MyTab titleText={ctx.localized('Lists')} key='1' style={sideTabsStyle}>
                            {app.data?.Lists && app.data.Lists.map((x, i) => <SideLink controller={x.RecordType} title={x.DisplayName!} key={x.RecordType} isLast={i === (app.data?.Lists.length || 0) - 1} />)}
                        </MyTab>
                        <MyTab titleText={ctx.localized('History')} key='2' style={sideTabsStyle} >
                            {app.data?.Recents && app.data?.Recents.map((x, i) => <SideLink controller={x.RecordType} title={x.DisplayName!} recordID={x.RecordID} key={x.RecordType + '.' + x.RecordID} isLast={i === (app.data?.Recents.length || 0) - 1} />)}
                        </MyTab>
                        <MyTab titleText={ctx.localized('Reports')} key='3' style={sideTabsStyle}>
                            {app.data?.Populars.map((x, i) => <SideLink reportID={x.ID} title={x.LocalName!} icon={x.RecordType} key={x.ID} isLast={i === (app.data?.Populars.length || 0) - 1} />)}
                        </MyTab>
                    </MyTabs>
                    <div style={{ display: 'flex', justifyContent: 'center', gap: 5 }}>
                        <NavLink controller="Portal" ><VecIcon name='Portal' width={indicWidth} title={ctx.localized('Portal')} /></NavLink>
                        {ctx.data.UserID && <>
                            <a href={getActionUrl('Global', 'DownloadData')}><VecIcon name='DownloadData' width={indicWidth} title={ctx.localized('DownloadData')} /></a>
                            <a href='https://docs.google.com/document/d/1bNYZlB8WvGrhxnLoSTisFBHNlvYzt2v6OAMa8fHkwQw/edit?usp=sharing'>
                                <VecIcon name='UserGuide' width={indicWidth} title={ctx.localized('UserGuide')} />
                            </a>
                            {ctx.data.IsSandbox && <VecIcon name='Sandbox' width={indicWidth} title={ctx.localized('Sandbox')} />}
                            {ctx.data.IsSysAdmin && <VecIcon name='Options' onClick={() => setIsOptions(true)} width={indicWidth} title={ctx.localized('Options')} />}
                            <VecIcon name='ClearStorage' title={ctx.localized('ClearStorage')} width={indicWidth} onClick={async () => {
                                localStorage.clear();
                                sessionStorage.clear();
                                await MachshevetClient.Global.ClearBrowser()
                                window.location.reload()
                            }} />
                        </>
                        }
                    </div>
                    <div>{ctx.data.Progresses?.map(x => {
                        const prcnt = x.Progress * 100
                        const num = Math.round(prcnt)
                        return <NavLink key={x.ID} controller="Execution" recordID={x.ID}>  <div style={{ backgroundColor: 'white', borderColor: 'white', borderWidth: 2, borderStyle: 'solid', borderRadius: 5 }} title={x.LocalCommand}>
                            <div style={{ width: prcnt + '%', backgroundColor: 'var(--primary)', borderRadius: 5 }}><SmartCount Number={num} />%</div>
                        </div></NavLink>
                    })}</div>
                    {app.data && <>
                        <div style={{ textAlign: 'center', paddingTop: 6 }}>{app.data?.AppName + ' ' + ctx.localized("By")}</div>
                        <a href='https://Machshevet.com' style={{ textDecoration:"none" }}>
                            <img src={getActionUrl('Global', 'Content/Logo.svg')} style={{ width: 25 }} />
                            <span>Machshevet.com</span>
                        </a>
                    </>}
                </aside>
                {isOptions && <Options onCancel={() => setIsOptions(false)} />}
            </>
            : <div style={{ width: 'auto', backgroundColor: numberColor(ctx.data.PrimaryColor), color: 'white' }}>
                <VecIcon name='Add' onClick={() => setVisible(true)} />
            </div>}
    </>
}

export const SideLink: FC<{ reportID?: number, controller?: string, recordID?: number, title: string, icon?: string, isLast: boolean }> = props => {
    return <NavLink {...props}>
        <SmartDiv style={{ padding: '.4vmax', borderBottom: props.isLast ? "0" : "solid .1px rgb(0 0 0 / 20%)", gap: 3, display: 'flex' }}>
            <span id={"TestLink" + props.controller}>{props.title}</span>
        </SmartDiv>
    </NavLink>
}

