//***Copyright Notice***
//____________________________________________________
//Copyright © 2024 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { useContext, useState, FC, CSSProperties, HTMLProps } from "react"
import { UtcDate, numberColor, range, GetMonthName } from "./globals"
import { SmartButton, SmartDiv } from "./misc"
import { MainContext } from "./styles"

export const MiniCal: FC<HTMLProps<HTMLDivElement> & { selectedDate?: Date, showTime: boolean, onSelected: (date: Date) => void }> = props => {
    const ctx = useContext(MainContext)
    const [value, setValue] = useState(props.selectedDate)//selected date
    const [state, setState] = useState(props.selectedDate || new Date())//navigation date
    //const [state, setState] = useState(props.selectedDate && props.selectedDate.getFullYear() > 1000 ? props.selectedDate : new Date())//navigation date

    function setSelected(date: Date) {
        setState(date)
        setValue(date)
        props.onSelected(date)
    }

    function setSelectedNum(date: number) {
        //const dt = new Date(date)
        const dt = UtcDate(new Date(date))
        setSelected(dt)
    }
    function setMinutes(minute: number) {
        const dt = new Date(Date.UTC(state.getFullYear(), state.getMonth(), state.getDate(), state.getUTCHours(), minute, state.getSeconds()))
        setSelectedNum(dt.setHours(dt.getUTCHours()))
    }

    function setStateNum(date: number) {
        const dt = new Date(date)
        setState(dt)
    }

    //const selday = value && UtcDate(value)
    const selhour = value?.getUTCHours()
    //const valdatetime = selday && selday.getTime();
    const stlhead: React.CSSProperties = { backgroundColor: numberColor(ctx.data.PrimaryColor), color: "white" }
    const yer = state.getFullYear()

    const yrs = range(yer - 10, yer + 10);
    const btnstyle: React.CSSProperties = { padding: 1 }
    return <div {...props} style={{ borderColor: numberColor(ctx.data.PrimaryColor), borderStyle: "solid", textAlign: 'center', display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
            <SmartButton style={btnstyle} tabIndex={-1} onClick={() => setStateNum(state.setFullYear(yer - 10))} >-10</SmartButton>
            <SmartButton style={btnstyle} tabIndex={-1} onClick={() => setStateNum(state.setFullYear(yer - 1))} >{ctx.data.IsRtl ? "<<" : ">>"}</SmartButton>
            <SmartButton style={btnstyle} tabIndex={-1} onClick={() => setStateNum(state.setMonth(state.getMonth() - 1))} >{ctx.data.IsRtl ? "<" : ">"}</SmartButton>
            <select style={btnstyle} tabIndex={-1} value={state.getMonth()} onChange={e => setState(new Date(yer, +e.currentTarget.value, 1))}>
                {range(0, 11).map(x => {
                    const txt = (x + 1) + ' | ' + GetMonthName(ctx, x)
                    return <option key={x} value={x}>{txt}</option>
                })
                }
            </select>
            <select style={btnstyle} tabIndex={-1} value={yer} onChange={e => setState(new Date(+e.currentTarget.value, state.getMonth(), state.getDate()))} >
                {yrs.map(x => <option value={x} key={x}>{x}</option>)}
            </select>
            <SmartButton style={btnstyle} tabIndex={-1} onClick={() => setStateNum(state.setMonth(state.getMonth() + 1))} >{ctx.data.IsRtl ? ">" : "<"}</SmartButton>
            <SmartButton style={btnstyle} tabIndex={-1} onClick={() => setStateNum(state.setFullYear(yer + 1))} >{ctx.data.IsRtl ? ">>" : "<<"}</SmartButton>
            <SmartButton style={btnstyle} tabIndex={-1} onClick={() => setSelected(UtcDate(new Date()))}> {ctx.localized("Now")} </SmartButton>
            <SmartButton style={btnstyle} tabIndex={-1} onClick={() => setStateNum(state.setFullYear(yer + 10))} >+10</SmartButton>
        </div>
        <div style={{ display: "flex", overflowY: "auto" }}>
            <table style={{ flexGrow: 1 }}>
                <thead>
                    <tr style={stlhead}>
                        {range(0, 6).map(x => <th key={x} style={{ padding: 2 }}>{x + 1}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {range(0, 5).map(week => {
                        return <tr key={week}>
                            {range(0, 6).map(i => {
                                const firstDayOfMonth = new Date(yer, state.getMonth(), 1);
                                const monthday = 1 - firstDayOfMonth.getDay() + week * 7 + i
                                const day = new Date(Date.UTC(yer, state.getMonth(), monthday))
                                const stl: CSSProperties = { fontSize: "80%", display: "table-cell" }
                                //if (day.getTime() === valdatetime) stl.backgroundColor = "orange"
                                if (props.selectedDate?.getDate() === monthday && props.selectedDate?.getMonth() === state.getMonth()) stl.backgroundColor = "orange"
                                if (day.getMonth() !== state.getMonth()) stl.color = "lightgray"
                                return <SmartDiv id={"TestDay" + monthday} style={stl} key={i} onMouseDown={e => {
                                    setSelected(day)
                                }}>
                                    {day.getDate()}
                                </SmartDiv>
                            })}
                        </tr>
                    })}
                </tbody>
            </table>
            {props.showTime && <div style={{ display: "flex", gap: 3, flexGrow: 1 }}>
                <div style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
                    <div style={stlhead}>{ctx.localized("Hour")}</div>
                    <div style={{ display: "flex", flexDirection: "column", flexWrap: "wrap", flexGrow: 1, overflowY: "auto" }}>
                        {range(0, 23).map(x => {
                            const stl: React.CSSProperties = { fontSize: "80%", padding: 1 }
                            if (x === selhour) stl.backgroundColor = "orange"
                            return <SmartDiv key={x} id={"TestHour" + x} style={stl} onMouseDown={() => {
                                const newval = state.setHours(x)
                                setSelectedNum(newval)
                            }}>{x}</SmartDiv>
                        })}
                    </div>
                </div>
                <div style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
                    <div style={stlhead}>{ctx.localized("Minute")}</div>
                    <div style={{ display: "flex", flexDirection: "column", flexWrap: "wrap", flexGrow: 1, overflowY: "auto" }}>
                        {range(0, 11).map(x => {
                            const stl: React.CSSProperties = { fontSize: "80%", padding: 1 }
                            const min = x * 5
                            if (min === value?.getMinutes()) stl.backgroundColor = "orange"
                            return <div key={x} style={stl} onMouseDown={() => {
                                setMinutes(min)
                            }}>{min}</div>
                        })}
                    </div>
                </div>
            </div>}
        </div>
    </div >
}