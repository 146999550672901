//***Copyright Notice***
//____________________________________________________
//Copyright © 2024 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { Preview, VecIcon, NavLink, useInterval, HamburgerMenu, Avatar2, useEffect2, SmartCount, SmartDiv, CardList, ChatList, Icon } from './misc'
import { Control } from './Control'
import { GridProps, MachshevetClient, ReportTypes, SettingGroup, ViewTable, ColumnData, RecordsData, LiteRecordData } from './Declarations'
import { adjustColor, columnControlProps2, ControlProps2, controlRecord, defaultGridProps, headerItem, leftCut, myStringify, numberColor, range, rightCut } from './globals'
import { Calendar } from './calendar'
import { DataGrid } from './DataGrid'
import { useParams } from 'react-router-dom'
import { AppContext, ColumnData2, doCommand, MainContext, prioritizeCommands } from './styles'

export interface TableBodyProps {
    onRowClicked?: (id: number, name: string) => void;
    onRowDoubleClick?: (record: LiteRecordData) => void;
    controller: string;
    onResultsFetched?: (header: headerItem) => void;
    fullPage?: boolean;
    modelGetter?: () => any;
    commandInputGetter?: () => ControlProps2[];
    gridProps: GridProps;
    autoRefresh?: boolean;
    setSelected?: (records: LiteRecordData[], select: boolean, unselectOthers: boolean) => void;
    idxSelected?: number;
    handleSelection?: (id: number, name: string) => void;
    handleSelections?: (keys: Set<string>) => void;
    resetSelected?: (count: number) => void;
    saveState?: boolean;
    showTools: boolean;
    records?: RecordsData;
    listType?: string;
    onPropsChanged?: (props: GridProps) => void;
    commands?: ColumnData[];
    doCommand?: (command: ColumnData2, field: ControlProps2) => void;
    activeTab: boolean;
    refreshData?: () => void;
    setMenuField?: (e: React.MouseEvent, field?: ControlProps2) => void;
    FontSize?: number;
    term?: string;
    parentRecordID?: number;
    totals?: { Key: string, Value: any }[];
}
export class menuField {
    command?: (input: ColumnData2) => void;
    colSetter?: (input: ColumnData) => void
    reloader?: () => void
}

export const ListView: FC<TableBodyProps> = props => {
    const app = useContext(AppContext)!
    const ctx = useContext(MainContext)
    const [state, setState] = useState<ViewTable>()
    const [totals, setTotals] = useState<{ Key: string, Value: any }[]>()
    const [totalRows, setTotalRows] = useState<number>()
    const [suggestions, setSuggestions] = useState<string[]>()
    const [showSuggestions, setShowSuggestions] = useState<boolean>(false)
    const [menuShown, setMenuShown] = useState(false)
    const [gridProps, setGridProps] = useState(props.gridProps)
    const [selectedRecord, setSelectedRecord] = useState<LiteRecordData>()
    const [isLoading, setIsLoading] = useState(true)
    const [loaded, setLoaded] = useState(false)
    const [dropValue, setDropValue] = useState<string>()
    //const [commands, setCommands] = useState<GridCommands>()
    const [commands, setCommands] = useState<ColumnData[]>()
    const selectedIndex = useRef(props.idxSelected)
    const ref = useRef<HTMLDivElement>(null)
    const lastRefresh = useRef<number>(0)
    const aborter = React.useRef(new AbortController())
    const lsttyp = props.controller

    const params = useParams()
    const url = params['*']
    const routs = url ? url.split("/") : undefined
    const MainRecordType = routs ? routs[0] : undefined


    useInterval(async () => {
        if (ctx.docActive()) {
            if (lastRefresh.current) {
                const diff = Date.now() - lastRefresh.current
                if (diff > 8000 && !isLoading) await refreshTable()
            }
        }
    }, 500)
    //}, 50000000);

    const curstt = state;
    const curgp = gridProps || defaultGridProps();
    if (!gridProps.SettingKey) gridProps.SettingKey = lsttyp + '.' + lsttyp

    useEffect2(async () => {
        if (props.activeTab) {
            await refreshTable()
        }
    }, [props.activeTab])


    useEffect(() => {
        setGridProps(prev => ({ ...prev, Term: props.term }))
    }, [props.term])
    useEffect(() => {
        setGridProps(prev => ({ ...prev, ParentRecordID: props.parentRecordID }))
    }, [props.parentRecordID])

    useEffect2(async () => {
        await filterChanged(false)
        if (loaded) setBrowserState()
    }, [gridProps.ParentRecordID, gridProps.Term, gridProps.Page, gridProps.Fields])//, gridProps.EditColumns])

    useEffect2(async () => {
        let gp: GridProps | undefined
        if (props.saveState !== false) {
            const ky = stateKey()
            const stateString = window.sessionStorage[ky]
            if (stateString) {
                gp = JSON.parse(stateString) as GridProps
                gp.RecordKeys = new Set([])//hack!!
                //if (!curstt || !curstt.Columns.length) setGridProps(gp)
                if (!state || !gridProps.Fields.length) setGridProps(gp)
            }
        }
        await refreshTable(gp)
    }, [gridProps.ParentRecordID])

    useEffect(() => {
        const idxSel = props.idxSelected!;
        if (idxSel >= lastNum && props.resetSelected) props.resetSelected(0);
        else moveSelection(idxSel);
    }, [props.idxSelected])

    function stateKey() {
        const cnt = props.controller;
        const gp = gridProps
        const rid = gp.ReportID;
        const mem = gp.Member
        const prid = gp.ParentRecordID;
        return cnt + '/DataTable' + (mem ? '.' + mem : '') + (rid ? '#' + rid : '') + (prid ? '.' + prid : '')
    }

    async function filterChanged(savestate: boolean) {
        if (savestate) setBrowserState()
        await refreshTable()
    }

    async function refreshTable(gp?: GridProps) {
        setIsLoading(true)
        aborter.current.abort()
        aborter.current = new AbortController()
        let mdl = props.modelGetter && props.modelGetter()
        const cmdinp = props.commandInputGetter && props.commandInputGetter()
        const jso = cmdinp && myStringify(controlRecord(cmdinp))
        if (!mdl) mdl = { ID: 0 }

        const hdr = new headerItem()
        hdr.column = state?.Records.Columns.find(x => x.DashboardAggregation)

        if (props.activeTab || !lastRefresh.current) {
            const gprops = gp || gridProps
            const cont = props.controller
            const r = await MachshevetClient.QuickSearch(cont, mdl, gprops, jso, props.showTools, aborter.current.signal)
            setState(r)

            //const dashagg = r.Records.Columns.find(x=>x.DashboardAggregation)
            //sumfield = r.Records.Columns.find(x => x.DashboardAggregation)
            //hdr = r.Records.Columns.find(x => x.DashboardAggregation)
            hdr.column = r.Records.Columns.find(x => x.DashboardAggregation)
            //setSubHeader(prev => ({ ...prev, column: r.Records.Columns.find(x => x.DashboardAggregation) }))
            const curFildsNames = gridProps.Fields.sortBy(x => x.SelectedPosition).map(y => y.Name!)
            const servFilds = r.Records.Columns//.map(x => x as MiniReportField)
            const servFildsNames = servFilds.sortBy(x => x.SelectedPosition).map(y => y.Name!)
            const j1 = myStringify(gridProps.Fields.sortBy(x => x.SelectedPosition).map(y => [y.Name!, y.BarFilterOptions, y.FilterBar, y.Visible]))
            const j2 = myStringify(servFilds.sortBy(x => x.SelectedPosition).map(y => [y.Name!, y.BarFilterOptions, y.FilterBar, y.Visible]))
            const changed = j1 !== j2
            if (changed) {
                const newflds = servFilds.filter(x => !curFildsNames.includes(x.Name!))
                setGridProps(prev => ({
                    ...prev, Fields: prev.Fields.filter(x => servFildsNames.includes(x.Name!)).concat(newflds).map(x => {
                        const newfld = servFilds.find(y => y.Name === x.Name)!
                        return { ...x, SelectedPosition: newfld.SelectedPosition, BarFilterOptions: newfld.BarFilterOptions, FilterBar: newfld.FilterBar, Visible: newfld.Visible }
                    })
                }))
            }
            if (props.fullPage) app!.setPageTitle!(r.DisplayName!, r.ListType);
            if (r.AllowedKeys.length && gridProps.RecordKeys.size) {
                setGridProps(prev => {
                    const newkeys = new Set([...prev.RecordKeys].filter(x => r.AllowedKeys.indexOf(x) > -1))
                    return ({ ...prev, RecordKeys: newkeys })
                })
            }
            if (!gridProps.DisplayType && r.ReportType) setGridProps(prev => ({ ...prev, DisplayType: r.ReportType }))
            if (!commands) {
                const cmnds = await MachshevetClient.Global.GetCommands(r.ListType!, gridProps.ReportID)
                setCommands(cmnds)
            }

            if (!hdr.column) {
                const ttls = await MachshevetClient.GetTotals(cont, mdl, gprops, jso, aborter.current.signal)
                setTotals(ttls)
            }



        }

        hdr.count = await MachshevetClient.GetCount(props.controller!, mdl, gp || gridProps, jso, aborter.current.signal)
        setTotalRows(hdr.count)
        if (hdr.column) {
            // if (hdr) {
            const ttls = await MachshevetClient.GetTotals(props.controller, mdl, gp || gridProps, jso, aborter.current.signal)
            setTotals(ttls)
            hdr.aggregation = ttls.find(x => x.Key === hdr.column!.Name)?.Value


            //hdr.coinSymbol = props.records?.Records?.flatMap(y => y.Fields).find(y => y.Name === hdr.column!.Name)?.CoinSymbol
        }

        props.onResultsFetched && props.onResultsFetched(hdr)
        lastRefresh.current = Date.now()//means data has been filled. necessary for inactive tabs on first show
        setIsLoading(false)
        if (!loaded) setLoaded(true)
    }

    function setBrowserState() {
        if (props.saveState != false) {
            const js = myStringify(gridProps);
            const ky = stateKey()
            window.sessionStorage[ky] = js;
        }
    }

    function getResultOptions() {
        const lc = leftCut(ctx.data.ResultOptions || "", ',')
        const rc = rightCut(lc, ',')
        return rc.split(',').map(x => x.trim())
    }

    const skippedrows = curstt?.PageRows! * (gridProps.Page - 1)
    const rowcount = curstt?.Records?.Records.length || 0
    if (totalRows && skippedrows > totalRows) setGridProps(prev => ({ ...prev, Page: 1 }))
    const lastNum = skippedrows + rowcount
    const pageCount = curstt?.PageRows ? ((Math.ceil((totalRows || 0) / curstt?.PageRows)) || 0) : 0;
    const end = (Math.min(pageCount, curgp.Page + 5)) || 0;
    let around_the_page = [1];

    function inputKeyDown(event: KeyboardEvent | React.KeyboardEvent<HTMLInputElement>) {
        const key = event.key;
        const cursel = selectedIndex.current === undefined ? -1 : selectedIndex.current
        if (key === 'ArrowDown') moveSelection(cursel + 1);
        else if (key === 'ArrowUp') moveSelection(cursel - 1);
        else if (key === "Esc" || key === "Escape") {
            curgp.Term = "";
            setSuggestions(undefined);
        }
    };

    function moveSelection(index: number) {
        let newidx = index
        if (index < 0) {
            newidx = lastNum - 1;
            if (props.resetSelected) props.resetSelected(newidx);
        }
        if (curstt) {
            const toRow = curstt.Records.Records[newidx]
            setKeys([toRow], true, true)
        }
        selectedIndex.current = newidx;
    }

    function setKeys(records: LiteRecordData[], select: boolean, unselectOthers?: boolean) {
        const keys = records.map(x => x.RecordKey!)
        let seld = [...gridProps.RecordKeys]
        if (unselectOthers) seld = keys
        else if (select) seld = seld!.concat(keys)
        else seld = seld.filter(x => x !== keys[0])
        setGridProps(prev => ({ ...prev!, RecordKeys: new Set(seld) }))
        gridProps.RecordKeys = new Set(seld)//no choice. since we dont know When the sate will have the New data
        if (props.handleSelection) handleSelection(seld)//the if seeems wrong here. ymo.
        if (props.handleSelections) props.handleSelections(new Set(seld))
        setSelectedRecord(records[records.length - 1])
    }

    function handleSelection(selected: string[]) {
        let selRowData: { id: number, name: string } = { id: 0, name: "" };
        const rData = curstt?.Records.Records?.find(x => x.RecordKey === selected[0]);
        if (rData) selRowData = { id: rData.RecordID!, name: rData.RecordName! };
        if (selRowData.id > 0) props.handleSelection && props.handleSelection(selRowData.id, selRowData.name);
    }

    if (pageCount == 2) around_the_page = around_the_page.concat([2]);
    else if (pageCount > 2) {
        if (curgp.Page == 1) around_the_page = around_the_page.concat(makeNext());
        else if (curgp.Page! == pageCount) around_the_page = around_the_page.concat(makePrev(), [curgp.Page!]);
        else if (curgp.Page! == 2) around_the_page = around_the_page.concat([curgp.Page!], makeNext());
        else around_the_page = around_the_page.concat(makePrev(), [curgp.Page!], makeNext());
    }

    function makeNext() {
        let next: number[];
        if ((curgp.Page!) == pageCount - 1) next = [pageCount]
        else {
            next = [pageCount + 1].concat(range(curgp.Page! + 2, end))
            if (end < pageCount - 1) next = next.concat([-1, pageCount])
            else if (end == pageCount - 1) next = next.concat([pageCount])
        }
        return next;
    }

    function makePrev(): number[] {
        let prev: number[]
        if (gridProps.Page == 3) prev = [0]
        else if (gridProps.Page <= 7) prev = range(2, gridProps.Page - 2).concat([0])
        else prev = [-1].concat(range(gridProps.Page - 5, gridProps.Page - 2), [0])
        return prev
    }

    const navdata = around_the_page.map(x => ({
        num: x == 0 ? curgp.Page - 1 : x == pageCount + 1 ? curgp.Page + 1 : x,
        text: x == 0 ? ctx.localized('Previous') : x == pageCount + 1 ? ctx.localized('Next') : x == -1 ? '...' : x.toString()
    }));
    const actionItems = commands && prioritizeCommands(ctx, commands)

    const sButtons: React.CSSProperties = { backgroundColor: numberColor(ctx.data.PrimaryColor), color: "white", borderRadius: 5, whiteSpace: "nowrap", padding: 4, cursor: "pointer" }
    const sCell: React.CSSProperties = { padding: "4px 8px" }
    if (curstt?.ValueBorderColor) {
        sCell.borderColor = numberColor(curstt?.ValueBorderColor)
        sCell.borderStyle = "solid"
        sCell.borderWidth = 1
    }

    async function docmd(c: ColumnData2) {
        const mdl = props.modelGetter && props.modelGetter()

        c.reloader = () => refreshTable()

        await doCommand(c, totalRows || 0, gridProps, app, mdl, async newcol => {
            setGridProps(prev => ({ ...prev, Fields: prev.Fields.map(y => y.Name === newcol.Name ? newcol : y) }))
        }, props.controller)
    }

    const suggAborter = React.useRef(new AbortController())
    const hasfilter = gridProps.Term || gridProps.Fields.some(x => x.AutoFilter || x.DateRange || x.FilterList || x.FilterText || x.IDFilter || x.MinFilter || x.MaxFilter || x.StartSpan || x.EndSpan || (x.BarFilterValues || []).size)
    const inpstyle: React.CSSProperties = { borderRadius: 5, borderWidth: 1, borderStyle: "solid", borderColor: numberColor(ctx.data.SecondaryColor), padding: 2 }

    const dispTypes = [ReportTypes.List, ReportTypes.Calendar, ReportTypes.Cards, ReportTypes.Chat]
    const partstyle: React.CSSProperties = { borderWidth: 1, borderStyle: "solid", borderColor: numberColor(ctx.data.SecondaryColor), borderRadius: 6, padding: 5 }
    const barfilters = gridProps.Fields.filter(x => x.FilterBar)

    const inpparams = state && columnControlProps2(state.InputParams)

    const props2 = { ...props, gridProps: gridProps, totals: totals }
    props2.setSelected = setKeys
    props2.refreshData = () => refreshTable()
    props2.records = state?.Records
    props2.commands = commands//?.Commands
    props2.listType = state?.ListType
    props2.setMenuField = setMenu
    props2.onPropsChanged = async e => {
        setGridProps(e)
        setBrowserState()
        await refreshTable()
    }

    function setMenu(e: React.MouseEvent, field?: ControlProps2) {
        app.showContextMenu!(e, docmd, actionItems, field, state?.Records.QuickAdds)
    }

    return <div id="TestGridContainer" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", overflowY: "hidden", flexGrow: 1 }} ref={ref}>
        <div id={"TestGrid" + gridProps.ReportID}>
            <div style={{ display: 'flex', padding: "1vh", gap: "1vh" }}>
                {state && gridProps.ReportID !== undefined && <NavLink controller={state?.ListType} style={{ fontWeight: 'bold' }}>{ctx.localized(state!.ListType!)}</NavLink>}
                {(state?.Filters?.length || 0) > 0 && <div style={{ display: "flex", gap: 10 }}>
                    {state?.Filters.map(x => {
                        if (x.Group == 0) return x.Descriptions.map(y => <span key={y.Key}>{y.Key}:&nbsp;<b>{y.Value}</b></span>)
                        else {
                            const orFilters = x.Descriptions.map<React.ReactNode>(y => <span key={y.Key}>{y.Key}:&nbsp;<b>{y.Value}</b></span>)
                            if (orFilters.length > 0)
                                return orFilters.map<React.ReactNode>(t => <span>{t}</span>).reduce((prev, current) => [prev, (<b style={{ color: "lightblue" }}> {ctx.localized("Or")} </b>), current]);
                            else
                                return null
                        }
                    })}
                </div>
                }
            </div>
            {props.showTools !== false && <div style={{ display: "flex", gap: "0.5vmax", alignItems: 'center' }}>
                {commands?.filter(x => !x.NeedsField).sortBy(x => [-x.Uses, x.DefaultPosition]).slice(0, 6).map(x => <Avatar2 key={x.Name} {...x} RecordType={state?.ListType} gridProps={gridProps} showTools={true} totalRows={totalRows} reloader={() => refreshTable()} />)}
                <div style={{ flexGrow: 1 }} onMouseLeave={() => setShowSuggestions(false)}><input id="TestSearch" type="search" value={gridProps.Term || ""} placeholder={ctx.localized("Search")} style={{ ...inpstyle, height: "100%", width: "100%" }} onChange={async e => {
                    const trm = e.currentTarget.value;
                    setGridProps(prev => ({ ...prev, Term: trm }));

                    if (suggAborter.current) suggAborter.current.abort();
                    suggAborter.current = new AbortController();
                    const suggs = await MachshevetClient.GetSuggestions(props.controller, gridProps, suggAborter.current.signal);
                    setSuggestions(suggs);
                    setShowSuggestions(true);
                }} onKeyDown={inputKeyDown} onMouseEnter={() => setShowSuggestions(true)} />
                    {showSuggestions && suggestions && <div style={{ position: "absolute", backgroundColor: "white", zIndex: 2, borderColor: "lightgray", borderWidth: 2, borderStyle: "solid", padding: 3, borderRadius: "0px 0px 5px 5px" }} >{suggestions?.map(x => <div key={x} style={{ padding: 2 }} onClick={e => {
                        setGridProps(prev => ({ ...prev, Term: x }))
                        setSuggestions(undefined)
                    }}>{x}</div>)}</div>}
                </div>
                <Icon name="ClearFilters" color={hasfilter ? "orange" : undefined} fontSize={"130%"} onClick={async () => {
                    setGridProps(prev => ({ ...prev, Fields: prev.Fields.map(x => ({ ...x, IDFilter: "", FilterText: "", MinFilter: "", MaxFilter: "", AutoFilter: undefined, FilterList: "", ReverseFilter: false, DateRange: undefined, StartSpan: undefined, EndSpan: undefined, BarFilterValues: new Set<string>() })), Term: "", RecordKeys: new Set<string>(), Page: 1, InputParams: [] }))
                    setSuggestions(undefined)
                }} />
                <div id="TestInputParams" style={{ display: "flex", gap: 10 }}>
                    {inpparams && inpparams.map(x => <div key={x.memberData!.Name}><div style={{ whiteSpace: "nowrap" }} >{x.memberData!.LocalName}</div><Control term={gridProps.Term} field={{
                        ...x, showTools: false, Value: gridProps.InputParams.find(y => y.Key === x.Name)?.Value,/* RecordType: state!.ListType*/
                        onChange: async e => {
                            if (gridProps) {
                                const ip = gridProps.InputParams.find(y => y.Key === x.memberData!.Name)
                                if (ip) ip.Value = e
                                else gridProps.InputParams.push({ Key: x.memberData!.Name!, Value: e })
                                setGridProps(gridProps)
                            }
                            await filterChanged(true)
                        }
                    }} /></div>
                    )}
                </div>
                <select style={inpstyle} onChange={async e => {
                    const rid = +e.currentTarget.value
                    gridProps.FilterReportID = rid
                    setGridProps(gridProps)
                    await refreshTable()
                }}>
                    <option key="">{ctx.localized("ReportFilter") + "..."}</option>{state && state.FilterReports && state.FilterReports.map(x => <option key={x.Key} value={x.Key}>{x.Value}</option>)}
                </select>
                {state && <>
                    <NavLink controller={state.ListType} action="Import" foreignController={MainRecordType} foreignRecordID={gridProps.ParentRecordID} style={sButtons}>{ctx.localized("Import")}</NavLink>
                    <NavLink controller={state.ListType} action="Insert" style={sButtons}>{ctx.localized("Insert")}</NavLink>
                    <VecIcon color={numberColor(ctx.data.PrimaryColor)} name={state.ShowPreview ? "HidePreview" : "ShowPreview"} width={28} onClick={async () => {
                        const newvalprevw = !state.ShowPreview
                        setState(prev => ({ ...prev!, ShowPreview: newvalprevw }))
                        await MachshevetClient.Global.SetSetting(gridProps.SettingKey!, newvalprevw.toString(), SettingGroup.ShowPreview, state?.ListType, props.gridProps?.ReportID)
                    }} />
                    <VecIcon id="TestShowHamburger" color={numberColor(ctx.data.PrimaryColor)} name={menuShown ? "HideMenu" : "ShowMenu"} onClick={async () => {
                        setMenuShown(!menuShown)
                    }} />
                </>}
            </div>}
        </div>
        {state && <div id={"TestGrid_" + state.ListType} style={{ display: "flex", gap: "1vmax", overflowY: "hidden", flexGrow: 1, padding: ".8vmax" }}>
            {barfilters && barfilters.length > 0 && props.showTools &&
                <div id="TestBarFilters" style={{ ...partstyle, overflowY: "auto" }}>
                    {barfilters.map(x => {
                        const bfs = gridProps.Fields
                        const bf = bfs.find(z => z.SelectName === x.SelectName)
                        const vals = [...bf?.BarFilterValues || new Set<string>()]
                        return <div key={x.ColumnKey} id={"TestFilterBar_" + x.Name}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span style={{ fontWeight: "bold" }}>{x.LocalName}</span>
                                <div style={{ display: 'flex' }}>
                                    <SmartDiv onClick={async () => {
                                        const curval = vals[0]
                                        const curindex = x.BarFilterOptions.findIndex(y => y.Key === curval)
                                        let newval = x.BarFilterOptions[curindex + 1]
                                        if (!newval) newval = x.BarFilterOptions[0]
                                        setGridProps(prev => ({ ...prev, Fields: prev.Fields.map(y => y.SelectName === x.SelectName ? { ...y, BarFilterValues: new Set([newval.Key!]) } : y) }))
                                    }}>▽</SmartDiv>
                                    <SmartDiv onClick={async () => {
                                        const curval = vals[0]
                                        const curindex = x.BarFilterOptions.findIndex(y => y.Key === curval)
                                        let newval = x.BarFilterOptions[curindex - 1]
                                        if (!newval) newval = x.BarFilterOptions[x.BarFilterOptions.length - 1]
                                        setGridProps(prev => ({ ...prev, Fields: prev.Fields.map(y => y.SelectName === x.SelectName ? { ...y, BarFilterValues: new Set([newval.Key!]) } : y) }))
                                    }}>△</SmartDiv>
                                </div>
                            </div>
                            <div>
                                {x.BarFilterOptions && x.BarFilterOptions.map(y => {
                                    const key2 = y.Key || ""
                                    const ischk = vals.includes(key2) ? true : false
                                    const isdropping = y.Key === dropValue && y.Key !== undefined
                                    return (
                                        <SmartDiv key={y.Key} onDragOver={e => e.preventDefault()} onDragEnter={() => setDropValue(y.Key)} onDrop={async () => {
                                            await MachshevetClient.Global.SetValue(state.ListType!, gridProps.RecordKeys, x.Name!, y.Key);
                                            await refreshTable();
                                            setDropValue(undefined);
                                        }}>
                                            <label style={{ display: 'flex', gap: 4, alignItems: 'center', justifyContent: 'space-between' }}>
                                                <div>
                                                    <input id="TestBarFilterOption" type="checkbox" onChange={async e => {
                                                        const toadd = e.target.checked
                                                        //var oldvals=[...]
                                                        setGridProps(prev => ({ ...prev, Fields: prev.Fields.map(y => y.SelectName === x.SelectName ? { ...y, BarFilterValues: new Set(toadd ? [...y.BarFilterValues || []].concat(key2) : [...y.BarFilterValues].filter(x => x.toString() !== String(key2))) } : y) }))
                                                        //await filterChanged(true)
                                                    }} checked={ischk} />
                                                    <span style={{ fontWeight: isdropping ? 900 : "unset", fontSize: isdropping ? 16 : "unset" }}> {y.Value || "(" + ctx.localized("Empty") + ")"}</span>
                                                </div>

                                                <span style={{ fontSize: 10 }}> <SmartCount Number={y.Count} /></span>
                                            </label>
                                        </SmartDiv>)
                                })}
                            </div>
                        </div>
                    })}
                </div>}

            {(() => {
                switch (gridProps.DisplayType) {
                    case ReportTypes.Calendar: return <Calendar {...props2} doCommand={docmd} />
                    case ReportTypes.Cards: return <CardList {...props2} controller={props.controller} activeTab={props.activeTab} doCommand={docmd} />
                    case ReportTypes.Chat: return <ChatList  {...props2} />
                    default:
                        return <DataGrid {...props2} />
                }
            })()}


            {props.showTools && state?.ShowPreview && selectedRecord && <div style={{ ...partstyle, overflow: "auto", maxWidth: "40%", display: 'flex' }}>{<Preview recordType={selectedRecord.RecordType!} id={selectedRecord.RecordID!} miniMode={false} />}</div>}
            {menuShown && commands && <div style={{ ...partstyle, display: 'flex' }}> <HamburgerMenu onCommand={async c => {
                await docmd(c)
                setMenuShown(false)
            }} items={commands.map(x => x as ColumnData2)} /></div>}
        </div>
        }

        <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: 'flex', gap: 3 }}>
                <div>{ctx.localized('Showing') + ' ' + (skippedrows + 1) + ' ' + ctx.localized('To') + ' ' + lastNum + ' ' + ctx.localized('Of') + ' '}</div><SmartCount testname="TotalRows" Number={totalRows || 0} />
            </div>
            {props.showTools && state && <>
                <div style={{ display: 'flex', gap: 2 }}>
                    {ctx.localized('Show') + ' '}
                    <select id="TestMaxRowsSelector" value={state.PageRows}
                        onChange={async e => {
                            setGridProps(prev => ({ ...prev, Page: 1 }))
                            await MachshevetClient.Global.SetSetting(gridProps.SettingKey!, e.currentTarget.value, SettingGroup.PageRows, state?.ListType, props.gridProps?.ReportID)
                            await refreshTable();
                        }}
                    >{getResultOptions()!.map(x => <option id={"TestMaxRowsSelector" + "_" + x} value={x} key={x}>{x}</option>)}
                    </select>
                    {' ' + ctx.localized('Records')}
                </div>
            </>}

            <VecIcon name="Refresh" onClick={() => refreshTable()} width={18} />
            <span id="TestLoading" >{isLoading ? "⇄" : ""} </span>

            {props.showTools && <>
                <div style={{ display: 'flex', gap: 2 }}>{dispTypes.map(x => <span key={x}> <VecIcon width={16} name={ReportTypes[x]} onClick={() => { setGridProps(prev => ({ ...prev, DisplayType: x })) }} /></span>)}</div>
            </>}
            <div style={{ display: 'flex' }}>
                {navdata.map(x => <SmartDiv key={x.num} style={{ padding: 4, borderWidth: 1, borderStyle: 'solid', borderColor: numberColor(ctx.data.SecondaryColor), backgroundColor: x.num === curgp.Page ? numberColor(ctx.data.PrimaryColor) : adjustColor(ctx.data.SecondaryColor!, .6) }}
                    onClick={async () => {
                        setGridProps(prev => ({ ...prev, Page: x.num }))
                        setBrowserState()
                    }}>{x.text}</SmartDiv>)
                }
            </div>
        </div>
    </div>
}

